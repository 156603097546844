import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { Button as HeadlessButton } from '@headlessui/react';

import { ShieldCheckIcon, LockClosedIcon, ComputerDesktopIcon } from '@heroicons/react/20/solid';
import { OysterWordMark } from '@oysterjs/ui/Logo';
import { Field, FieldGroup, Fieldset, Label } from '../../components/fieldset';
import {
  CreateBusinessApplicationDocument,
  CreateBusinessApplicationMutation
} from '../../types/graphql';
import { Input } from '../../components/input';

const features = [
  {
    name: 'Comprehensive coverage.',
    description:
      'Oyster works with 30+ carriers to ensure you get tailored coverage for your business.',
    icon: ShieldCheckIcon
  },
  {
    name: 'Digital experience.',
    description: 'Experience a seamless digital experience from quote to policy management.',
    icon: ComputerDesktopIcon
  },
  {
    name: 'Risk management.',
    description:
      'Generate, share, and manage COIs on your own. Get access to a dedicated risk expert.',
    icon: LockClosedIcon
  }
];

export const Landing = () => {
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [createApplication, { data, error }] = useMutation<CreateBusinessApplicationMutation>(
    CreateBusinessApplicationDocument
  );

  React.useEffect(() => {
    if (data?.createBusinessApplication?.id) {
      history.push(`/commercial/app/${data.createBusinessApplication.id}`);
    }
  }, [data?.createBusinessApplication?.id]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const form = Object.fromEntries(formData.entries());

    setIsSubmitting(true);
    setTimeout(() => {
      createApplication({
        variables: {
          application: {
            businessName: form.businessName?.toString(),
            fullName: form.fullName?.toString(),
            email: form.email?.toString(),
            phone: form.phone?.toString()
          }
        }
      }).finally(() => {
        setIsSubmitting(false);
      });
    }, 1000);
  };

  return (
    <div className="min-h-screen bg-neutral-950 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-8 lg:px-16">
        <div className="mx-auto flex flex-col max-w-2xl gap-x-8 gap-y-16 sm:gap-y-20 lg:max-w-none lg:flex-row">
          <div className="lg:pr-8 w-full">
            <div className="lg:max-w-lg">
              <OysterWordMark forceLight />
              <h1 className="text-base mt-10 font-semibold leading-7 text-primary-300">
                Reduce risk and grow confidently
              </h1>
              <p className="mt-2 text-3xl font-semibold font-heading text-white sm:text-4xl">
                Find the right insurance for your business
              </p>
              <p className="mt-6 text-lg leading-8 text-neutral-300">
                Oyster works with top-rated insurance carriers to provide you with the best coverage
                for your business. Plus, leverage Oyster's risk management platform and experts to
                better protect your business.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-neutral-300 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-white">
                      <feature.icon
                        aria-hidden="true"
                        className="absolute left-1 top-1 h-5 w-5 text-primary-300"
                      />
                      {feature.name}{' '}
                    </dt>
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="flex w-full justify-center lg:mt-20">
            <div className="isolate relative bg-neutral-100 px-10 py-10 shadow-2xl rounded-lg sm:px-12 max-w-md w-full">
              <h2 className="mt-2 text-3xl font-medium font-heading">Start your quote</h2>
              <form onSubmit={onSubmit} className="space-y-6 mt-6">
                <Fieldset>
                  <FieldGroup>
                    <Field>
                      <Label>Business name</Label>
                      <Input
                        name="businessName"
                        type="text"
                        required
                        placeholder="ACME Corporation"
                      />
                    </Field>

                    <Field>
                      <Label>Your full name</Label>
                      <Input
                        name="fullName"
                        type="text"
                        required
                        autoComplete="full-name"
                        placeholder="May Peterson"
                      />
                    </Field>

                    <Field>
                      <Label>Email address</Label>
                      <Input
                        name="email"
                        type="email"
                        required
                        autoComplete="email"
                        placeholder="may@acme.com"
                      />
                    </Field>

                    <Field>
                      <Label>Phone number</Label>
                      <Input
                        name="phone"
                        type="tel"
                        required
                        autoComplete="tel"
                        placeholder="+1 (555) 123-4567"
                        minLength={10}
                      />
                    </Field>
                  </FieldGroup>
                </Fieldset>

                <HeadlessButton
                  type="submit"
                  disabled={isSubmitting}
                  className={clsx(
                    'w-full bg-primary-500 text-white shadow-sm hover:bg-primary-50 hover:text-primary-700 hover:outline hover:outline-primary-700 cursor-pointer',
                    'font-heading transition-all',
                    'mt-6 block rounded-md px-3 py-2 text-center font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500',
                    'data-[disabled]:opacity-75 data-[disabled]:cursor-not-allowed data-[disabled]:hover:bg-primary-500 data-[disabled]:hover:text-white data-[disabled]:hover:outline-none data-[disabled]:focus-visible:outline-none'
                  )}
                >
                  Continue
                </HeadlessButton>
                {!!error?.message && (
                  <div className="mt-2 text-sm/5 text-red-600 sm:text-xs/4">{error.message}</div>
                )}
              </form>

              <div
                aria-hidden="true"
                className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
              >
                <div
                  style={{
                    clipPath:
                      'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)'
                  }}
                  className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
