import React from 'react';

import {
  ApplicationFieldsFragment,
  ApplicationQuotableCarrierFieldsFragment,
  ApplicationQuoteFieldsFragment,
  GetBusinessApplicationDocument,
  GetBusinessApplicationQuery
} from '../../types/graphql';
import { getMerchantGraphQLClient } from '@oysterjs/core/api/merchant';
import { ApolloError } from '@apollo/client';

export const ApplicationContext = React.createContext<
  ReturnType<typeof useBusinessApplicationProvider>
>({} as ReturnType<typeof useBusinessApplicationProvider>);

export type BusinessApplication = ApplicationFieldsFragment &
  ApplicationQuoteFieldsFragment &
  ApplicationQuotableCarrierFieldsFragment;

export const useBusinessApplicationProvider = (
  id: string,
  includeQuotes: boolean,
  includeQuotableCarriers: boolean
) => {
  const [application, setApplication] = React.useState<BusinessApplication>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<ApolloError>();

  const getApplication = async () => {
    const [res] = await Promise.all([
      getMerchantGraphQLClient()
        .query<GetBusinessApplicationQuery>({
          errorPolicy: 'all',
          query: GetBusinessApplicationDocument,
          variables: { id, includeQuotes, includeQuotableCarriers }
        })
        .then((res) => {
          if (res.data?.businessApplication) {
            return res.data.businessApplication;
          }
          if (res.error?.message) {
            throw res.error;
          }

          const otherErrors = res.errors?.filter((e) => !e.extensions?.validationError) || [];
          if (otherErrors.length > 0) {
            throw new ApolloError({ graphQLErrors: otherErrors });
          }

          throw new Error('Application not found');
        }),
      new Promise((resolve) => setTimeout(resolve, 500))
    ]);

    return res;
  };

  const loadApplication = async (): Promise<BusinessApplication | null> => {
    if (!id || loading) {
      return null;
    }

    setLoading(true);

    try {
      const res = await getApplication();
      setApplication(res);
      return res;
    } catch (e) {
      setError(e as ApolloError);
    } finally {
      setLoading(false);
    }

    return null;
  };

  return {
    applicationId: id,
    application,
    loading,
    error,
    setError,
    updateApplication: setApplication,
    loadApplication,
    getApplication
  };
};
