import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Int64: { input: number; output: number };
  Time: { input: Date; output: Date };
};

export enum AchPaymentStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Sent = 'SENT'
}

/** Describes an address that can be accepted as input */
export type Address = {
  __typename?: 'Address';
  /**
   *  Level 3 administrative division depending on country. For example, this is
   * the city in the U.S., a muncipality in Austria, a ward in Singapore, etc.
   */
  city: Scalars['String']['output'];
  /** First line of the address. */
  line1: Scalars['String']['output'];
  /** Optional second line of the address. */
  line2?: Maybe<Scalars['String']['output']>;
  /**
   *  Country-specific mailing identifier, e.g. ZIP Code in the U.S., Post Code
   * in the U.K., etc.
   */
  postalCode: Scalars['String']['output'];
  /**
   *  Level 2 administrative division depending on country. For example, this is
   * the county in the U.S., prefectural city in China, division in India, etc.
   */
  subZone?: Maybe<Scalars['String']['output']>;
  /**
   *  Level 1 administrative division depending on country. For example, this is
   * the state in the U.S., the province in Canada, etc.
   */
  zone: Scalars['String']['output'];
};

/** Describes an address that can be accepted as input */
export type AddressInput = {
  /**
   *  Level 3 administrative division depending on country. For example, this is
   * the city in the U.S., a muncipality in Austria, a ward in Singapore, etc.
   */
  city?: InputMaybe<Scalars['String']['input']>;
  /** First line of the address. */
  line1?: InputMaybe<Scalars['String']['input']>;
  /** Optional second line of the address. */
  line2?: InputMaybe<Scalars['String']['input']>;
  /**
   *  Country-specific mailing identifier, e.g. ZIP Code in the U.S., Post Code
   * in the U.K., etc.
   */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /**
   *  Level 2 administrative division depending on country. For example, this is
   * the county in the U.S., prefectural city in China, division in India, etc.
   */
  subZone?: InputMaybe<Scalars['String']['input']>;
  /**
   *  Level 1 administrative division depending on country. For example, this is
   * the state in the U.S., the province in Canada, etc.
   */
  zone?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Describes the Ascend billable that this policy is associated with. This
 * type represents the response from the Ascend /v1/billables API
 */
export type AscendBillable = {
  __typename?: 'AscendBillable';
  /** Fee collected by your agency (dollars). Fully earned, not financeable, and funded directly to your agency. */
  agencyFees: Scalars['Float']['output'];
  /** The identifier assigned to the quote/endorsement by the issuing carrier or wholesaler. */
  billableIdentifier: Scalars['String']['output'];
  /** Fee collected by the wholesaler (dollars). Fully earned, not financeable, and funded directly to the wholesaler. */
  brokerFees: Scalars['Float']['output'];
  /** An insurance company. */
  carrier: AscendCarrier;
  /** A type of coverage. */
  coverageType: AscendCoverage;
  /** A short description to explain to the insured what they are purchasing. */
  description?: Maybe<Scalars['String']['output']>;
  /** The unique identifier for the billable */
  id: Scalars['String']['output'];
  /** Determines if the carrier can audit customer raising premium significantly. */
  isAuditable: Scalars['Boolean']['output'];
  /** Determines if specific lines of business are filed with other bureaus which they have to notify on cancelation. */
  isFiled: Scalars['Boolean']['output'];
  /** Determines if the policy is fined by 10% on top of earned premium when insured cancels */
  isShortRate: Scalars['Boolean']['output'];
  /** Set state determined extended cancelation period for the policy. */
  minDaysToCancel: Scalars['Int']['output'];
  /** The minimum rate to charge insureds when they decide to finance. */
  minEarnedRate: Scalars['Float']['output'];
  /** Any additional fees that come with the policy (dollars). This fee is treated as non-refundable & fully earned. */
  otherFees: Scalars['Float']['output'];
  /** Payouts created for this program, if any. This corresponds to the data returned from the /v1/payouts endpoint */
  payouts: Array<AscendPayout>;
  /** Any policy fees that come with the billable (dollars). This fee is treated as non-refundable & fully earned. */
  policyFees: Scalars['Float']['output'];
  /** The amount to pay for the insurance policy (dollars). */
  premium: Scalars['Float']['output'];
  /** Your commission rate for this policy. */
  sellerCommissionRate?: Maybe<Scalars['Float']['output']>;
  /** The amount of surplus lines tax to be collected (dollars). */
  surplusLinesTax: Scalars['Float']['output'];
  /** Any taxes & unearned fees (dollars). Taxes and fees are financed. */
  taxesAndFees: Scalars['Float']['output'];
  /** An insurance wholesaler or MGA. */
  wholesaler?: Maybe<AscendWholesaler>;
};

/** An insurance company. */
export type AscendCarrier = {
  __typename?: 'AscendCarrier';
  /** A human readable unique identifier. */
  id: Scalars['String']['output'];
  /** The carrier display name. */
  title: Scalars['String']['output'];
};

/** A type of coverage. */
export type AscendCoverage = {
  __typename?: 'AscendCoverage';
  /** A human readable unique identifier. */
  id: Scalars['String']['output'];
  /** The coverage type display name. */
  title: Scalars['String']['output'];
};

/** A document representing a transaction between the insured and Ascend. */
export type AscendInvoice = {
  __typename?: 'AscendInvoice';
  /** The checkout url of the invoice. */
  checkoutUrl?: Maybe<Scalars['String']['output']>;
  /** The date by which the invoice should be paid. */
  dueDate?: Maybe<Scalars['Time']['output']>;
  /** The invoice unique identifier. */
  id: Scalars['String']['output'];
  /** The unique identifier of the insured invoice is attached to. */
  insuredID: Scalars['String']['output'];
  /** A number assigned to uniquely identify the invoice. */
  invoiceNumber: Scalars['String']['output'];
  /** The url of the invoice document. */
  invoiceUrl?: Maybe<Scalars['String']['output']>;
  /** The date when the invoice was shared with the insured. */
  issuedAt?: Maybe<Scalars['Time']['output']>;
  /** The date when the invoice was paid by the insured. */
  paidAt?: Maybe<Scalars['Time']['output']>;
  /** The name of the insured/business paying for the invoice. */
  payerName: Scalars['String']['output'];
  /** It represents the payment instrument used by an insured. */
  paymentMethod?: Maybe<AscendPaymentMethod>;
  /** The unique identifier of the program invoice is attached to. */
  programID?: Maybe<Scalars['String']['output']>;
  /** The current state of the invoice. */
  status: Scalars['String']['output'];
  /** The total amount charged, in dollars. */
  totalAmount: Scalars['Float']['output'];
};

/** A loan associated with an Ascend program */
export type AscendLoan = {
  __typename?: 'AscendLoan';
  /** The amount financed through the loan represented in dollars. */
  amountFinanced: Scalars['Float']['output'];
  /** The total annual rate charged. */
  apr: Scalars['Float']['output'];
  /** The date when the loan was created. The date uses UTC and is represented in the ISO8601 format. */
  createdAt: Scalars['Time']['output'];
  /** The initial up-front payment represented in dollars. */
  downPayment: Scalars['Float']['output'];
  /** The loan unique identifier. */
  id: Scalars['String']['output'];
  /** The number of payments that will be made to repay the loan. */
  numberOfPayments: Scalars['Int']['output'];
  /** The current state of the loan. */
  status: Scalars['String']['output'];
  /** The amount that will be payed in each payment in dollars. */
  termPayment: Scalars['Float']['output'];
  /** The date when the loan was last updated. The date uses UTC and is represented in the ISO8601 format. */
  updatedAt: Scalars['Time']['output'];
};

/** The account the Ascend payout was made to */
export type AscendPayableAccount = {
  __typename?: 'AscendPayableAccount';
  /** The ID of the payable account */
  id: Scalars['String']['output'];
  /** The last four digits of the bank account */
  lastFour: Scalars['String']['output'];
  /** The owner name of the bank account */
  ownerName: Scalars['String']['output'];
  /** The routing number of the bank account */
  routingNumber: Scalars['String']['output'];
  /** The usage type of the bank account */
  usageType: AscendPayableAccountUsageType;
};

export enum AscendPayableAccountUsageType {
  /** Organization's bank account we transfer commissions to. */
  CommissionAccount = 'COMMISSION_ACCOUNT',
  /** Paid out to carrier/wholesaler */
  SupplierAccount = 'SUPPLIER_ACCOUNT',
  /** Organization's bank account we transfer full premium to. */
  TrustAccount = 'TRUST_ACCOUNT'
}

export type AscendPaymentMethod = {
  __typename?: 'AscendPaymentMethod';
  type: AscendPaymentMethodType;
};

export enum AscendPaymentMethodType {
  AchCreditTransfer = 'ACH_CREDIT_TRANSFER',
  AchDebit = 'ACH_DEBIT',
  Card = 'CARD'
}

/** A payout from Ascend to an entity */
export type AscendPayout = {
  __typename?: 'AscendPayout';
  /** The date the payout was created */
  createdAt: Scalars['Time']['output'];
  /** The date that the payout failed */
  failedAt?: Maybe<Scalars['Time']['output']>;
  /** The gross amount paid out, in dollars. */
  grossPayoutAmount: Scalars['Float']['output'];
  /** The payout unique identifier */
  id: Scalars['String']['output'];
  /** If found, the details of the bank transaction on Oyster's side */
  linkedBankTransaction?: Maybe<LinkedBankTransaction>;
  /** The net amount paid out, in dollars. */
  netPayoutAmount: Scalars['Float']['output'];
  /** The date that the payout was paid */
  paidAt?: Maybe<Scalars['Time']['output']>;
  /** The account that the payout was made to */
  payableAccount: AscendPayableAccount;
  /** The date that the payout will be paid */
  payingAt?: Maybe<Scalars['Time']['output']>;
  /** The type of the recipient */
  recipientType: AscendRecipientType;
  /** The reference number included in the bank memo associated with this payout */
  reference?: Maybe<Scalars['String']['output']>;
  /** The current state of the payout */
  status: AscendPayoutStatus;
  /** The type of the payout */
  type: AscendPayoutType;
};

/** The status of the payout */
export enum AscendPayoutStatus {
  /** The payout has been canceled */
  Canceled = 'CANCELED',
  /** The payout has failed to reach the payable account. */
  Failed = 'FAILED',
  /** The payout is blocked until the invoice release_date when funds can be paid to the payable account. */
  OnHold = 'ON_HOLD',
  /** The payout has been paid to designated PayableAccount */
  Paid = 'PAID',
  /** The payout has been initiated but we have not received confirmation that it has cleared. */
  Paying = 'PAYING',
  /** The payout is waiting for confirmation from servicer indicating it has been funded. */
  Transferred = 'TRANSFERRED',
  /** Initial state, no action has been taken on the payout. */
  Unpaid = 'UNPAID'
}

/** The type of the payout */
export enum AscendPayoutType {
  /** Transfer cumulative supplier funding to org so that they can pay out to supplier - paid to the organization's trust account */
  AgentSupplierFunding = 'AGENT_SUPPLIER_FUNDING',
  /** Policy's commission paid to organization's commission account */
  Commission = 'COMMISSION',
  /** Policy's full premium (premium w/ commission) paid to organization's trust account */
  FullPremium = 'FULL_PREMIUM',
  /** Policy's net premium (full premium - commission) paid out to org's trust account (direct bill) */
  NetPremium = 'NET_PREMIUM',
  /** Payout to agency's commission account for one-off invoice */
  OneOff = 'ONE_OFF',
  /** Payout to org for additional funds they have transferred to us - paid to the organization's commission account */
  Overpayment = 'OVERPAYMENT',
  /** Policy's premium paid out to supplier's honor_supplier_account or supplier_account (agency bill) */
  Supplier = 'SUPPLIER',
  /** Payout to agency's trust account for surplus line taxes */
  SurplusLinesTax = 'SURPLUS_LINES_TAX',
  /** Financed policy's downpayment paid to honor's downpayment account */
  TakeRate = 'TAKE_RATE'
}

/** A collection of insurance quotes pooled to produce 1 checkout link. */
export type AscendProgram = {
  __typename?: 'AscendProgram';
  /** The date the program was archived. The date uses UTC and is represented in the ISO8601 format. */
  archivedAt?: Maybe<Scalars['Time']['output']>;
  /** The date the program was checked out. The date uses UTC and is represented in the ISO8601 format. */
  checkedOutAt?: Maybe<Scalars['Time']['output']>;
  /** The date the program was created. The date uses UTC and is represented in the ISO8601 format. */
  createdAt: Scalars['Time']['output'];
  /** The unique identifier for the program */
  id: Scalars['String']['output'];
  /** Invoices created for this program, if any. This corresponds to the data returned from the /v1/invoices endpoint */
  invoices: Array<AscendInvoice>;
  /** Financing details for this program, if any. This corresponds to the data returned from the /v1/loans endpoint */
  loans: Array<AscendLoan>;
  /** A URL to complete the checkout process. */
  programUrl: Scalars['String']['output'];
  /** The payment option chosen by the insured during the checkout process. */
  selectedPaymentOptionType?: Maybe<PaymentOptionType>;
  /** The state of the program */
  status: AscendProgramStatus;
  /** The date the program was last updated. The date uses UTC and is represented in the ISO8601 format. */
  updatedAt: Scalars['Time']['output'];
};

export enum AscendProgramCreationMode {
  BundleWithExistingQuote = 'BUNDLE_WITH_EXISTING_QUOTE',
  CreateProgram = 'CREATE_PROGRAM',
  UseSpecificProgramId = 'USE_SPECIFIC_PROGRAM_ID'
}

/** The status of a program */
export enum AscendProgramStatus {
  Archived = 'ARCHIVED',
  CheckedOut = 'CHECKED_OUT',
  Created = 'CREATED',
  Elected = 'ELECTED',
  Purchased = 'PURCHASED',
  ReadyForCheckout = 'READY_FOR_CHECKOUT'
}

/** The type of the Ascend recipient */
export enum AscendRecipientType {
  Account = 'ACCOUNT',
  Carrier = 'CARRIER',
  Organization = 'ORGANIZATION',
  Wholesaler = 'WHOLESALER'
}

/** An insurance wholesaler or MGA. */
export type AscendWholesaler = {
  __typename?: 'AscendWholesaler';
  /** A human readable unique identifier. */
  id: Scalars['String']['output'];
  /** The wholesaler display name. */
  title: Scalars['String']['output'];
};

export type AvailableCoverageLimit = {
  __typename?: 'AvailableCoverageLimit';
  insuranceType: InsuranceType;
  limits: InsuranceLimits;
};

/** Represents the construction type of a building. */
export enum BuildingConstructionType {
  /** A fire resistive construction type. */
  FireResistive = 'FIRE_RESISTIVE',
  /** A frame construction type. */
  Frame = 'FRAME',
  /** A joisted masonry construction type. */
  JoistedMasonry = 'JOISTED_MASONRY',
  /** A masonry non-combustible construction type. */
  MasonryNonCombustible = 'MASONRY_NON_COMBUSTIBLE',
  /** A modified fire resistive construction type. */
  ModifiedFireResistive = 'MODIFIED_FIRE_RESISTIVE',
  /** A non-combustible construction type. */
  NonCombustible = 'NON_COMBUSTIBLE'
}

/** Represents the building location ownership type */
export enum BuildingOwnershipType {
  /** The building is a home that is owned by the business. */
  Home = 'HOME',
  /** The building is leased by the business. */
  Leased = 'LEASED',
  /** The building is owned by the business. */
  Owned = 'OWNED'
}

/** Represents the type of roof on a building. */
export enum BuildingRoofType {
  /** Asphalt shingles roof type. */
  AsphaltShingles = 'ASPHALT_SHINGLES',
  /** Built-up gravel roof type. */
  BuiltUpGravel = 'BUILT_UP_GRAVEL',
  /** Built-up without gravel roof type. */
  BuiltUpWithoutGravel = 'BUILT_UP_WITHOUT_GRAVEL',
  /** Clay tiles roof type. */
  ClayTiles = 'CLAY_TILES',
  /** Foam roof type. */
  Foam = 'FOAM',
  /** Metal roof type. */
  Metal = 'METAL',
  /** Modified bitumen roof type. */
  ModifiedBitumen = 'MODIFIED_BITUMEN',
  /** Single-ply ballasted roof type. */
  SinglePlyBallasted = 'SINGLE_PLY_BALLASTED',
  /** Single-ply PVC TPO roof type. */
  SinglePlyPvcTpo = 'SINGLE_PLY_PVC_TPO',
  /** Single-ply SPDM rubber roof type. */
  SinglePlySpdmRubber = 'SINGLE_PLY_SPDM_RUBBER',
  /** Wood shingles roof type. */
  WoodShingles = 'WOOD_SHINGLES'
}

/** Represents the type of burglar alarm installed at a business location. */
export enum BurglarAlarmType {
  /** A central burglar alarm type. */
  BurglarCentral = 'BURGLAR_CENTRAL',
  /** A local burglar alarm type. */
  BurglarLocal = 'BURGLAR_LOCAL',
  /** No burglar alarm. */
  BurglarNone = 'BURGLAR_NONE',
  /** A police and fire burglar alarm type. */
  BurglarPoliceFire = 'BURGLAR_POLICE_FIRE'
}

/** BusinessApplication represents an application */
export type BusinessApplication = {
  __typename?: 'BusinessApplication';
  /** The annual revenue of the business. */
  annualRevenue: Scalars['Float']['output'];
  /** The 'doing business as' name of the business. */
  businessDba: Scalars['String']['output'];
  /** The name of the business. */
  businessName: Scalars['String']['output'];
  /** The primary contact information for the business. */
  contact: BusinessContact;
  /** CoverForce applications associated with this application */
  coverforceApplications: Array<CoverforceApplication>;
  /** The date the application was originally created */
  createdAt: Scalars['Time']['output'];
  /** Information about the employees at the business */
  employeeInfo: BusinessEmployeeInfo;
  /** The Federal Employer Identification Number of the business. */
  fein: Scalars['String']['output'];
  /** SHA-256 hash of the application data */
  fingerprint: Scalars['String']['output'];
  /** The ID of the business application */
  id: Scalars['ID']['output'];
  /** The desired effective date of the insurance. */
  insuranceEffectiveAt?: Maybe<Scalars['Time']['output']>;
  /** The types of insurance the business is applying for. */
  insuranceTypes: Array<InsuranceType>;
  /** Indicates whether the business is a non-profit organization. */
  isNonProfit: Scalars['Boolean']['output'];
  /** The legal entity type of the business. */
  legalEntityType?: Maybe<BusinessLegalEntityType>;
  /** List of locations associated with the business. */
  locations: Array<BusinessLocation>;
  /** The mailing address of the business. */
  mailingAddress: Address;
  /** The North American Industry Classification System code of the business. */
  naicsCode: Scalars['String']['output'];
  /** A description of the nature of the business. */
  natureOfBusiness: Scalars['String']['output'];
  /** Information about the business owners. */
  owners: Array<BusinessOwner>;
  /** Any past policy losses */
  pastPolicyLosses: Array<PastPolicyLoss>;
  /**
   * Carriers that will quote one or more coverages for this application.
   * Can only be queried after the application has been completed.
   */
  quotableCarriers: Array<InsuranceCarrier>;
  /** Current quotes for this application, if any are available. */
  quotes: Array<BusinessApplicationQuote>;
  /** Carriers selected by the customer to generate quotes for. */
  selectedCarriers: Array<BusinessApplicationCarrier>;
  /** The selected insurance limits for the application. */
  selectedLimits: InsuranceLimits;
  /** State of the application */
  state: BusinessApplicationState;
  /** Answers to underwriting questions. */
  underwritingAnswers: Array<UnderwritingAnswer>;
  /** Underwriting questions needed on this application. */
  underwritingQuestions: Array<UnderwritingQuestion>;
  /** Underwriting statements and disclosures that the insured needs to acknowledge. */
  underwritingStatements: Array<UnderwritingStatement>;
  /** The date this application was last updated */
  updatedAt: Scalars['Time']['output'];
  /** The version of this application */
  version: Scalars['Int64']['output'];
  /**
   * The previous versions of this application, subject to the specified limit and offset.
   * The first version in this list will be this version (i.e. identical to the current application), followed by the version before that, and so-forth.
   */
  versionHistory: Array<BusinessApplicationVersion>;
  /** The website of the business. */
  website: Scalars['String']['output'];
  /** The year the business was founded. */
  yearOfFounding: Scalars['String']['output'];
  /** The number of years of management experience the business has. */
  yearsOfManagementExperience: Scalars['Int']['output'];
};

/** BusinessApplication represents an application */
export type BusinessApplicationVersionHistoryArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export enum BusinessApplicationCarrier {
  Amtrust = 'AMTRUST',
  Biberk = 'BIBERK',
  Chubb = 'CHUBB',
  Cna = 'CNA',
  Coalition = 'COALITION',
  Coterie = 'COTERIE',
  Employers = 'EMPLOYERS',
  Gaig = 'GAIG',
  Guard = 'GUARD',
  Hiscox = 'HISCOX',
  Libertymutual = 'LIBERTYMUTUAL',
  Markel = 'MARKEL',
  Nationwide = 'NATIONWIDE',
  Next = 'NEXT',
  Progressive = 'PROGRESSIVE',
  Travelers = 'TRAVELERS'
}

export type BusinessApplicationQuote = {
  __typename?: 'BusinessApplicationQuote';
  /** The carrier that has offered this quote */
  carrier: BusinessApplicationCarrier;
  /** The link to the carrier portal to view and modify the quote */
  carrierBridgingLink?: Maybe<Scalars['String']['output']>;
  /** The link to the carrier portal to bind and claim the policy */
  carrierPurchaseLink?: Maybe<Scalars['String']['output']>;
  /** The ID of the quote in the carrier system */
  carrierQuoteId: Scalars['String']['output'];
  /** The ID of the quote */
  id: Scalars['String']['output'];
  /** The effective date of the insurance */
  insuranceEffectiveDate: Scalars['Time']['output'];
  /** The expiration date of the insurance */
  insuranceExpiryDate: Scalars['Time']['output'];
  /** List of payment plans available for this quote if the quote is bindable */
  paymentPlans: Array<BusinessApplicationQuotePaymentPlan>;
  /** If this policy is binding, the policy ID from the carrier system. */
  policyId?: Maybe<Scalars['String']['output']>;
  /** The type of insurance policy */
  policyType: InsuranceType;
  /** The premium amount for the policy */
  premiumAmount: Scalars['Float']['output'];
  /** The state of the quote */
  state: BusinessApplicationQuoteState;
  /** The tax amount for the policy */
  taxAmount: Scalars['Float']['output'];
  /** The total amount for the policy */
  totalAmount: Scalars['Float']['output'];
  /** The type of quote */
  type: BusinessApplicationQuoteType;
};

export type BusinessApplicationQuotePaymentPlan = {
  __typename?: 'BusinessApplicationQuotePaymentPlan';
  description: Scalars['String']['output'];
  downAmount: Scalars['Float']['output'];
  downDueAt?: Maybe<Scalars['Time']['output']>;
  installmentAmount: Scalars['Float']['output'];
  installmentCount: Scalars['Int']['output'];
  installmentDueAt?: Maybe<Scalars['Time']['output']>;
  installmentFee: Scalars['Float']['output'];
  installmentFrequency: PaymentPlanInstallmentFrequency;
  paymentMethod: PaymentPlanPaymentMethodType;
  paymentPlanId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  totalAmount: Scalars['Float']['output'];
  totalFees: Scalars['Float']['output'];
};

export enum BusinessApplicationQuoteState {
  Binding = 'BINDING',
  Created = 'CREATED'
}

export enum BusinessApplicationQuoteType {
  BindOnline = 'BIND_ONLINE',
  Bridge = 'BRIDGE',
  Decline = 'DECLINE',
  Failed = 'FAILED',
  Refer = 'REFER'
}

/** The state of business application */
export enum BusinessApplicationState {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Quoted = 'QUOTED',
  Quoting = 'QUOTING',
  Submitted = 'SUBMITTED'
}

/** Describes the reason why an application was updated */
export enum BusinessApplicationUpdateReasonCode {
  /** Placeholder reason codes until the use case becomes more clear */
  Unknown = 'UNKNOWN'
}

/** Describes the type of update that occured to a application */
export enum BusinessApplicationUpdateType {
  /** The application was canceled and is no longer active */
  CancelApplication = 'CANCEL_APPLICATION',
  /** The application was created, either directely or by completing an offer */
  CreateApplication = 'CREATE_APPLICATION',
  /** The application was completed and submitted, and is now ready to be reviewed by the carrier */
  SubmitApplication = 'SUBMIT_APPLICATION',
  /** The application was updated. Requires manual inspection of the application to determine the actual update */
  UpdateApplication = 'UPDATE_APPLICATION'
}

/** Describes a particular version of an application */
export type BusinessApplicationVersion = {
  __typename?: 'BusinessApplicationVersion';
  /** The full application object that includes all of the updates made in this version and all previous versions */
  businessApplication: BusinessApplication;
  /** The date this version was created */
  createdAt: Scalars['Time']['output'];
  /** Produces a human-readable string that can be used to diff against another application version. */
  diffableJson: Scalars['String']['output'];
  /** The version number of the previous application version, if there is any. If this field is null, then this version is the only version */
  previousVersion?: Maybe<BusinessApplicationVersion>;
  /** The ID of the entity that initiated the update, depending on the type */
  updateInitiatorId: Scalars['String']['output'];
  /** The type of the entity that initiated the update */
  updateInitiatorType: UpdateInitiatorType;
  /** Indicates whether this update is 'private' in the sense that it should not be displayed to the policyholder */
  updatePrivate: Scalars['Boolean']['output'];
  /** A human-readable description of the reason why this update was made */
  updateReason?: Maybe<Scalars['String']['output']>;
  /** A programmatic representation of the reason why this update was made */
  updateReasonCode?: Maybe<BusinessApplicationUpdateReasonCode>;
  /** The types of updates made between this version and the previous version */
  updateTypes: Array<BusinessApplicationUpdateType>;
  /** The version number of this application version */
  version: Scalars['Int64']['output'];
};

/** Represents a contact person for the business. */
export type BusinessContact = {
  __typename?: 'BusinessContact';
  /** The email address of the contact person. */
  email: Scalars['String']['output'];
  /** The name of the contact person. */
  fullName: Scalars['String']['output'];
  /** The phone number of the contact person. */
  phone: Scalars['String']['output'];
};

/** Represents a contact person for the business. */
export type BusinessContactInput = {
  /** The email address of the contact person. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The name of the contact person. */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** The phone number of the contact person. */
  phone?: InputMaybe<Scalars['String']['input']>;
};

/** Represents information about the employees at a business location. */
export type BusinessEmployeeInfo = {
  __typename?: 'BusinessEmployeeInfo';
  /** The job code ID associated with the employees. */
  jobCode: Scalars['String']['output'];
  /** The number of full-time employees at the business location. */
  numFullTimeEmployees: Scalars['Int']['output'];
  /** The number of part-time employees at the business location. */
  numPartTimeEmployees: Scalars['Int']['output'];
  /** The total payroll amount for all employees at the business location. */
  totalEmployeePayroll: Scalars['Float']['output'];
};

/** Represents information about the employees at a business location. */
export type BusinessEmployeeInfoInput = {
  /** The job code ID associated with the employees. */
  jobCode?: InputMaybe<Scalars['String']['input']>;
  /** The number of full-time employees at the business location. */
  numFullTimeEmployees?: InputMaybe<Scalars['Int']['input']>;
  /** The number of part-time employees at the business location. */
  numPartTimeEmployees?: InputMaybe<Scalars['Int']['input']>;
  /** The total payroll amount for all employees at the business location. */
  totalEmployeePayroll?: InputMaybe<Scalars['Float']['input']>;
};

export type BusinessInsurancePersonalizationInput = {
  insuranceOtherDesc?: InputMaybe<Scalars['String']['input']>;
  insuranceTypes: Array<InsuranceType>;
  operationOtherDesc?: InputMaybe<Scalars['String']['input']>;
  operationTypes: Array<OperationType>;
  revenueManufacturing?: InputMaybe<Scalars['Float']['input']>;
  revenueOther?: InputMaybe<Scalars['Float']['input']>;
  revenueRental?: InputMaybe<Scalars['Float']['input']>;
  revenueRentalPercentGuided?: InputMaybe<Scalars['Float']['input']>;
  revenueRetail?: InputMaybe<Scalars['Float']['input']>;
  revenueServiceOrRepair?: InputMaybe<Scalars['Float']['input']>;
  revenueWholesale?: InputMaybe<Scalars['Float']['input']>;
  totalPayroll?: InputMaybe<Scalars['Float']['input']>;
};

/** Represents the legal entity type of a business. */
export enum BusinessLegalEntityType {
  /** An association. */
  Association = 'ASSOCIATION',
  /** A corporation. */
  Corporation = 'CORPORATION',
  /** An estate. */
  Estate = 'ESTATE',
  /** An executor. */
  Executor = 'EXECUTOR',
  /** A general partnership. */
  GeneralPartnership = 'GENERAL_PARTNERSHIP',
  /** A government entity. */
  GovernmentEntity = 'GOVERNMENT_ENTITY',
  /** An individual. */
  Individual = 'INDIVIDUAL',
  /** Joint employers. */
  JointEmployers = 'JOINT_EMPLOYERS',
  /** A joint venture. */
  JointVenture = 'JOINT_VENTURE',
  /** A labor union. */
  LaborUnion = 'LABOR_UNION',
  /** A limited liability company. */
  LimitedLiabilityCompany = 'LIMITED_LIABILITY_COMPANY',
  /** A limited liability partnership. */
  LimitedLiabilityPartnership = 'LIMITED_LIABILITY_PARTNERSHIP',
  /** A limited partnership. */
  LimitedPartnership = 'LIMITED_PARTNERSHIP',
  /** Multiple statuses. */
  MultipleStatus = 'MULTIPLE_STATUS',
  /** A non-profit association. */
  NonProfitAssociation = 'NON_PROFIT_ASSOCIATION',
  /** A non-profit corporation. */
  NonProfitCorporation = 'NON_PROFIT_CORPORATION',
  /** An S corporation. */
  SCorporation = 'S_CORPORATION',
  /** Tenants in common. */
  TenantsInCommon = 'TENANTS_IN_COMMON',
  /** A trust. */
  Trust = 'TRUST'
}

/** Represents a business location. */
export type BusinessLocation = {
  __typename?: 'BusinessLocation';
  /** The address of the business location. */
  address: Address;
  /** Information about the building of the business location. */
  buildingInfo: BusinessLocationBuildingInfo;
  /** Information about the employees at the business location. */
  employeeInfo: BusinessEmployeeInfo;
  /** The unique identifier of the business location. */
  id: Scalars['ID']['output'];
  /** Indicates whether this is the primary business location. */
  isPrimary: Scalars['Boolean']['output'];
};

/** Represents information about the building of a business location. */
export type BusinessLocationBuildingInfo = {
  __typename?: 'BusinessLocationBuildingInfo';
  /** The annual sales of the business location. */
  annualSales: Scalars['Float']['output'];
  /** The area occupied by the business. */
  areaOccupiedByBusiness: Scalars['Float']['output'];
  /** The building coverage amount. */
  buildingCoverage: Scalars['Float']['output'];
  /** The type of burglar alarm installed at the business location. */
  burglarAlarmType?: Maybe<BurglarAlarmType>;
  /** The construction type of the building. */
  constructionType?: Maybe<BuildingConstructionType>;
  /** The year the electrical wiring was last updated. */
  electricalWiringUpdateYear: Scalars['String']['output'];
  /** The year the heating system was last updated. */
  heatingUpdateYear: Scalars['String']['output'];
  /** The type of ownership of this business location. */
  ownershipType?: Maybe<BuildingOwnershipType>;
  /** The personal property coverage amount. */
  personalPropertyCoverage: Scalars['Float']['output'];
  /** The year the plumbing system was last updated. */
  plumbingUpdateYear: Scalars['String']['output'];
  /** The type of roof on the building. */
  roofType?: Maybe<BuildingRoofType>;
  /** The year the roof was last updated. */
  roofUpdateYear: Scalars['String']['output'];
  /** The percentage of the building that is sprinklered. */
  sprinkleredPercentage: Scalars['Float']['output'];
  /** The total area of the building. */
  totalArea: Scalars['Float']['output'];
  /** The total number of stories in the building. */
  totalStories: Scalars['Int']['output'];
  /** The year the building was built. */
  yearBuilt: Scalars['String']['output'];
};

/** Represents information about the building of a business location. */
export type BusinessLocationBuildingInfoInput = {
  /** The annual sales of the business location. */
  annualSales?: InputMaybe<Scalars['Float']['input']>;
  /** The area occupied by the business. */
  areaOccupiedByBusiness?: InputMaybe<Scalars['Float']['input']>;
  /** The building coverage amount. */
  buildingCoverage?: InputMaybe<Scalars['Float']['input']>;
  /** The type of burglar alarm installed at the business location. */
  burglarAlarmType?: InputMaybe<BurglarAlarmType>;
  /** The construction type of the building. */
  constructionType?: InputMaybe<BuildingConstructionType>;
  /** The year the electrical wiring was last updated. */
  electricalWiringUpdateYear?: InputMaybe<Scalars['String']['input']>;
  /** The year the heating system was last updated. */
  heatingUpdateYear?: InputMaybe<Scalars['String']['input']>;
  /** Indicates whether the business owns the building. */
  ownershipType?: InputMaybe<BuildingOwnershipType>;
  /** The personal property coverage amount. */
  personalPropertyCoverage?: InputMaybe<Scalars['Float']['input']>;
  /** The year the plumbing system was last updated. */
  plumbingUpdateYear?: InputMaybe<Scalars['String']['input']>;
  /** The type of roof on the building. */
  roofType?: InputMaybe<BuildingRoofType>;
  /** The year the roof was last updated. */
  roofUpdateYear?: InputMaybe<Scalars['String']['input']>;
  /** The percentage of the building that is sprinklered. */
  sprinkleredPercentage?: InputMaybe<Scalars['Float']['input']>;
  /** The total area of the building. */
  totalArea?: InputMaybe<Scalars['Float']['input']>;
  /** The total number of stories in the building. */
  totalStories?: InputMaybe<Scalars['Int']['input']>;
  /** The year the building was built. */
  yearBuilt?: InputMaybe<Scalars['String']['input']>;
};

export type BusinessLocationInput = {
  address: AddressInput;
  buildingInfo: BusinessLocationBuildingInfoInput;
  employeeInfo: BusinessEmployeeInfoInput;
  id: Scalars['String']['input'];
  isPrimary: Scalars['Boolean']['input'];
};

/** Represents an owner of the business. */
export type BusinessOwner = {
  __typename?: 'BusinessOwner';
  /** The annual payroll of the business owner. */
  annualPayroll: Scalars['Float']['output'];
  /** The date of birth of the business owner. */
  dateOfBirth?: Maybe<Scalars['Time']['output']>;
  /** The full name of the business owner. */
  fullName: Scalars['String']['output'];
  /** The job code associated with the business owner. */
  jobCode?: Maybe<Scalars['String']['output']>;
  /** The location ID associated with the business owner. */
  locationId: Scalars['String']['output'];
};

/** Represents an owner of the business. */
export type BusinessOwnerInput = {
  /** The annual payroll of the business owner. */
  annualPayroll: Scalars['Float']['input'];
  /** The date of birth of the business owner. */
  dateOfBirth: Scalars['String']['input'];
  /** The full name of the business owner. */
  fullName: Scalars['String']['input'];
  /** The job code of the business owner. */
  jobCode: Scalars['String']['input'];
};

/** Business Policy represents the business or commercial insurance policy */
export type BusinessPolicy = {
  __typename?: 'BusinessPolicy';
  /** The broker providing this policy, if any */
  broker?: Maybe<InsuranceCarrier>;
  /** If the policy was canceled or nonrenewed, information about the latest instance of cancellation or nonrenewal */
  cancellation?: Maybe<BusinessPolicyCancellation>;
  /** The carrier providing this policy */
  carrier: InsuranceCarrier;
  /** The date the policy object was originally created */
  createdAt: Scalars['Time']['output'];
  /** The timestamp when this policy expires */
  expiresAt?: Maybe<Scalars['Time']['output']>;
  /** The external/carrier ID of the policy */
  externalId: Scalars['ID']['output'];
  /** All attached files for this application */
  files: Array<File>;
  /** The internal ID of the policy */
  id: Scalars['ID']['output'];
  /** The timestamp when this policy is inforce */
  inforceAt?: Maybe<Scalars['Time']['output']>;
  /** The timestamp when this policy was issued */
  issuedAt?: Maybe<Scalars['Time']['output']>;
  /** The market this policy was written in */
  market: InsuranceMarket;
  /** Merchant that is associated with the policy */
  merchant?: Maybe<Merchant>;
  /** The number or external identifier associated with this policy within the carrier system */
  policyNumber: Scalars['String']['output'];
  /** The pricing information for this policy */
  pricing: BusinessPolicyPricing;
  /** The number or external identifier associated with this quote within the carrier system */
  quoteNumber: Scalars['String']['output'];
  /** If the policy was reinstated, information about the latest instance of when it was reinstated */
  reinstatement?: Maybe<BusinessPolicyReinstatement>;
  /** If this policy was renewed from another policy, information about the renewal and its previous versions */
  renewal?: Maybe<BusinessPolicyRenewal>;
  /** The source of the policy, and associated details */
  source: BusinessPolicySource;
  /** The current state of the policy */
  state: PolicyState;
  /** The type of insurance this policy represents */
  type: InsuranceType;
  /** The date this policy object was last updated */
  updatedAt: Scalars['Time']['output'];
  /** The version of this policy */
  version: Scalars['Int64']['output'];
  /** The previous versions of this policy. The first version in this list will be this version (i.e. identical to the current policy), followed by the version before that, and so-forth. */
  versionHistory?: Maybe<Array<BusinessPolicyVersion>>;
};

export type BusinessPolicyAgencyBilling = {
  __typename?: 'BusinessPolicyAgencyBilling';
  ascendBillable?: Maybe<AscendBillable>;
  ascendProgram?: Maybe<AscendProgram>;
  stripeInvoice?: Maybe<StripeInvoice>;
  type: BusinessPolicyAgencyBillingType;
};

export enum BusinessPolicyAgencyBillingType {
  Ascend = 'ASCEND',
  Manual = 'MANUAL',
  StripeInvoice = 'STRIPE_INVOICE'
}

/** The billing type of policy, can be direct or agent */
export enum BusinessPolicyBillingType {
  Agency = 'AGENCY',
  Direct = 'DIRECT',
  Unknown = 'UNKNOWN'
}

export type BusinessPolicyCancellation = {
  __typename?: 'BusinessPolicyCancellation';
  canceledAt: Scalars['Time']['output'];
  canceledBy: BusinessPolicyCancellationSource;
  effectiveAt: Scalars['Time']['output'];
  reason: BusinessPolicyCancellationReason;
  reasonDescription: Scalars['String']['output'];
  type: BusinessPolicyCancellationType;
};

export enum BusinessPolicyCancellationReason {
  CarrierClosedClass = 'CARRIER_CLOSED_CLASS',
  CarrierLossHistory = 'CARRIER_LOSS_HISTORY',
  CarrierNonpayment = 'CARRIER_NONPAYMENT',
  CarrierUnderwritingReason = 'CARRIER_UNDERWRITING_REASON',
  InsuredClosedBusiness = 'INSURED_CLOSED_BUSINESS',
  InsuredFoundBetterCoverage = 'INSURED_FOUND_BETTER_COVERAGE',
  InsuredFoundBetterPrice = 'INSURED_FOUND_BETTER_PRICE',
  InsuredNoExposure = 'INSURED_NO_EXPOSURE',
  Other = 'OTHER',
  OysterFoundBetterCoverage = 'OYSTER_FOUND_BETTER_COVERAGE',
  OysterFoundBetterPrice = 'OYSTER_FOUND_BETTER_PRICE'
}

export enum BusinessPolicyCancellationSource {
  Carrier = 'CARRIER',
  Insured = 'INSURED',
  Oyster = 'OYSTER'
}

export enum BusinessPolicyCancellationType {
  Cancellation = 'CANCELLATION',
  Nonrenewal = 'NONRENEWAL',
  QuoteExpired = 'QUOTE_EXPIRED'
}

export type BusinessPolicyInput = {
  agencyBillingType?: InputMaybe<BusinessPolicyAgencyBillingType>;
  /** Additional fees collected by Oyster as revenue. */
  agencyFees: Scalars['Float']['input'];
  ascendExistingQuoteRefId?: InputMaybe<Scalars['String']['input']>;
  ascendProgramCreationMode?: InputMaybe<AscendProgramCreationMode>;
  ascendProgramId?: InputMaybe<Scalars['String']['input']>;
  billingType: BusinessPolicyBillingType;
  /** Fees collected by the wholesale broker */
  brokerFees: Scalars['Float']['input'];
  /** The portion of the wholesale broker fees that get kicked back to Oyster, as revenue */
  brokerFeesOysterPortion: Scalars['Float']['input'];
  brokerId?: InputMaybe<Scalars['String']['input']>;
  carrierId: Scalars['String']['input'];
  estimatedCommission: Scalars['Float']['input'];
  expiresAt?: InputMaybe<Scalars['Time']['input']>;
  id: Scalars['String']['input'];
  inforceAt?: InputMaybe<Scalars['Time']['input']>;
  issuedAt?: InputMaybe<Scalars['Time']['input']>;
  market: InsuranceMarket;
  minimumEarnedPremium: Scalars['Float']['input'];
  /** Other fees incurred on this policy, not collected by Oyster as revenue */
  otherFees: Scalars['Float']['input'];
  /** Payment interval of the policy. If billed via ASCEND, leave this blank */
  paymentIntervalType?: InputMaybe<PaymentIntervalType>;
  paymentOccurrencesPerInterval: Scalars['Int']['input'];
  /** Fees collected by the carrier to administer the policy or program */
  policyFees: Scalars['Float']['input'];
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  /** The actual premium of the policy */
  premium: Scalars['Float']['input'];
  quoteNumber: Scalars['String']['input'];
  sourceAgentId: Scalars['String']['input'];
  sourceLeadId?: InputMaybe<Scalars['String']['input']>;
  sourceNotes: Scalars['String']['input'];
  sourceType: BusinessPolicySourceType;
  state: PolicyState;
  /** Taxes levied on the policy premium and any taxable fees */
  taxes: Scalars['Float']['input'];
  /** Total amount to charge for the policy, including premium and all taxes and fees */
  total: Scalars['Float']['input'];
  type: InsuranceType;
};

/** Represents all pricing information for a policy */
export type BusinessPolicyPricing = {
  __typename?: 'BusinessPolicyPricing';
  /** Actual commission received by Oyster for this policy */
  actualCommission?: Maybe<Scalars['Float']['output']>;
  /** Add a resolver to compute actual commission + our fees */
  actualRevenue: Scalars['Float']['output'];
  /** If billing type is AGENCY, this specifies information about the agency bill */
  agencyBilling?: Maybe<BusinessPolicyAgencyBilling>;
  /** Additional fees collected by Oyster as revenue. */
  agencyFees: Scalars['Float']['output'];
  /** The billing type */
  billingType: BusinessPolicyBillingType;
  /** Fees collected by the wholesale broker */
  brokerFees: Scalars['Float']['output'];
  /** The portion of the wholesale broker fees that get kicked back to Oyster, as revenue */
  brokerFeesOysterPortion: Scalars['Float']['output'];
  /** Estimate of the commission Oyster receive for this policy */
  estimatedCommission: Scalars['Float']['output'];
  /** Add a resolver to compute estimated commission + our fees */
  estimatedRevenue: Scalars['Float']['output'];
  /** The interval type of payment plan. Leave null if currently unknown */
  intervalType?: Maybe<PaymentIntervalType>;
  /** The minimum premium that must be paid for policy, regardless of cancellation, if any */
  minimumEarnedPremium: Scalars['Float']['output'];
  /** Other fees incurred on this policy, not collected by Oyster as revenue */
  otherFees: Scalars['Float']['output'];
  /** Fees collected by the carrier to administer the policy or program */
  policyFees: Scalars['Float']['output'];
  /** The premium for this policy */
  premium: Scalars['Float']['output'];
  /** Taxes levied on the policy premium and any taxable fees */
  taxes: Scalars['Float']['output'];
  /** The total price paid for this policy */
  total: Scalars['Float']['output'];
};

export type BusinessPolicyQueryInput = {
  id: Scalars['ID']['input'];
  type: BusinessPolicyQueryInputType;
};

export enum BusinessPolicyQueryInputType {
  MerchantId = 'MERCHANT_ID',
  PolicyId = 'POLICY_ID'
}

export type BusinessPolicyReinstatement = {
  __typename?: 'BusinessPolicyReinstatement';
  effectiveAt: Scalars['Time']['output'];
  reasonDescription: Scalars['String']['output'];
  reinstatedAt: Scalars['Time']['output'];
};

export type BusinessPolicyRenewal = {
  __typename?: 'BusinessPolicyRenewal';
  nextPolicies: Array<BusinessPolicy>;
  previousPolicies: Array<BusinessPolicy>;
};

export type BusinessPolicySource = {
  __typename?: 'BusinessPolicySource';
  agent?: Maybe<OysterAgent>;
  leadId?: Maybe<Scalars['String']['output']>;
  notes: Scalars['String']['output'];
  type: BusinessPolicySourceType;
};

/** Describes the source where the business deal comes from */
export enum BusinessPolicySourceType {
  BoldPenguin = 'BOLD_PENGUIN',
  GtmCrossSell = 'GTM_CROSS_SELL',
  Inbound = 'INBOUND',
  Other = 'OTHER',
  SelfServe = 'SELF_SERVE',
  Tivly = 'TIVLY'
}

/** Describes the reason why an application was updated */
export enum BusinessPolicyUpdateReasonCode {
  /** Placeholder reason codes until the use case becomes more clear */
  Unknown = 'UNKNOWN'
}

/** Describes the type of update that occured to a policy */
export enum BusinessPolicyUpdateType {
  /** The policy was canceled and is no longer active */
  CancelPolicy = 'CANCEL_POLICY',
  /** The policy was created */
  CreatePolicy = 'CREATE_POLICY',
  /** The policy expired and its no longer active */
  ExpirePolicy = 'EXPIRE_POLICY',
  /** The policy was reinstated after previously being canceled */
  ReinstatePolicy = 'REINSTATE_POLICY',
  /** The policy was created as a renewal */
  RenewPolicy = 'RENEW_POLICY',
  /** The policy was updated. Requires manual inspection of the policy to determine the actual update */
  UpdatePolicy = 'UPDATE_POLICY'
}

/** Describes a particular version of a policy */
export type BusinessPolicyVersion = {
  __typename?: 'BusinessPolicyVersion';
  /** The full policy object that includes all of the updates made in this version and all previous versions */
  businessPolicy: BusinessPolicy;
  /** The date this version was created */
  createdAt: Scalars['Time']['output'];
  /** Produces a human-readable string that can be used to diff against another policy version. */
  diffableJson: Scalars['String']['output'];
  /** The version number of the previous policy version, if there is any. If this field is null, then this version is the only version */
  previousVersion?: Maybe<BusinessPolicyVersion>;
  /** The ID of the entity that initiated the update, depending on the type */
  updateInitiatorId: Scalars['String']['output'];
  /** The type of the entity that initiated the update */
  updateInitiatorType: UpdateInitiatorType;
  /** Indicates whether this update is 'private' in the sense that it should not be displayed to the policyholder */
  updatePrivate: Scalars['Boolean']['output'];
  /** A human-readable description of the reason why this update was made */
  updateReason?: Maybe<Scalars['String']['output']>;
  /** A programmatic representation of the reason why this update was made */
  updateReasonCode?: Maybe<BusinessPolicyUpdateReasonCode>;
  /** The types of updates made between this version and the previous version */
  updateTypes: Array<BusinessPolicyUpdateType>;
  /** The version number of this policy version */
  version: Scalars['Int64']['output'];
};

export type CancelBusinessPolicyInput = {
  canceledBy: BusinessPolicyCancellationSource;
  effectiveAt: Scalars['Time']['input'];
  reason: BusinessPolicyCancellationReason;
  reasonDescription: Scalars['String']['input'];
  type: BusinessPolicyCancellationType;
};

export type CertificateCoverage = {
  __typename?: 'CertificateCoverage';
  effectiveDate: Scalars['Time']['output'];
  expirationDate: Scalars['Time']['output'];
  hasWaiverOfSubrogation: Scalars['Boolean']['output'];
  holderIsAdditionalInsured: Scalars['Boolean']['output'];
  insurer: CoverageInsurer;
  limits: Array<CertificateLimit>;
  policyNumber: Scalars['String']['output'];
  type: InsuranceType;
};

export type CertificateDetails = {
  __typename?: 'CertificateDetails';
  certificateHolder: CertificateEntity;
  certificateNumber: Scalars['String']['output'];
  coverages: Array<CertificateCoverage>;
  descriptionOfOperations: Scalars['String']['output'];
  insured: CertificateEntity;
  producer: CertificateEntity;
};

export type CertificateEntity = {
  __typename?: 'CertificateEntity';
  address: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CertificateLimit = {
  __typename?: 'CertificateLimit';
  limit: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type CoverageInsurer = {
  __typename?: 'CoverageInsurer';
  naic: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Represents information about the associated CoverForce application. */
export type CoverforceApplication = {
  __typename?: 'CoverforceApplication';
  /** The ID of the policy that is binding, if any was selected */
  bindingPolicyId?: Maybe<Scalars['String']['output']>;
  /** The ID of the quote that is binding, if any was selected */
  bindingQuoteId?: Maybe<Scalars['String']['output']>;
  /** The ID of the associated CoverForce application. */
  id: Scalars['ID']['output'];
  /** The fingerprint of the application last submitted for quotes. */
  lastApplicationFingerprint: Scalars['String']['output'];
  /** The timestamp that the Coverforce application was last submitted for quotes */
  lastQuotesRefreshedAt?: Maybe<Scalars['Time']['output']>;
  /** The type of insurance the CoverForce application is for. */
  type: InsuranceType;
};

export type CreateApplicationInput = {
  /** The legal name of the business applying for insurance. */
  businessName: Scalars['String']['input'];
  /** The email address of the contact person for the business application. */
  email: Scalars['String']['input'];
  /** The name of the contact person for the business application. */
  fullName: Scalars['String']['input'];
  /** The phone number of the contact person for the business application. */
  phone: Scalars['String']['input'];
};

export type CreateBusinessPoliciesInput = {
  insured: InsuredInput;
  merchantId: Scalars['String']['input'];
  policies: Array<BusinessPolicyInput>;
};

export type CreateFileInput = {
  bytesBase64: Scalars['String']['input'];
  name: Scalars['String']['input'];
  notes: Scalars['String']['input'];
  role: FileRole;
  visibility: FileVisibility;
};

export type CreateMerchantInput = {
  address: AddressInput;
  businessDba: Scalars['String']['input'];
  businessName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  personalization?: InputMaybe<PersonalizationInput>;
  phone: Scalars['String']['input'];
  productVertical: ProductType;
  userFirstName: Scalars['String']['input'];
  userLastName: Scalars['String']['input'];
  website: Scalars['String']['input'];
};

/**
 * A file describes a document that is attached to an object, with
 * metadata and URLs to fetch it.
 */
export type File = {
  __typename?: 'File';
  /** The internal URL to fetch the file */
  adminUrl: Scalars['String']['output'];
  /** The timestamp this file was created */
  createdAt: Scalars['Time']['output'];
  /** The details of this specific file, depending on the file role */
  details?: Maybe<FileDetails>;
  /** The unique identifier of the file */
  id: Scalars['ID']['output'];
  /** The file name, for display and download purposes */
  name: Scalars['String']['output'];
  /** The notes associated with the file */
  notes: Scalars['String']['output'];
  /** The role of the file, i.e. how it relates to the object it is attached to */
  role: FileRole;
  /** The size of the file in bytes, if available */
  size: Scalars['Int']['output'];
  /** The file MIME type */
  type: Scalars['String']['output'];
  /** The timestamp this file was updated */
  updatedAt: Scalars['Time']['output'];
  /** The public URL to fetch the file, if the file is visible to the user */
  url?: Maybe<Scalars['String']['output']>;
  /** The visibility of the file, i.e. who can view this file */
  visibility: FileVisibility;
};

export type FileDetails = CertificateDetails;

/** File role describes a type of file related to an object */
export enum FileRole {
  /** The file is a form generated as part of an application */
  ApplicationForm = 'APPLICATION_FORM',
  /** The file is a binder, a pre-coverage confirmation document */
  Binder = 'BINDER',
  /** The file is a certificate of insurance */
  Certificate = 'CERTIFICATE',
  /** The file is a template to generate a certificate of liability insurance */
  CertificateOfLiabilityInsuranceTemplate = 'CERTIFICATE_OF_LIABILITY_INSURANCE_TEMPLATE',
  /** The file is an invoice, generally for internal tracking purposes */
  Invoice = 'INVOICE',
  /** The file is a policy document */
  PolicyDocument = 'POLICY_DOCUMENT',
  /** The file role is unknown */
  Unknown = 'UNKNOWN'
}

/** File visibility describes who is able to view a file */
export enum FileVisibility {
  /** Only internal users can view this file */
  Internal = 'INTERNAL',
  /** End users and internal users can view this file */
  User = 'USER'
}

export type GenerateCertificateInput = {
  additionalInsured: Scalars['Boolean']['input'];
  additionalInsuredRelationship?: InputMaybe<Scalars['String']['input']>;
  address: AddressInput;
  name: Scalars['String']['input'];
  notes: Scalars['String']['input'];
};

export type GetBusinessApplication = {
  id?: InputMaybe<Scalars['ID']['input']>;
  merchantId?: InputMaybe<Scalars['ID']['input']>;
  policyId?: InputMaybe<Scalars['ID']['input']>;
};

export type InsuranceCarrier = {
  __typename?: 'InsuranceCarrier';
  /** If available, the ID of this carrier in Ascend */
  ascendId?: Maybe<Scalars['String']['output']>;
  /** Human readable short identifier of this carrier */
  id: Scalars['String']['output'];
  /** True if this insurance entity is a broker which we use to access a carrier */
  isBroker: Scalars['Boolean']['output'];
  /** True if this insurance entity is a carrier with binding authority */
  isCarrier: Scalars['Boolean']['output'];
  /** Legal name of this carrier */
  name: Scalars['String']['output'];
  /** The types of insurance policies supported by this carrier */
  supportedPolicies?: Maybe<Array<InsuranceType>>;
};

export type InsuranceLimits = {
  __typename?: 'InsuranceLimits';
  bopAggregateLimit?: Maybe<Scalars['Float']['output']>;
  bopPerOccurrenceLimit?: Maybe<Scalars['Float']['output']>;
  coverageState: Scalars['String']['output'];
  cyberAggregateLimit?: Maybe<Scalars['Float']['output']>;
  cyberRetentionLimit?: Maybe<Scalars['Float']['output']>;
  glAggregateLimit?: Maybe<Scalars['Float']['output']>;
  glPerOccurrenceLimit?: Maybe<Scalars['Float']['output']>;
  wcPerAccidentLimit?: Maybe<Scalars['Float']['output']>;
  wcPerDiseaseEmployeeLimit?: Maybe<Scalars['Float']['output']>;
  wcPerDiseasePolicyLimit?: Maybe<Scalars['Float']['output']>;
};

export type InsuranceLimitsInput = {
  bopAggregateLimit?: InputMaybe<Scalars['Float']['input']>;
  bopPerOccurrenceLimit?: InputMaybe<Scalars['Float']['input']>;
  coverageState: Scalars['String']['input'];
  cyberAggregateLimit?: InputMaybe<Scalars['Float']['input']>;
  cyberRetentionLimit?: InputMaybe<Scalars['Float']['input']>;
  glAggregateLimit?: InputMaybe<Scalars['Float']['input']>;
  glPerOccurrenceLimit?: InputMaybe<Scalars['Float']['input']>;
  wcPerAccidentLimit?: InputMaybe<Scalars['Float']['input']>;
  wcPerDiseaseEmployeeLimit?: InputMaybe<Scalars['Float']['input']>;
  wcPerDiseasePolicyLimit?: InputMaybe<Scalars['Float']['input']>;
};

/** Describes the market the insurance policy was written in. */
export enum InsuranceMarket {
  /** The admitted market */
  Admitted = 'ADMITTED',
  /** The Excess and Surplus (E&S) market */
  ExcessAndSurplus = 'EXCESS_AND_SURPLUS'
}

/** Insurance type for business insurance */
export enum InsuranceType {
  AccidentAndHealth = 'ACCIDENT_AND_HEALTH',
  BusinessOwners = 'BUSINESS_OWNERS',
  CommercialAuto = 'COMMERCIAL_AUTO',
  Cyber = 'CYBER',
  DirectorsAndOfficers = 'DIRECTORS_AND_OFFICERS',
  EmploymentPracticesLiability = 'EMPLOYMENT_PRACTICES_LIABILITY',
  ErrorsAndOmission = 'ERRORS_AND_OMISSION',
  GeneralLiability = 'GENERAL_LIABILITY',
  JewelersBlock = 'JEWELERS_BLOCK',
  Other = 'OTHER',
  Package = 'PACKAGE',
  ProductLiability = 'PRODUCT_LIABILITY',
  ProfessionalLiability = 'PROFESSIONAL_LIABILITY',
  Property = 'PROPERTY',
  Rental = 'RENTAL',
  ShippingAndTransportation = 'SHIPPING_AND_TRANSPORTATION',
  UmbrellaOrExcess = 'UMBRELLA_OR_EXCESS',
  WorkersCompensation = 'WORKERS_COMPENSATION'
}

export type InsuredInput = {
  addressCity: Scalars['String']['input'];
  addressLine1: Scalars['String']['input'];
  addressLine2: Scalars['String']['input'];
  addressState: Scalars['String']['input'];
  addressZipCode: Scalars['String']['input'];
  businessName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

/** Details of a bank transaction linked to a payout */
export type LinkedBankTransaction = {
  __typename?: 'LinkedBankTransaction';
  /** The amount of the transaction in dollars */
  amount: Scalars['Float']['output'];
  /** The ID of the counterparty in the bank account's system */
  counterPartyId: Scalars['String']['output'];
  /** The name of the counterparty */
  counterPartyName: Scalars['String']['output'];
  /** The date the transaction was created */
  createdAt: Scalars['Time']['output'];
  /** The link to the transaction in the partner bank's system */
  dashboardLink: Scalars['String']['output'];
  /** The bank description of the transaction */
  description: Scalars['String']['output'];
  /** The ID of the transaction in the bank account */
  id: Scalars['String']['output'];
  /** The memo line of the transaction */
  memo: Scalars['String']['output'];
  /** The status of the transaction in the bank account */
  status: AchPaymentStatus;
};

/** Describes a merchant partner's account */
export type Merchant = {
  __typename?: 'Merchant';
  /** The business' physical address */
  address: Address;
  /** Any applications that this merchant has submitted */
  applications: Array<BusinessApplication>;
  /** The date this merchant was created */
  createdAt: Scalars['Time']['output'];
  /** The business' dba */
  dba: Scalars['String']['output'];
  /** The business' website domain */
  domain: Scalars['String']['output'];
  /** The business' email address */
  email: Scalars['String']['output'];
  /** Any files that are associated with this merchant */
  files: Array<File>;
  /** The ID of the merchant */
  id: Scalars['ID']['output'];
  /** The merchant's API keys */
  keys?: Maybe<Array<MerchantKey>>;
  /** The Mercury recipient ID for this merchant, used to determine the payout information for this merchant */
  mercuryRecipientId?: Maybe<Scalars['String']['output']>;
  /** The business' name */
  name: Scalars['String']['output'];
  /** The merchant's preferred payment method */
  paymentMethod: MerchantPaymentMethod;
  /** The business' contact phone number */
  phone: Scalars['String']['output'];
  /** List of product verticals this business sells */
  productVerticals: Array<ProductType>;
  /** The date this merchant was last updated */
  updatedAt: Scalars['Time']['output'];
  /** The users that can access this merchant's account */
  users: Array<MerchantUser>;
};

/** A merchant's API key */
export type MerchantKey = {
  __typename?: 'MerchantKey';
  /** The API key the merchant uses to authenticate themselves via the API/SDK */
  apiKey: Scalars['ID']['output'];
  /** The date this API key was created */
  createdAt: Scalars['Time']['output'];
  /** The Merchant that this API key belongs to */
  merchant?: Maybe<Merchant>;
  /** The date this API key was updated */
  updatedAt: Scalars['Time']['output'];
};

/** The payment method */
export enum MerchantPaymentMethod {
  /** The merchant has opted to be paid out via physical check */
  Check = 'CHECK',
  /** The merchant has opted to be paid out via ACH and has connected a bank account */
  Electronic = 'ELECTRONIC',
  /** The merchant has not selected a payment method */
  Unknown = 'UNKNOWN'
}

/** A user that can log into the merchant's account */
export type MerchantUser = {
  __typename?: 'MerchantUser';
  /** The email address of the user */
  email: Scalars['String']['output'];
  /** The first name of the user */
  firstName: Scalars['String']['output'];
  /** The ID of the user */
  id: Scalars['ID']['output'];
  /** The last name of the user */
  lastName: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Adds a new business location to an existing business application.
   *
   * Arguments:
   *   applicationId: The unique identifier of the business application.
   *   location: The input data required to add a new business location.
   *
   * Returns:
   *   The updated BusinessApplication object with the new location added.
   */
  addBusinessLocation: BusinessApplication;
  /**
   * Binds a quote to a business application.
   *
   * Arguments:
   *   applicationId: The unique identifier of the business application.
   *   quoteId: The unique identifier of the quote to bind.
   *   paymentPlanId: The ID of the payment plan to use.
   *   paymentMethod: The payment method to use.
   *
   * Returns:
   *   The updated BusinessApplication object with the quote bound.
   */
  bindQuote: BusinessApplication;
  /** Delete the document and remove the reference from the given business policy */
  businessPolicyDeleteDocument?: Maybe<BusinessPolicy>;
  /** Upload the document for this business policy to S3 */
  businessPolicyUploadDocument: File;
  cancelBusinessPolicy: BusinessPolicy;
  /**
   * Creates a new business application.
   *
   * Arguments:
   *   application: The input data required to create a new business application.
   *
   * Returns:
   *   The created BusinessApplication object.
   */
  createBusinessApplication: BusinessApplication;
  /** Create business insurance policies according to specification */
  createBusinessPolicies: Array<BusinessPolicy>;
  createMerchant?: Maybe<Merchant>;
  /** Generate a certificate of insurance with the given inputs for the current merchant */
  generateCertificate?: Maybe<File>;
  /**
   * Starts the quote process for a business application if it's not already quoting
   *
   * Arguments:
   *   applicationId: The unique identifier of the business application.
   *
   * Returns:
   *   The updated BusinessApplication object.
   */
  quoteBusinessApplication: BusinessApplication;
  reinstateBusinessPolicy: BusinessPolicy;
  /**
   * Removes a business location from an existing business application.
   *
   * Arguments:
   *   applicationId: The unique identifier of the business application.
   *   locationId: The unique identifier of the business location to remove.
   *
   * Returns:
   *   The updated BusinessApplication object with the location removed.
   */
  removeBusinessLocation: BusinessApplication;
  renewBusinessPolicy: BusinessPolicy;
  /**
   * Submits and finalizes the business application if not already submitted. This
   * indicates that a customer has finished submitting their information and is
   * ready to be reviewed by an agent.
   *
   * Arguments:
   *   applicationId: The unique identifier of the business application.
   *
   * Returns:
   *   The updated BusinessApplication object.
   */
  submitBusinessApplication: BusinessApplication;
  /**
   * Updates an existing business application.
   *
   * Arguments:
   *   id: The unique identifier of the business application to update.
   *   application: The input data required to update the business application.
   *
   * Returns:
   *   The updated BusinessApplication object.
   */
  updateBusinessApplication: BusinessApplication;
  /**
   * Updates an existing business location in a business application.
   *
   * Arguments:
   *   applicationId: The unique identifier of the business application.
   *   locationId: The unique identifier of the business location to update.
   *   location: The input data required to update the business location.
   *
   * Returns:
   *   The updated BusinessApplication object with the updated location.
   */
  updateBusinessLocation: BusinessApplication;
  /** Update the following business insurance policy with the specified changes */
  updateBusinessPolicy: BusinessPolicy;
};

export type MutationAddBusinessLocationArgs = {
  applicationId: Scalars['ID']['input'];
  location: BusinessLocationInput;
};

export type MutationBindQuoteArgs = {
  applicationId: Scalars['ID']['input'];
  paymentMethod?: InputMaybe<PaymentPlanPaymentMethodType>;
  paymentPlanId?: InputMaybe<Scalars['String']['input']>;
  quoteId: Scalars['ID']['input'];
};

export type MutationBusinessPolicyDeleteDocumentArgs = {
  fileId: Scalars['ID']['input'];
  policyId: Scalars['ID']['input'];
};

export type MutationBusinessPolicyUploadDocumentArgs = {
  policyId: Scalars['ID']['input'];
  req: CreateFileInput;
};

export type MutationCancelBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
  req: CancelBusinessPolicyInput;
};

export type MutationCreateBusinessApplicationArgs = {
  application: CreateApplicationInput;
};

export type MutationCreateBusinessPoliciesArgs = {
  req: CreateBusinessPoliciesInput;
};

export type MutationCreateMerchantArgs = {
  req?: InputMaybe<CreateMerchantInput>;
};

export type MutationGenerateCertificateArgs = {
  req: GenerateCertificateInput;
};

export type MutationQuoteBusinessApplicationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationReinstateBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
  req: ReinstateBusinessPolicyInput;
};

export type MutationRemoveBusinessLocationArgs = {
  applicationId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
};

export type MutationRenewBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
  req: RenewBusinessPolicyInput;
};

export type MutationSubmitBusinessApplicationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationUpdateBusinessApplicationArgs = {
  application: UpdateApplicationInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateBusinessLocationArgs = {
  applicationId: Scalars['ID']['input'];
  location: BusinessLocationInput;
  locationId: Scalars['ID']['input'];
};

export type MutationUpdateBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
  req: UpdateBusinessPolicyInput;
};

export enum OperationType {
  ManufacturingDesign = 'MANUFACTURING_DESIGN',
  Other = 'OTHER',
  Rental = 'RENTAL',
  Retail = 'RETAIL',
  Service = 'SERVICE',
  Wholesale = 'WHOLESALE'
}

export type OysterAgent = {
  __typename?: 'OysterAgent';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PastPolicyLoss = {
  __typename?: 'PastPolicyLoss';
  /** The date when the claim was made. */
  claimDate?: Maybe<Scalars['Time']['output']>;
  /** The status of the claim. */
  claimStatus?: Maybe<PastPolicyLossClaimStatus>;
  /** The date when the loss occurred. */
  lossDate?: Maybe<Scalars['Time']['output']>;
  /** A description of the loss. */
  lossDescription: Scalars['String']['output'];
  /** The 2-letter state code where the loss occurred. */
  lossState: Scalars['String']['output'];
  /** The type of loss that occurred. */
  lossType?: Maybe<PastPolicyLossType>;
  /** The date when the policy became effective. */
  policyEffectiveDate?: Maybe<Scalars['Time']['output']>;
  /** The date when the policy expired. */
  policyExpirationDate?: Maybe<Scalars['Time']['output']>;
  /** The type of insurance policy. */
  policyType?: Maybe<InsuranceType>;
  /** The total amount paid for the claim. */
  totalPaidAmount: Scalars['Float']['output'];
  /** The total amount reserved for the claim. */
  totalReservedAmount: Scalars['Float']['output'];
};

export enum PastPolicyLossClaimStatus {
  /** The claim has been closed. */
  Closed = 'CLOSED',
  /** The claim is currently open. */
  Open = 'OPEN',
  Unknown = 'UNKNOWN'
}

export type PastPolicyLossInput = {
  /** The date when the claim was made. */
  claimDate: Scalars['String']['input'];
  /** The status of the claim. */
  claimStatus?: InputMaybe<PastPolicyLossClaimStatus>;
  /** The date when the loss occurred. */
  lossDate: Scalars['String']['input'];
  /** A description of the loss. */
  lossDescription: Scalars['String']['input'];
  /** The 2-letter state code where the loss occurred. */
  lossState: Scalars['String']['input'];
  /** The type of loss that occurred. */
  lossType?: InputMaybe<PastPolicyLossType>;
  /** The date when the policy became effective. */
  policyEffectiveDate: Scalars['String']['input'];
  /** The date when the policy expired. */
  policyExpirationDate: Scalars['String']['input'];
  /** The type of insurance policy. */
  policyType?: InputMaybe<InsuranceType>;
  /** The total amount paid for the claim. */
  totalPaidAmount: Scalars['Float']['input'];
  /** The total amount reserved for the claim. */
  totalReservedAmount: Scalars['Float']['input'];
};

export enum PastPolicyLossType {
  BodilyInjuryOther = 'BODILY_INJURY_OTHER',
  BusinessInterruption = 'BUSINESS_INTERRUPTION',
  ComputerFraud = 'COMPUTER_FRAUD',
  ContingentBusinessInterruption = 'CONTINGENT_BUSINESS_INTERRUPTION',
  CyberIncident = 'CYBER_INCIDENT',
  DigitalData = 'DIGITAL_DATA',
  EmployeePractices = 'EMPLOYEE_PRACTICES',
  ErrorsAndOmissions = 'ERRORS_AND_OMISSIONS',
  Fire = 'FIRE',
  FoodSpoilage = 'FOOD_SPOILAGE',
  FundTransferFraud = 'FUND_TRANSFER_FRAUD',
  GeneralLiabilityProducts = 'GENERAL_LIABILITY_PRODUCTS',
  GlPropertyDamage = 'GL_PROPERTY_DAMAGE',
  Hail = 'HAIL',
  Indemnity = 'INDEMNITY',
  InlandMarine = 'INLAND_MARINE',
  LiabilityMedicalPayments = 'LIABILITY_MEDICAL_PAYMENTS',
  MediaLiability = 'MEDIA_LIABILITY',
  Medical = 'MEDICAL',
  MedicalAndIndemnity = 'MEDICAL_AND_INDEMNITY',
  NetworkExtortion = 'NETWORK_EXTORTION',
  Other = 'OTHER',
  PaymentCard = 'PAYMENT_CARD',
  PersonalInjury = 'PERSONAL_INJURY',
  PrivacyAndNetworkSecurity = 'PRIVACY_AND_NETWORK_SECURITY',
  ProfessionalLiability = 'PROFESSIONAL_LIABILITY',
  PropertyDamageCollapse = 'PROPERTY_DAMAGE_COLLAPSE',
  PropertyDamageOther = 'PROPERTY_DAMAGE_OTHER',
  RegulatoryProceeding = 'REGULATORY_PROCEEDING',
  SlipFallInside = 'SLIP_FALL_INSIDE',
  SlipFallOutside = 'SLIP_FALL_OUTSIDE',
  SocialEngineeringFraud = 'SOCIAL_ENGINEERING_FRAUD',
  TechnologyErrorsAndOmissions = 'TECHNOLOGY_ERRORS_AND_OMISSIONS',
  Theft = 'THEFT',
  Unknown = 'UNKNOWN',
  Vandalism = 'VANDALISM',
  WaterNonWeatherRelated = 'WATER_NON_WEATHER_RELATED',
  Windstorm = 'WINDSTORM'
}

/** PaymentIntervalType enumerates the interval of a fee occurrence */
export enum PaymentIntervalType {
  /** Describes an annual payment */
  Annual = 'ANNUAL',
  /** Describes a monthly payment */
  Monthly = 'MONTHLY',
  /** Describes a non-recurring payment */
  None = 'NONE',
  /** Describes a quarterly payment */
  Quarterly = 'QUARTERLY'
}

/** How the customer chose to pay */
export enum PaymentOptionType {
  AnnualPayInFull = 'ANNUAL_PAY_IN_FULL',
  MonthlyFinanced = 'MONTHLY_FINANCED'
}

export enum PaymentPlanInstallmentFrequency {
  Monthly = 'MONTHLY',
  None = 'NONE',
  Quarterly = 'QUARTERLY'
}

export enum PaymentPlanPaymentMethodType {
  CreditCard = 'CREDIT_CARD',
  DirectDebit = 'DIRECT_DEBIT',
  ElectronicFundTransfer = 'ELECTRONIC_FUND_TRANSFER'
}

export type PersonalizationInput = {
  businessInsurance?: InputMaybe<BusinessInsurancePersonalizationInput>;
};

/** The enumeration of states for business policy */
export enum PolicyState {
  Binding = 'BINDING',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Inforce = 'INFORCE',
  Pending = 'PENDING',
  Submitted = 'SUBMITTED',
  Unknown = 'UNKNOWN'
}

/** The type of a single insured item on a policy */
export enum ProductType {
  BeautyAndSupplements = 'BEAUTY_AND_SUPPLEMENTS',
  /** A bike or eBike */
  Bike = 'BIKE',
  /** A collectible */
  Collectibles = 'COLLECTIBLES',
  /** An electronics item, such as a phone, laptop, or tablet */
  Electronics = 'ELECTRONICS',
  /** Fashion pieces */
  Fashion = 'FASHION',
  FoodAndBeverage = 'FOOD_AND_BEVERAGE',
  /** Furniture items */
  Furniture = 'FURNITURE',
  /** A jewelry item */
  Jewelry = 'JEWELRY',
  /** A motorcycle, moped, scooter, or other higher-powered motor vehicle */
  Motorcycle = 'MOTORCYCLE',
  /** An ATV, UTV, or other offroad vehicle */
  OffroadVehicle = 'OFFROAD_VEHICLE',
  /** Other producs */
  Other = 'OTHER',
  OutdoorSports = 'OUTDOOR_SPORTS',
  TobaccoCbdSmokeShop = 'TOBACCO_CBD_SMOKE_SHOP',
  /** An unknown product type. This value should be impossible */
  Unknown = 'UNKNOWN'
}

export type Query = {
  __typename?: 'Query';
  /** Retrieve a business application by ID */
  businessApplication: BusinessApplication;
  /** Retrieve all business applications */
  businessApplications: Array<BusinessApplication>;
  businessPolicies: Array<BusinessPolicy>;
  businessPolicy?: Maybe<BusinessPolicy>;
  carriers: Array<InsuranceCarrier>;
  certificate?: Maybe<File>;
  /**
   * Returns the available limits for a particular application and state.
   *
   * Arguments:
   *   applicationId: The unique identifier of the business application.
   *   state: The state for which to retrieve the available limits.
   *
   * Returns:
   *   A list of AvailableCoverageLimit objects representing the available coverage limits.
   */
  coverageLimits: Array<AvailableCoverageLimit>;
  merchant?: Maybe<Merchant>;
  merchants: Array<Merchant>;
};

export type QueryBusinessApplicationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBusinessPolicyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCertificateArgs = {
  certificateNumber: Scalars['String']['input'];
};

export type QueryCoverageLimitsArgs = {
  applicationId: Scalars['ID']['input'];
  state: Scalars['String']['input'];
};

export type QueryMerchantArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ReinstateBusinessPolicyInput = {
  effectiveAt: Scalars['Time']['input'];
  reasonDescription: Scalars['String']['input'];
};

export type RenewBusinessPolicyInput = {
  /** Additional fees collected by Oyster as revenue. */
  agencyFees: Scalars['Float']['input'];
  /** Fees collected by the wholesale broker */
  brokerFees: Scalars['Float']['input'];
  /** The portion of the wholesale broker fees that get kicked back to Oyster, as revenue */
  brokerFeesOysterPortion: Scalars['Float']['input'];
  /** The estimated commission Oyster earns on this policy */
  estimatedCommission: Scalars['Float']['input'];
  expiresAt?: InputMaybe<Scalars['Time']['input']>;
  inforceAt?: InputMaybe<Scalars['Time']['input']>;
  issuedAt?: InputMaybe<Scalars['Time']['input']>;
  /** Other fees incurred on this policy, not collected by Oyster as revenue */
  otherFees: Scalars['Float']['input'];
  /** Fees collected by the carrier to administer the policy or program */
  policyFees: Scalars['Float']['input'];
  policyNumber: Scalars['String']['input'];
  /** The actual premium of the policy */
  premium: Scalars['Float']['input'];
  quoteNumber: Scalars['String']['input'];
  state: PolicyState;
  /** Taxes levied on the policy premium and any taxable fees */
  taxes: Scalars['Float']['input'];
  /** Total amount to charge for the policy, including premium and all taxes and fees */
  total: Scalars['Float']['input'];
};

export enum Role {
  Admin = 'ADMIN',
  Bpo = 'BPO',
  User = 'USER'
}

/** The Stripe invoice information used to bill a policy */
export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  id: Scalars['String']['output'];
};

/** Represents an answer to an underwriting question. */
export type UnderwritingAnswer = {
  __typename?: 'UnderwritingAnswer';
  /** The answer to the question. */
  answer: Scalars['String']['output'];
  /** The ID of the location associated with the answer. */
  locationId?: Maybe<Scalars['ID']['output']>;
  /** The ID of the question associated with the answer. */
  questionId: Scalars['ID']['output'];
};

export type UnderwritingAnswerInput = {
  answer: Scalars['String']['input'];
  locationId?: InputMaybe<Scalars['String']['input']>;
  questionId: Scalars['String']['input'];
};

/** Represents an answer option for an underwriting question. */
export type UnderwritingAnswerOption = {
  __typename?: 'UnderwritingAnswerOption';
  /** The display text of the answer option. */
  displayText: Scalars['String']['output'];
  /** The value of the answer option. */
  value: Scalars['String']['output'];
};

/** Represents an underwriting question. */
export type UnderwritingQuestion = {
  __typename?: 'UnderwritingQuestion';
  /** The possible answer options for the question. */
  answerOptions?: Maybe<Array<UnderwritingAnswerOption>>;
  /** Carriers that require an answer to this question" */
  carriers: Array<BusinessApplicationCarrier>;
  /** The default answer to the question, if any. */
  defaultAnswer?: Maybe<Scalars['String']['output']>;
  /** Question IDs that are dependent on this question. */
  dependentQuestions: Array<UnderwritingQuestionDependency>;
  /** Indicates whether the question has a parent question. */
  hasParentQuestion: Scalars['Boolean']['output'];
  /** The unique identifier of the underwriting question. */
  id: Scalars['ID']['output'];
  /** The ID of the location associated with the question. */
  locationId?: Maybe<Scalars['ID']['output']>;
  /** The text of the underwriting question. */
  question: Scalars['String']['output'];
  /** The tooltip providing additional information about the question. */
  tooltip: Scalars['String']['output'];
  /** The type of the underwriting question. */
  type: UnderwritingQuestionType;
};

/** Represents a dependency between underwriting questions. */
export type UnderwritingQuestionDependency = {
  __typename?: 'UnderwritingQuestionDependency';
  /** The condition that must be met for the dependent questions to be shown. */
  condition?: Maybe<Scalars['String']['output']>;
  /** The IDs of the questions that are dependent on the parent question. */
  dependentQuestionIds: Array<Scalars['ID']['output']>;
  /** The type of the dependency. */
  type: UnderwritingQuestionDependencyType;
};

export enum UnderwritingQuestionDependencyType {
  /** Indicates that a question should be shown if the condition matches */
  Conditional = 'CONDITIONAL',
  /** Indicates that a question should always be shown */
  Direct = 'DIRECT'
}

/** Represents the type of an underwriting question. */
export enum UnderwritingQuestionType {
  /** A date question. */
  Date = 'DATE',
  /** An email question. */
  Email = 'EMAIL',
  /** A multi-select dropdown question. */
  Multiselectdropdown = 'MULTISELECTDROPDOWN',
  /** A number question. */
  Number = 'NUMBER',
  /** A phone number question. */
  Phonenumber = 'PHONENUMBER',
  /** A radio button question. */
  Radio = 'RADIO',
  /** A single-select dropdown question. */
  Singleselectdropdown = 'SINGLESELECTDROPDOWN',
  /** A text question. */
  Text = 'TEXT',
  /** A year question. */
  Year = 'YEAR'
}

/** Represents an underwriting statement. */
export type UnderwritingStatement = {
  __typename?: 'UnderwritingStatement';
  /** The carrier that the underwriting statement is associated with. */
  carrier: BusinessApplicationCarrier;
  /** Formatted markdown string with the underwriting statement. */
  markdown: Scalars['String']['output'];
};

/** Represents the type of an underwriting statement. */
export enum UnderwritingStatementType {
  /** A payment statement. */
  Payment = 'PAYMENT',
  /** An underwriting statement. */
  Underwriting = 'UNDERWRITING'
}

export type UpdateApplicationInput = {
  /** The annual revenue of the business. */
  annualRevenue?: InputMaybe<Scalars['Float']['input']>;
  /** The "Doing Business As" name of the business. */
  businessDba?: InputMaybe<Scalars['String']['input']>;
  /** The legal name of the business. */
  businessName?: InputMaybe<Scalars['String']['input']>;
  /** The information for the the contact person on the business application. */
  contact?: InputMaybe<BusinessContactInput>;
  /** Information about the employees at the business location. */
  employeeInfo?: InputMaybe<BusinessEmployeeInfoInput>;
  /** The Federal Employer Identification Number (FEIN) of the business. */
  fein?: InputMaybe<Scalars['String']['input']>;
  /** The desired effective date for the insurance policy. */
  insuranceEffectiveAt?: InputMaybe<Scalars['Time']['input']>;
  /** The insurance types the business is interested in. */
  insuranceTypes?: InputMaybe<Array<InsuranceType>>;
  /** The legal entity type of the business. */
  legalEntityType?: InputMaybe<BusinessLegalEntityType>;
  /** The mailing address of the business. */
  mailingAddress?: InputMaybe<AddressInput>;
  /** The NAICS classification code for the business. */
  naicsCode?: InputMaybe<Scalars['String']['input']>;
  /** The owners of the business. */
  owners?: InputMaybe<Array<BusinessOwnerInput>>;
  /** The past policy losses of the business. */
  pastPolicyLosses?: InputMaybe<Array<PastPolicyLossInput>>;
  /** The carriers that this business wants quotes from */
  selectedCarriers?: InputMaybe<Array<BusinessApplicationCarrier>>;
  /** The selected insurance limits for the business. */
  selectedLimits?: InputMaybe<InsuranceLimitsInput>;
  /** The underwriting answers for the business. */
  underwritingAnswers?: InputMaybe<Array<UnderwritingAnswerInput>>;
  /** The year the business was founded. */
  yearOfFounding?: InputMaybe<Scalars['String']['input']>;
  /** The years of management experience of the business. */
  yearsOfManagementExperience?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateBusinessPolicyInput = {
  /** Additional fees collected by Oyster as revenue. */
  agencyFees?: InputMaybe<Scalars['Float']['input']>;
  /** Fees collected by the wholesale broker */
  brokerFees?: InputMaybe<Scalars['Float']['input']>;
  /** The portion of the wholesale broker fees that get kicked back to Oyster, as revenue */
  brokerFeesOysterPortion?: InputMaybe<Scalars['Float']['input']>;
  brokerId?: InputMaybe<Scalars['String']['input']>;
  carrierId?: InputMaybe<Scalars['String']['input']>;
  estimatedCommission?: InputMaybe<Scalars['Float']['input']>;
  expiresAt?: InputMaybe<Scalars['Time']['input']>;
  inforceAt?: InputMaybe<Scalars['Time']['input']>;
  issuedAt?: InputMaybe<Scalars['Time']['input']>;
  market?: InputMaybe<InsuranceMarket>;
  minimumEarnedPremium?: InputMaybe<Scalars['Float']['input']>;
  /** Other fees incurred on this policy, not collected by Oyster as revenue */
  otherFees?: InputMaybe<Scalars['Float']['input']>;
  paymentIntervalType?: InputMaybe<PaymentIntervalType>;
  paymentOccurrencesPerInterval?: InputMaybe<Scalars['Int']['input']>;
  /** Fees collected by the carrier to administer the policy or program */
  policyFees?: InputMaybe<Scalars['Float']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  /** The actual premium of the policy */
  premium?: InputMaybe<Scalars['Float']['input']>;
  quoteNumber?: InputMaybe<Scalars['String']['input']>;
  sourceAgentId?: InputMaybe<Scalars['String']['input']>;
  sourceLeadId?: InputMaybe<Scalars['String']['input']>;
  sourceNotes?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<BusinessPolicySourceType>;
  state?: InputMaybe<PolicyState>;
  /** Taxes levied on the policy premium and any taxable fees */
  taxes?: InputMaybe<Scalars['Float']['input']>;
  /** Total amount to charge for the policy, including premium and all taxes and fees */
  total?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<InsuranceType>;
  updateReason: Scalars['String']['input'];
};

export enum UpdateInitiatorType {
  Cronjob = 'CRONJOB',
  Oyster = 'OYSTER',
  User = 'USER',
  Workflow = 'WORKFLOW'
}

export enum ValidationCode {
  Required = 'REQUIRED',
  ValidationFailed = 'VALIDATION_FAILED'
}

/** Describe a validation error for a field */
export type ValidationError = {
  __typename?: 'ValidationError';
  code: ValidationCode;
  field: Array<Scalars['String']['output']>;
  message: Scalars['String']['output'];
};

export type GetCoverageLimitsQueryVariables = Exact<{
  applicationId: Scalars['ID']['input'];
  state: Scalars['String']['input'];
}>;

export type GetCoverageLimitsQuery = {
  __typename?: 'Query';
  coverageLimits: Array<{
    __typename?: 'AvailableCoverageLimit';
    insuranceType: InsuranceType;
    limits: {
      __typename?: 'InsuranceLimits';
      bopAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      coverageState: string;
      cyberAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
    };
  }>;
};

export type ApplicationFieldsFragment = {
  __typename?: 'BusinessApplication';
  annualRevenue: number;
  businessDba: string;
  businessName: string;
  fein: string;
  id: string;
  insuranceEffectiveAt?: Date | null | undefined;
  insuranceTypes: Array<InsuranceType>;
  legalEntityType?: BusinessLegalEntityType | null | undefined;
  naicsCode: string;
  selectedCarriers: Array<BusinessApplicationCarrier>;
  state: BusinessApplicationState;
  yearOfFounding: string;
  yearsOfManagementExperience: number;
  contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
  employeeInfo: {
    __typename?: 'BusinessEmployeeInfo';
    numFullTimeEmployees: number;
    numPartTimeEmployees: number;
    totalEmployeePayroll: number;
  };
  locations: Array<{
    __typename?: 'BusinessLocation';
    id: string;
    isPrimary: boolean;
    address: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    buildingInfo: {
      __typename?: 'BusinessLocationBuildingInfo';
      annualSales: number;
      areaOccupiedByBusiness: number;
      buildingCoverage: number;
      burglarAlarmType?: BurglarAlarmType | null | undefined;
      constructionType?: BuildingConstructionType | null | undefined;
      ownershipType?: BuildingOwnershipType | null | undefined;
      personalPropertyCoverage: number;
      sprinkleredPercentage: number;
      totalArea: number;
      totalStories: number;
      yearBuilt: string;
    };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numPartTimeEmployees: number;
      numFullTimeEmployees: number;
      totalEmployeePayroll: number;
      jobCode: string;
    };
  }>;
  mailingAddress: {
    __typename?: 'Address';
    line1: string;
    line2?: string | null | undefined;
    city: string;
    zone: string;
    postalCode: string;
  };
  owners: Array<{
    __typename?: 'BusinessOwner';
    fullName: string;
    annualPayroll: number;
    dateOfBirth?: Date | null | undefined;
    jobCode?: string | null | undefined;
  }>;
  pastPolicyLosses: Array<{
    __typename?: 'PastPolicyLoss';
    claimDate?: Date | null | undefined;
    claimStatus?: PastPolicyLossClaimStatus | null | undefined;
    lossDate?: Date | null | undefined;
    lossDescription: string;
    lossState: string;
    lossType?: PastPolicyLossType | null | undefined;
    policyEffectiveDate?: Date | null | undefined;
    policyExpirationDate?: Date | null | undefined;
    policyType?: InsuranceType | null | undefined;
    totalPaidAmount: number;
    totalReservedAmount: number;
  }>;
  selectedLimits: {
    __typename?: 'InsuranceLimits';
    coverageState: string;
    wcPerAccidentLimit?: number | null | undefined;
    wcPerDiseaseEmployeeLimit?: number | null | undefined;
    wcPerDiseasePolicyLimit?: number | null | undefined;
    glPerOccurrenceLimit?: number | null | undefined;
    glAggregateLimit?: number | null | undefined;
    bopPerOccurrenceLimit?: number | null | undefined;
    bopAggregateLimit?: number | null | undefined;
    cyberRetentionLimit?: number | null | undefined;
    cyberAggregateLimit?: number | null | undefined;
  };
  underwritingAnswers: Array<{
    __typename?: 'UnderwritingAnswer';
    answer: string;
    locationId?: string | null | undefined;
    questionId: string;
  }>;
  underwritingQuestions: Array<{
    __typename?: 'UnderwritingQuestion';
    id: string;
    carriers: Array<BusinessApplicationCarrier>;
    defaultAnswer?: string | null | undefined;
    hasParentQuestion: boolean;
    locationId?: string | null | undefined;
    question: string;
    tooltip: string;
    type: UnderwritingQuestionType;
    answerOptions?:
      | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
      | null
      | undefined;
    dependentQuestions: Array<{
      __typename?: 'UnderwritingQuestionDependency';
      condition?: string | null | undefined;
      dependentQuestionIds: Array<string>;
      type: UnderwritingQuestionDependencyType;
    }>;
  }>;
  underwritingStatements: Array<{
    __typename?: 'UnderwritingStatement';
    carrier: BusinessApplicationCarrier;
    markdown: string;
  }>;
};

export type ApplicationQuoteFieldsFragment = {
  __typename?: 'BusinessApplication';
  quotes: Array<{
    __typename?: 'BusinessApplicationQuote';
    carrier: BusinessApplicationCarrier;
    id: string;
    policyType: InsuranceType;
    premiumAmount: number;
    state: BusinessApplicationQuoteState;
    totalAmount: number;
    type: BusinessApplicationQuoteType;
    paymentPlans: Array<{
      __typename?: 'BusinessApplicationQuotePaymentPlan';
      description: string;
      downAmount: number;
      downDueAt?: Date | null | undefined;
      installmentAmount: number;
      installmentCount: number;
      installmentDueAt?: Date | null | undefined;
      installmentFee: number;
      installmentFrequency: PaymentPlanInstallmentFrequency;
      paymentMethod: PaymentPlanPaymentMethodType;
      paymentPlanId: string;
      title: string;
      totalAmount: number;
      totalFees: number;
    }>;
  }>;
};

export type ApplicationQuotableCarrierFieldsFragment = {
  __typename?: 'BusinessApplication';
  quotableCarriers: Array<{
    __typename?: 'InsuranceCarrier';
    ascendId?: string | null | undefined;
    id: string;
    isBroker: boolean;
    isCarrier: boolean;
    name: string;
    supportedPolicies?: Array<InsuranceType> | null | undefined;
  }>;
};

export type GetBusinessApplicationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  includeQuotes?: InputMaybe<Scalars['Boolean']['input']>;
  includeQuotableCarriers?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetBusinessApplicationQuery = {
  __typename?: 'Query';
  businessApplication: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
    quotes: Array<{
      __typename?: 'BusinessApplicationQuote';
      carrier: BusinessApplicationCarrier;
      id: string;
      policyType: InsuranceType;
      premiumAmount: number;
      state: BusinessApplicationQuoteState;
      totalAmount: number;
      type: BusinessApplicationQuoteType;
      paymentPlans: Array<{
        __typename?: 'BusinessApplicationQuotePaymentPlan';
        description: string;
        downAmount: number;
        downDueAt?: Date | null | undefined;
        installmentAmount: number;
        installmentCount: number;
        installmentDueAt?: Date | null | undefined;
        installmentFee: number;
        installmentFrequency: PaymentPlanInstallmentFrequency;
        paymentMethod: PaymentPlanPaymentMethodType;
        paymentPlanId: string;
        title: string;
        totalAmount: number;
        totalFees: number;
      }>;
    }>;
    quotableCarriers: Array<{
      __typename?: 'InsuranceCarrier';
      ascendId?: string | null | undefined;
      id: string;
      isBroker: boolean;
      isCarrier: boolean;
      name: string;
      supportedPolicies?: Array<InsuranceType> | null | undefined;
    }>;
  };
};

export type CreateBusinessApplicationMutationVariables = Exact<{
  application: CreateApplicationInput;
}>;

export type CreateBusinessApplicationMutation = {
  __typename?: 'Mutation';
  createBusinessApplication: { __typename?: 'BusinessApplication'; id: string };
};

export type UpdateBusinessApplicationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  application: UpdateApplicationInput;
  includeQuotes?: InputMaybe<Scalars['Boolean']['input']>;
  includeQuotableCarriers?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateBusinessApplicationMutation = {
  __typename?: 'Mutation';
  updateBusinessApplication: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
    quotes: Array<{
      __typename?: 'BusinessApplicationQuote';
      carrier: BusinessApplicationCarrier;
      id: string;
      policyType: InsuranceType;
      premiumAmount: number;
      state: BusinessApplicationQuoteState;
      totalAmount: number;
      type: BusinessApplicationQuoteType;
      paymentPlans: Array<{
        __typename?: 'BusinessApplicationQuotePaymentPlan';
        description: string;
        downAmount: number;
        downDueAt?: Date | null | undefined;
        installmentAmount: number;
        installmentCount: number;
        installmentDueAt?: Date | null | undefined;
        installmentFee: number;
        installmentFrequency: PaymentPlanInstallmentFrequency;
        paymentMethod: PaymentPlanPaymentMethodType;
        paymentPlanId: string;
        title: string;
        totalAmount: number;
        totalFees: number;
      }>;
    }>;
    quotableCarriers: Array<{
      __typename?: 'InsuranceCarrier';
      ascendId?: string | null | undefined;
      id: string;
      isBroker: boolean;
      isCarrier: boolean;
      name: string;
      supportedPolicies?: Array<InsuranceType> | null | undefined;
    }>;
  };
};

export type QuoteBusinessApplicationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type QuoteBusinessApplicationMutation = {
  __typename?: 'Mutation';
  quoteBusinessApplication: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
    quotes: Array<{
      __typename?: 'BusinessApplicationQuote';
      carrier: BusinessApplicationCarrier;
      id: string;
      policyType: InsuranceType;
      premiumAmount: number;
      state: BusinessApplicationQuoteState;
      totalAmount: number;
      type: BusinessApplicationQuoteType;
      paymentPlans: Array<{
        __typename?: 'BusinessApplicationQuotePaymentPlan';
        description: string;
        downAmount: number;
        downDueAt?: Date | null | undefined;
        installmentAmount: number;
        installmentCount: number;
        installmentDueAt?: Date | null | undefined;
        installmentFee: number;
        installmentFrequency: PaymentPlanInstallmentFrequency;
        paymentMethod: PaymentPlanPaymentMethodType;
        paymentPlanId: string;
        title: string;
        totalAmount: number;
        totalFees: number;
      }>;
    }>;
    quotableCarriers: Array<{
      __typename?: 'InsuranceCarrier';
      ascendId?: string | null | undefined;
      id: string;
      isBroker: boolean;
      isCarrier: boolean;
      name: string;
      supportedPolicies?: Array<InsuranceType> | null | undefined;
    }>;
  };
};

export type SubmitBusinessApplicationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SubmitBusinessApplicationMutation = {
  __typename?: 'Mutation';
  submitBusinessApplication: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
    quotes: Array<{
      __typename?: 'BusinessApplicationQuote';
      carrier: BusinessApplicationCarrier;
      id: string;
      policyType: InsuranceType;
      premiumAmount: number;
      state: BusinessApplicationQuoteState;
      totalAmount: number;
      type: BusinessApplicationQuoteType;
      paymentPlans: Array<{
        __typename?: 'BusinessApplicationQuotePaymentPlan';
        description: string;
        downAmount: number;
        downDueAt?: Date | null | undefined;
        installmentAmount: number;
        installmentCount: number;
        installmentDueAt?: Date | null | undefined;
        installmentFee: number;
        installmentFrequency: PaymentPlanInstallmentFrequency;
        paymentMethod: PaymentPlanPaymentMethodType;
        paymentPlanId: string;
        title: string;
        totalAmount: number;
        totalFees: number;
      }>;
    }>;
    quotableCarriers: Array<{
      __typename?: 'InsuranceCarrier';
      ascendId?: string | null | undefined;
      id: string;
      isBroker: boolean;
      isCarrier: boolean;
      name: string;
      supportedPolicies?: Array<InsuranceType> | null | undefined;
    }>;
  };
};

export type AddBusinessApplicationLocationMutationVariables = Exact<{
  applicationId: Scalars['ID']['input'];
  location: BusinessLocationInput;
}>;

export type AddBusinessApplicationLocationMutation = {
  __typename?: 'Mutation';
  businessApplication: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
  };
};

export type UpdateBusinessApplicationLocationMutationVariables = Exact<{
  applicationId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
  location: BusinessLocationInput;
}>;

export type UpdateBusinessApplicationLocationMutation = {
  __typename?: 'Mutation';
  businessApplication: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
  };
};

export type RemoveBusinessApplicationLocationMutationVariables = Exact<{
  applicationId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
}>;

export type RemoveBusinessApplicationLocationMutation = {
  __typename?: 'Mutation';
  businessApplication: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
  };
};

export type BindQuoteMutationVariables = Exact<{
  applicationId: Scalars['ID']['input'];
  quoteId: Scalars['ID']['input'];
  paymentPlanId?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentPlanPaymentMethodType>;
}>;

export type BindQuoteMutation = {
  __typename?: 'Mutation';
  bindQuote: {
    __typename?: 'BusinessApplication';
    annualRevenue: number;
    businessDba: string;
    businessName: string;
    fein: string;
    id: string;
    insuranceEffectiveAt?: Date | null | undefined;
    insuranceTypes: Array<InsuranceType>;
    legalEntityType?: BusinessLegalEntityType | null | undefined;
    naicsCode: string;
    selectedCarriers: Array<BusinessApplicationCarrier>;
    state: BusinessApplicationState;
    yearOfFounding: string;
    yearsOfManagementExperience: number;
    contact: { __typename?: 'BusinessContact'; fullName: string; email: string; phone: string };
    employeeInfo: {
      __typename?: 'BusinessEmployeeInfo';
      numFullTimeEmployees: number;
      numPartTimeEmployees: number;
      totalEmployeePayroll: number;
    };
    locations: Array<{
      __typename?: 'BusinessLocation';
      id: string;
      isPrimary: boolean;
      address: {
        __typename?: 'Address';
        line1: string;
        line2?: string | null | undefined;
        city: string;
        zone: string;
        postalCode: string;
      };
      buildingInfo: {
        __typename?: 'BusinessLocationBuildingInfo';
        annualSales: number;
        areaOccupiedByBusiness: number;
        buildingCoverage: number;
        burglarAlarmType?: BurglarAlarmType | null | undefined;
        constructionType?: BuildingConstructionType | null | undefined;
        ownershipType?: BuildingOwnershipType | null | undefined;
        personalPropertyCoverage: number;
        sprinkleredPercentage: number;
        totalArea: number;
        totalStories: number;
        yearBuilt: string;
      };
      employeeInfo: {
        __typename?: 'BusinessEmployeeInfo';
        numPartTimeEmployees: number;
        numFullTimeEmployees: number;
        totalEmployeePayroll: number;
        jobCode: string;
      };
    }>;
    mailingAddress: {
      __typename?: 'Address';
      line1: string;
      line2?: string | null | undefined;
      city: string;
      zone: string;
      postalCode: string;
    };
    owners: Array<{
      __typename?: 'BusinessOwner';
      fullName: string;
      annualPayroll: number;
      dateOfBirth?: Date | null | undefined;
      jobCode?: string | null | undefined;
    }>;
    pastPolicyLosses: Array<{
      __typename?: 'PastPolicyLoss';
      claimDate?: Date | null | undefined;
      claimStatus?: PastPolicyLossClaimStatus | null | undefined;
      lossDate?: Date | null | undefined;
      lossDescription: string;
      lossState: string;
      lossType?: PastPolicyLossType | null | undefined;
      policyEffectiveDate?: Date | null | undefined;
      policyExpirationDate?: Date | null | undefined;
      policyType?: InsuranceType | null | undefined;
      totalPaidAmount: number;
      totalReservedAmount: number;
    }>;
    selectedLimits: {
      __typename?: 'InsuranceLimits';
      coverageState: string;
      wcPerAccidentLimit?: number | null | undefined;
      wcPerDiseaseEmployeeLimit?: number | null | undefined;
      wcPerDiseasePolicyLimit?: number | null | undefined;
      glPerOccurrenceLimit?: number | null | undefined;
      glAggregateLimit?: number | null | undefined;
      bopPerOccurrenceLimit?: number | null | undefined;
      bopAggregateLimit?: number | null | undefined;
      cyberRetentionLimit?: number | null | undefined;
      cyberAggregateLimit?: number | null | undefined;
    };
    underwritingAnswers: Array<{
      __typename?: 'UnderwritingAnswer';
      answer: string;
      locationId?: string | null | undefined;
      questionId: string;
    }>;
    underwritingQuestions: Array<{
      __typename?: 'UnderwritingQuestion';
      id: string;
      carriers: Array<BusinessApplicationCarrier>;
      defaultAnswer?: string | null | undefined;
      hasParentQuestion: boolean;
      locationId?: string | null | undefined;
      question: string;
      tooltip: string;
      type: UnderwritingQuestionType;
      answerOptions?:
        | Array<{ __typename?: 'UnderwritingAnswerOption'; displayText: string; value: string }>
        | null
        | undefined;
      dependentQuestions: Array<{
        __typename?: 'UnderwritingQuestionDependency';
        condition?: string | null | undefined;
        dependentQuestionIds: Array<string>;
        type: UnderwritingQuestionDependencyType;
      }>;
    }>;
    underwritingStatements: Array<{
      __typename?: 'UnderwritingStatement';
      carrier: BusinessApplicationCarrier;
      markdown: string;
    }>;
    quotes: Array<{
      __typename?: 'BusinessApplicationQuote';
      carrier: BusinessApplicationCarrier;
      id: string;
      policyType: InsuranceType;
      premiumAmount: number;
      state: BusinessApplicationQuoteState;
      totalAmount: number;
      type: BusinessApplicationQuoteType;
      paymentPlans: Array<{
        __typename?: 'BusinessApplicationQuotePaymentPlan';
        description: string;
        downAmount: number;
        downDueAt?: Date | null | undefined;
        installmentAmount: number;
        installmentCount: number;
        installmentDueAt?: Date | null | undefined;
        installmentFee: number;
        installmentFrequency: PaymentPlanInstallmentFrequency;
        paymentMethod: PaymentPlanPaymentMethodType;
        paymentPlanId: string;
        title: string;
        totalAmount: number;
        totalFees: number;
      }>;
    }>;
    quotableCarriers: Array<{
      __typename?: 'InsuranceCarrier';
      ascendId?: string | null | undefined;
      id: string;
      isBroker: boolean;
      isCarrier: boolean;
      name: string;
      supportedPolicies?: Array<InsuranceType> | null | undefined;
    }>;
  };
};

export const ApplicationFieldsFragmentDoc = gql`
  fragment ApplicationFields on BusinessApplication {
    annualRevenue
    businessDba
    businessName
    contact {
      fullName
      email
      phone
    }
    employeeInfo {
      numFullTimeEmployees
      numPartTimeEmployees
      totalEmployeePayroll
    }
    fein
    id
    insuranceEffectiveAt
    insuranceTypes
    legalEntityType
    locations {
      id
      address {
        line1
        line2
        city
        zone
        postalCode
      }
      buildingInfo {
        annualSales
        areaOccupiedByBusiness
        buildingCoverage
        burglarAlarmType
        constructionType
        ownershipType
        personalPropertyCoverage
        sprinkleredPercentage
        totalArea
        totalStories
        yearBuilt
      }
      employeeInfo {
        numPartTimeEmployees
        numFullTimeEmployees
        totalEmployeePayroll
        jobCode
      }
      isPrimary
    }
    mailingAddress {
      line1
      line2
      city
      zone
      postalCode
    }
    naicsCode
    owners {
      fullName
      annualPayroll
      dateOfBirth
      jobCode
    }
    pastPolicyLosses {
      claimDate
      claimStatus
      lossDate
      lossDescription
      lossState
      lossType
      policyEffectiveDate
      policyExpirationDate
      policyType
      totalPaidAmount
      totalReservedAmount
    }
    selectedCarriers
    selectedLimits {
      coverageState
      wcPerAccidentLimit
      wcPerDiseaseEmployeeLimit
      wcPerDiseasePolicyLimit
      glPerOccurrenceLimit
      glAggregateLimit
      bopPerOccurrenceLimit
      bopAggregateLimit
      cyberRetentionLimit
      cyberAggregateLimit
    }
    state
    underwritingAnswers {
      answer
      locationId
      questionId
    }
    underwritingQuestions {
      id
      answerOptions {
        displayText
        value
      }
      carriers
      defaultAnswer
      dependentQuestions {
        condition
        dependentQuestionIds
        type
      }
      hasParentQuestion
      locationId
      question
      tooltip
      type
    }
    underwritingStatements {
      carrier
      markdown
    }
    yearOfFounding
    yearsOfManagementExperience
  }
`;
export const ApplicationQuoteFieldsFragmentDoc = gql`
  fragment ApplicationQuoteFields on BusinessApplication {
    quotes {
      carrier
      id
      paymentPlans {
        description
        downAmount
        downDueAt
        installmentAmount
        installmentCount
        installmentDueAt
        installmentFee
        installmentFrequency
        paymentMethod
        paymentPlanId
        title
        totalAmount
        totalFees
      }
      policyType
      premiumAmount
      state
      totalAmount
      type
    }
  }
`;
export const ApplicationQuotableCarrierFieldsFragmentDoc = gql`
  fragment ApplicationQuotableCarrierFields on BusinessApplication {
    quotableCarriers {
      ascendId
      id
      isBroker
      isCarrier
      name
      supportedPolicies
    }
  }
`;
export const GetCoverageLimitsDocument = gql`
  query GetCoverageLimits($applicationId: ID!, $state: String!) {
    coverageLimits(applicationId: $applicationId, state: $state) {
      insuranceType
      limits {
        bopAggregateLimit
        bopPerOccurrenceLimit
        coverageState
        cyberAggregateLimit
        cyberRetentionLimit
        glAggregateLimit
        glPerOccurrenceLimit
        wcPerAccidentLimit
        wcPerDiseaseEmployeeLimit
        wcPerDiseasePolicyLimit
      }
    }
  }
`;

/**
 * __useGetCoverageLimitsQuery__
 *
 * To run a query within a React component, call `useGetCoverageLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoverageLimitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoverageLimitsQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGetCoverageLimitsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCoverageLimitsQuery, GetCoverageLimitsQueryVariables> &
    ({ variables: GetCoverageLimitsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoverageLimitsQuery, GetCoverageLimitsQueryVariables>(
    GetCoverageLimitsDocument,
    options
  );
}
export function useGetCoverageLimitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCoverageLimitsQuery, GetCoverageLimitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoverageLimitsQuery, GetCoverageLimitsQueryVariables>(
    GetCoverageLimitsDocument,
    options
  );
}
export function useGetCoverageLimitsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCoverageLimitsQuery,
    GetCoverageLimitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCoverageLimitsQuery, GetCoverageLimitsQueryVariables>(
    GetCoverageLimitsDocument,
    options
  );
}
export type GetCoverageLimitsQueryHookResult = ReturnType<typeof useGetCoverageLimitsQuery>;
export type GetCoverageLimitsLazyQueryHookResult = ReturnType<typeof useGetCoverageLimitsLazyQuery>;
export type GetCoverageLimitsSuspenseQueryHookResult = ReturnType<
  typeof useGetCoverageLimitsSuspenseQuery
>;
export type GetCoverageLimitsQueryResult = Apollo.QueryResult<
  GetCoverageLimitsQuery,
  GetCoverageLimitsQueryVariables
>;
export const GetBusinessApplicationDocument = gql`
  query GetBusinessApplication(
    $id: ID!
    $includeQuotes: Boolean = false
    $includeQuotableCarriers: Boolean = false
  ) {
    businessApplication(id: $id) {
      ...ApplicationFields
      ...ApplicationQuoteFields @include(if: $includeQuotes)
      ...ApplicationQuotableCarrierFields @include(if: $includeQuotableCarriers)
    }
  }
  ${ApplicationFieldsFragmentDoc}
  ${ApplicationQuoteFieldsFragmentDoc}
  ${ApplicationQuotableCarrierFieldsFragmentDoc}
`;

/**
 * __useGetBusinessApplicationQuery__
 *
 * To run a query within a React component, call `useGetBusinessApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeQuotes: // value for 'includeQuotes'
 *      includeQuotableCarriers: // value for 'includeQuotableCarriers'
 *   },
 * });
 */
export function useGetBusinessApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBusinessApplicationQuery,
    GetBusinessApplicationQueryVariables
  > &
    ({ variables: GetBusinessApplicationQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBusinessApplicationQuery, GetBusinessApplicationQueryVariables>(
    GetBusinessApplicationDocument,
    options
  );
}
export function useGetBusinessApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBusinessApplicationQuery,
    GetBusinessApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBusinessApplicationQuery, GetBusinessApplicationQueryVariables>(
    GetBusinessApplicationDocument,
    options
  );
}
export function useGetBusinessApplicationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetBusinessApplicationQuery,
    GetBusinessApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetBusinessApplicationQuery, GetBusinessApplicationQueryVariables>(
    GetBusinessApplicationDocument,
    options
  );
}
export type GetBusinessApplicationQueryHookResult = ReturnType<
  typeof useGetBusinessApplicationQuery
>;
export type GetBusinessApplicationLazyQueryHookResult = ReturnType<
  typeof useGetBusinessApplicationLazyQuery
>;
export type GetBusinessApplicationSuspenseQueryHookResult = ReturnType<
  typeof useGetBusinessApplicationSuspenseQuery
>;
export type GetBusinessApplicationQueryResult = Apollo.QueryResult<
  GetBusinessApplicationQuery,
  GetBusinessApplicationQueryVariables
>;
export const CreateBusinessApplicationDocument = gql`
  mutation CreateBusinessApplication($application: CreateApplicationInput!) {
    createBusinessApplication(application: $application) {
      id
    }
  }
`;
export type CreateBusinessApplicationMutationFn = Apollo.MutationFunction<
  CreateBusinessApplicationMutation,
  CreateBusinessApplicationMutationVariables
>;

/**
 * __useCreateBusinessApplicationMutation__
 *
 * To run a mutation, you first call `useCreateBusinessApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessApplicationMutation, { data, loading, error }] = useCreateBusinessApplicationMutation({
 *   variables: {
 *      application: // value for 'application'
 *   },
 * });
 */
export function useCreateBusinessApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBusinessApplicationMutation,
    CreateBusinessApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBusinessApplicationMutation,
    CreateBusinessApplicationMutationVariables
  >(CreateBusinessApplicationDocument, options);
}
export type CreateBusinessApplicationMutationHookResult = ReturnType<
  typeof useCreateBusinessApplicationMutation
>;
export type CreateBusinessApplicationMutationResult =
  Apollo.MutationResult<CreateBusinessApplicationMutation>;
export type CreateBusinessApplicationMutationOptions = Apollo.BaseMutationOptions<
  CreateBusinessApplicationMutation,
  CreateBusinessApplicationMutationVariables
>;
export const UpdateBusinessApplicationDocument = gql`
  mutation UpdateBusinessApplication(
    $id: ID!
    $application: UpdateApplicationInput!
    $includeQuotes: Boolean = false
    $includeQuotableCarriers: Boolean = false
  ) {
    updateBusinessApplication(id: $id, application: $application) {
      ...ApplicationFields
      ...ApplicationQuoteFields @include(if: $includeQuotes)
      ...ApplicationQuotableCarrierFields @include(if: $includeQuotableCarriers)
    }
  }
  ${ApplicationFieldsFragmentDoc}
  ${ApplicationQuoteFieldsFragmentDoc}
  ${ApplicationQuotableCarrierFieldsFragmentDoc}
`;
export type UpdateBusinessApplicationMutationFn = Apollo.MutationFunction<
  UpdateBusinessApplicationMutation,
  UpdateBusinessApplicationMutationVariables
>;

/**
 * __useUpdateBusinessApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessApplicationMutation, { data, loading, error }] = useUpdateBusinessApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      application: // value for 'application'
 *      includeQuotes: // value for 'includeQuotes'
 *      includeQuotableCarriers: // value for 'includeQuotableCarriers'
 *   },
 * });
 */
export function useUpdateBusinessApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBusinessApplicationMutation,
    UpdateBusinessApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBusinessApplicationMutation,
    UpdateBusinessApplicationMutationVariables
  >(UpdateBusinessApplicationDocument, options);
}
export type UpdateBusinessApplicationMutationHookResult = ReturnType<
  typeof useUpdateBusinessApplicationMutation
>;
export type UpdateBusinessApplicationMutationResult =
  Apollo.MutationResult<UpdateBusinessApplicationMutation>;
export type UpdateBusinessApplicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateBusinessApplicationMutation,
  UpdateBusinessApplicationMutationVariables
>;
export const QuoteBusinessApplicationDocument = gql`
  mutation QuoteBusinessApplication($id: ID!) {
    quoteBusinessApplication(id: $id) {
      ...ApplicationFields
      ...ApplicationQuoteFields
      ...ApplicationQuotableCarrierFields @include(if: false)
    }
  }
  ${ApplicationFieldsFragmentDoc}
  ${ApplicationQuoteFieldsFragmentDoc}
  ${ApplicationQuotableCarrierFieldsFragmentDoc}
`;
export type QuoteBusinessApplicationMutationFn = Apollo.MutationFunction<
  QuoteBusinessApplicationMutation,
  QuoteBusinessApplicationMutationVariables
>;

/**
 * __useQuoteBusinessApplicationMutation__
 *
 * To run a mutation, you first call `useQuoteBusinessApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuoteBusinessApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quoteBusinessApplicationMutation, { data, loading, error }] = useQuoteBusinessApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuoteBusinessApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QuoteBusinessApplicationMutation,
    QuoteBusinessApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QuoteBusinessApplicationMutation,
    QuoteBusinessApplicationMutationVariables
  >(QuoteBusinessApplicationDocument, options);
}
export type QuoteBusinessApplicationMutationHookResult = ReturnType<
  typeof useQuoteBusinessApplicationMutation
>;
export type QuoteBusinessApplicationMutationResult =
  Apollo.MutationResult<QuoteBusinessApplicationMutation>;
export type QuoteBusinessApplicationMutationOptions = Apollo.BaseMutationOptions<
  QuoteBusinessApplicationMutation,
  QuoteBusinessApplicationMutationVariables
>;
export const SubmitBusinessApplicationDocument = gql`
  mutation SubmitBusinessApplication($id: ID!) {
    submitBusinessApplication(id: $id) {
      ...ApplicationFields
      ...ApplicationQuoteFields
      ...ApplicationQuotableCarrierFields @include(if: false)
    }
  }
  ${ApplicationFieldsFragmentDoc}
  ${ApplicationQuoteFieldsFragmentDoc}
  ${ApplicationQuotableCarrierFieldsFragmentDoc}
`;
export type SubmitBusinessApplicationMutationFn = Apollo.MutationFunction<
  SubmitBusinessApplicationMutation,
  SubmitBusinessApplicationMutationVariables
>;

/**
 * __useSubmitBusinessApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitBusinessApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBusinessApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBusinessApplicationMutation, { data, loading, error }] = useSubmitBusinessApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmitBusinessApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitBusinessApplicationMutation,
    SubmitBusinessApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitBusinessApplicationMutation,
    SubmitBusinessApplicationMutationVariables
  >(SubmitBusinessApplicationDocument, options);
}
export type SubmitBusinessApplicationMutationHookResult = ReturnType<
  typeof useSubmitBusinessApplicationMutation
>;
export type SubmitBusinessApplicationMutationResult =
  Apollo.MutationResult<SubmitBusinessApplicationMutation>;
export type SubmitBusinessApplicationMutationOptions = Apollo.BaseMutationOptions<
  SubmitBusinessApplicationMutation,
  SubmitBusinessApplicationMutationVariables
>;
export const AddBusinessApplicationLocationDocument = gql`
  mutation AddBusinessApplicationLocation($applicationId: ID!, $location: BusinessLocationInput!) {
    businessApplication: addBusinessLocation(applicationId: $applicationId, location: $location) {
      ...ApplicationFields
    }
  }
  ${ApplicationFieldsFragmentDoc}
`;
export type AddBusinessApplicationLocationMutationFn = Apollo.MutationFunction<
  AddBusinessApplicationLocationMutation,
  AddBusinessApplicationLocationMutationVariables
>;

/**
 * __useAddBusinessApplicationLocationMutation__
 *
 * To run a mutation, you first call `useAddBusinessApplicationLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBusinessApplicationLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBusinessApplicationLocationMutation, { data, loading, error }] = useAddBusinessApplicationLocationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useAddBusinessApplicationLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBusinessApplicationLocationMutation,
    AddBusinessApplicationLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddBusinessApplicationLocationMutation,
    AddBusinessApplicationLocationMutationVariables
  >(AddBusinessApplicationLocationDocument, options);
}
export type AddBusinessApplicationLocationMutationHookResult = ReturnType<
  typeof useAddBusinessApplicationLocationMutation
>;
export type AddBusinessApplicationLocationMutationResult =
  Apollo.MutationResult<AddBusinessApplicationLocationMutation>;
export type AddBusinessApplicationLocationMutationOptions = Apollo.BaseMutationOptions<
  AddBusinessApplicationLocationMutation,
  AddBusinessApplicationLocationMutationVariables
>;
export const UpdateBusinessApplicationLocationDocument = gql`
  mutation UpdateBusinessApplicationLocation(
    $applicationId: ID!
    $locationId: ID!
    $location: BusinessLocationInput!
  ) {
    businessApplication: updateBusinessLocation(
      applicationId: $applicationId
      locationId: $locationId
      location: $location
    ) {
      ...ApplicationFields
    }
  }
  ${ApplicationFieldsFragmentDoc}
`;
export type UpdateBusinessApplicationLocationMutationFn = Apollo.MutationFunction<
  UpdateBusinessApplicationLocationMutation,
  UpdateBusinessApplicationLocationMutationVariables
>;

/**
 * __useUpdateBusinessApplicationLocationMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessApplicationLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessApplicationLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessApplicationLocationMutation, { data, loading, error }] = useUpdateBusinessApplicationLocationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      locationId: // value for 'locationId'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useUpdateBusinessApplicationLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBusinessApplicationLocationMutation,
    UpdateBusinessApplicationLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBusinessApplicationLocationMutation,
    UpdateBusinessApplicationLocationMutationVariables
  >(UpdateBusinessApplicationLocationDocument, options);
}
export type UpdateBusinessApplicationLocationMutationHookResult = ReturnType<
  typeof useUpdateBusinessApplicationLocationMutation
>;
export type UpdateBusinessApplicationLocationMutationResult =
  Apollo.MutationResult<UpdateBusinessApplicationLocationMutation>;
export type UpdateBusinessApplicationLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdateBusinessApplicationLocationMutation,
  UpdateBusinessApplicationLocationMutationVariables
>;
export const RemoveBusinessApplicationLocationDocument = gql`
  mutation RemoveBusinessApplicationLocation($applicationId: ID!, $locationId: ID!) {
    businessApplication: removeBusinessLocation(
      applicationId: $applicationId
      locationId: $locationId
    ) {
      ...ApplicationFields
    }
  }
  ${ApplicationFieldsFragmentDoc}
`;
export type RemoveBusinessApplicationLocationMutationFn = Apollo.MutationFunction<
  RemoveBusinessApplicationLocationMutation,
  RemoveBusinessApplicationLocationMutationVariables
>;

/**
 * __useRemoveBusinessApplicationLocationMutation__
 *
 * To run a mutation, you first call `useRemoveBusinessApplicationLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBusinessApplicationLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBusinessApplicationLocationMutation, { data, loading, error }] = useRemoveBusinessApplicationLocationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useRemoveBusinessApplicationLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveBusinessApplicationLocationMutation,
    RemoveBusinessApplicationLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveBusinessApplicationLocationMutation,
    RemoveBusinessApplicationLocationMutationVariables
  >(RemoveBusinessApplicationLocationDocument, options);
}
export type RemoveBusinessApplicationLocationMutationHookResult = ReturnType<
  typeof useRemoveBusinessApplicationLocationMutation
>;
export type RemoveBusinessApplicationLocationMutationResult =
  Apollo.MutationResult<RemoveBusinessApplicationLocationMutation>;
export type RemoveBusinessApplicationLocationMutationOptions = Apollo.BaseMutationOptions<
  RemoveBusinessApplicationLocationMutation,
  RemoveBusinessApplicationLocationMutationVariables
>;
export const BindQuoteDocument = gql`
  mutation BindQuote(
    $applicationId: ID!
    $quoteId: ID!
    $paymentPlanId: String
    $paymentMethod: PaymentPlanPaymentMethodType
  ) {
    bindQuote(
      applicationId: $applicationId
      quoteId: $quoteId
      paymentPlanId: $paymentPlanId
      paymentMethod: $paymentMethod
    ) {
      ...ApplicationFields
      ...ApplicationQuoteFields
      ...ApplicationQuotableCarrierFields @include(if: false)
    }
  }
  ${ApplicationFieldsFragmentDoc}
  ${ApplicationQuoteFieldsFragmentDoc}
  ${ApplicationQuotableCarrierFieldsFragmentDoc}
`;
export type BindQuoteMutationFn = Apollo.MutationFunction<
  BindQuoteMutation,
  BindQuoteMutationVariables
>;

/**
 * __useBindQuoteMutation__
 *
 * To run a mutation, you first call `useBindQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBindQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bindQuoteMutation, { data, loading, error }] = useBindQuoteMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      quoteId: // value for 'quoteId'
 *      paymentPlanId: // value for 'paymentPlanId'
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function useBindQuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<BindQuoteMutation, BindQuoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BindQuoteMutation, BindQuoteMutationVariables>(
    BindQuoteDocument,
    options
  );
}
export type BindQuoteMutationHookResult = ReturnType<typeof useBindQuoteMutation>;
export type BindQuoteMutationResult = Apollo.MutationResult<BindQuoteMutation>;
export type BindQuoteMutationOptions = Apollo.BaseMutationOptions<
  BindQuoteMutation,
  BindQuoteMutationVariables
>;
