import React, { useCallback, useEffect } from 'react';
import { IoLockClosedOutline, IoMailOutline } from 'react-icons/io5';
import { usePlaidLink } from 'react-plaid-link';
import styled from 'styled-components';

import { setMerchantPayment, connectToPlaid } from '@oysterjs/core/api/plaid';
import { CardGallery, SelectableOptionCard } from '@oysterjs/ui/Card';
import { FormContainer, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { Address, MercuryPaymentTypes, PaymentMethodType, ValidationError } from '@oysterjs/types';
import { Button } from '@oysterjs/ui/Button';
import { AddressInputForm } from '@oysterjs/ui/Form/address';
import { ErrorType, WrappedError } from '@oysterjs/core/errors';
import { ErrorDisplay } from '@oysterjs/ui/Form/text';

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  padding-bottom: 20px;
`;

const SummerFieldContainer = styled.div`
  flex-direction: column;
`;

const SummaryHeaderDescription = styled.div`
  font-size: 0.9em;
`;

const SummaryHeaderTitle = styled.div`
  font-weight: 500;
`;

interface SummaryHeaderProps {
  title?: string;
  description?: string;
  address?: Partial<Address>;
}

const getMercuryPaymentMethod = (paymentMethod: PaymentMethodType) => {
  switch (paymentMethod) {
    case PaymentMethodType.Check:
      return MercuryPaymentTypes.check;
    case PaymentMethodType.Electronic:
      return MercuryPaymentTypes.electronic;
    default:
      return MercuryPaymentTypes.unknown;
  }
};

export const SummaryField: React.FunctionComponent<React.PropsWithChildren<SummaryHeaderProps>> = (
  props
) => (
  <SummerFieldContainer>
    {props.title && <SummaryHeaderTitle>{props.title}</SummaryHeaderTitle>}
    {props.description && (
      <SummaryHeaderDescription style={{ paddingTop: '5px' }}>
        {props.description}
      </SummaryHeaderDescription>
    )}
    {props.address && (
      <div style={{ paddingTop: '5px' }}>
        <SummaryHeaderDescription>{props.address.AddressLine1}</SummaryHeaderDescription>
        <SummaryHeaderDescription>{props.address.AddressLine2}</SummaryHeaderDescription>
        <SummaryHeaderDescription>
          {props.address.City + ', ' + props.address.Zone + ' ' + props.address.PostalCode}
        </SummaryHeaderDescription>
      </div>
    )}
  </SummerFieldContainer>
);

export const PayoutSummary = (props: {
  paymentMethod: PaymentMethodType;
  address: Partial<Address>;
  onUpdatePayoutsInfo: (paymentMethod: PaymentMethodType, formAddress?: Partial<Address>) => void;
}) => {
  return (
    <SummaryContainer>
      <SummaryField title="Billing Address" address={props.address} />
      <SummaryField title="Payout Method" description={props.paymentMethod} />
    </SummaryContainer>
  );
};

export const UserPayment = (props: {
  loading: boolean;
  paymentMethod?: PaymentMethodType;
  address?: Partial<Address>;
  onUpdatePayoutsInfo: (paymentMethod: PaymentMethodType, formAddress?: Partial<Address>) => void;
  onSave?: () => void;
  error?: string;
  onUpdateLoading: (loading: boolean) => void;
}) => {
  const [data, setData] = React.useState('');
  const [paymentMethod, setPaymentMethod] = React.useState(
    props.paymentMethod || PaymentMethodType.Unknown
  );
  const [token, setToken] = React.useState('');
  const [formAddress, setFormAddress] = React.useState({
    Address: props.address
  });
  const [validationError, setValidationError] = React.useState<ValidationError>();

  const setAddress = (field: string, value: string | unknown[] | object) => {
    if (validationError?.Field === field) {
      setValidationError(undefined);
    }

    setFormAddress((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const onChangeAddress = <T,>(vals: [string, T][]) =>
    setAddress('Address', {
      ...Object.fromEntries(vals)
    });

  const fetchToken = useCallback(async () => {
    const res = await connectToPlaid();
    setData(res.LinkToken);
  }, []);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);

  const onSuccess = useCallback(async (token) => {
    setPaymentMethod(PaymentMethodType.Electronic);
    if (validationError?.Field == 'PayoutMethod') {
      setValidationError(undefined);
    }
    setToken(token);
  }, []);

  const config = {
    token: data,
    onSuccess
  };

  const { open, ready, error } = usePlaidLink(config);

  const onClickCheck = () => {
    if (validationError?.Field == 'PayoutMethod') {
      setValidationError(undefined);
    }
    setPaymentMethod(PaymentMethodType.Check);
  };

  const onClick = () => {
    props.onUpdateLoading(true);
    setMerchantPayment({
      PublicToken: token,
      PaymentType: getMercuryPaymentMethod(paymentMethod),
      Address: formAddress.Address
    })
      .then(() => {
        props.onUpdatePayoutsInfo(paymentMethod, formAddress.Address);
        props.onSave?.();
      })
      .catch((e) => {
        const err = WrappedError.asWrappedError(e);
        if (err.type() == ErrorType.validationError) {
          setValidationError(err.getValidationError);
        }
      });
  };

  return (
    <>
      <FormContainer style={{ maxWidth: '600px', width: ' 100%', paddingTop: '0px' }}>
        <FormRowHeader title="Business Address" paddingTop="0px" />
        <AddressInputForm
          disabled={props.loading}
          showSecondLine={true}
          singleSecondLine={true}
          onChange={(address) => {
            onChangeAddress([
              ['AddressLine1', address.streetAddress],
              ['AddressLine2', address.streetAddressLine2],
              ['City', address.city],
              ['Zone', address.state],
              ['PostalCode', address.zipCode]
            ]);
          }}
          initialValue={{
            streetAddress: formAddress.Address?.AddressLine1,
            streetAddressLine2: formAddress.Address?.AddressLine2,
            city: formAddress.Address?.City,
            state: formAddress.Address?.Zone,
            zipCode: formAddress.Address?.PostalCode
          }}
          validationError={{
            streetAddress: validationError?.SubField === 'AddressLine1' && validationError?.Message,
            city: validationError?.SubField === 'City' && validationError?.Message,
            state: validationError?.SubField === 'Zone' && validationError?.Message,
            zipCode: validationError?.SubField === 'PostalCode' && validationError?.Message
          }}
        />
      </FormContainer>
      <FormContainer style={{ gap: '0px', paddingBottom: '5px' }}>
        <FormRowHeader title="Select Payout Method" />
        <CardGallery style={{ padding: '0', gap: '20px' }}>
          <SelectableOptionCard
            disabled={props.loading}
            title="Receive Check"
            description="We will send you a check to your business address."
            selected={paymentMethod == PaymentMethodType.Check}
            onClick={() => onClickCheck()}
            image={<IoMailOutline />}
          />
          <SelectableOptionCard
            title="Connect Bank Account"
            description="We will send an ACH payment to your bank account."
            selected={paymentMethod == PaymentMethodType.Electronic}
            onClick={() => open()}
            image={<IoLockClosedOutline />}
            disabled={!ready || props.loading}
          />
        </CardGallery>
        {(validationError?.Field == 'PayoutMethod' || error || props.error) && (
          <ErrorDisplay>
            {validationError?.Message || error?.toString?.() || props.error}
          </ErrorDisplay>
        )}
      </FormContainer>
      <Button primary onClick={onClick} loading={props.loading} disabled={props.loading}>
        Save
      </Button>
    </>
  );
};
