import { Radio, RadioGroup } from '@headlessui/react';
import { Description, ErrorMessage, Field, Label } from '../../components/fieldset';
import { Input } from '../../components/input';
import { Select } from '../../components/select';
import { Textarea } from '../../components/textarea';
import { Checkbox, CheckboxGroup } from '../../components/checkbox';
import { CheckboxField as __CheckboxField } from '../../components/checkbox';

type BaseFieldProps = {
  label: string;
  description?: string;
  value?: string;
  error?: string;
  onChange: (value: string) => void;
};

export const DateField = (props: BaseFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <Input
        type="date"
        className="xs:w-36"
        value={props.value}
        invalid={!!props.error}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      />
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

export const EmailField = (props: BaseFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <Input
        type="email"
        className="sm:w-1/2"
        value={props.value}
        invalid={!!props.error}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      />
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

export const PhoneNumberField = (props: BaseFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <Input
        type="tel"
        className="sm:w-1/2"
        value={props.value}
        invalid={!!props.error}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      />
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

export const NumberField = (props: BaseFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <Input
        type="number"
        className="sm:w-24"
        value={props.value}
        invalid={!!props.error}
        onKeyDown={(e) =>
          e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null
        }
        onWheel={(e) => e.currentTarget.blur()}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      />
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

export const YearField = (props: BaseFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <Input
        type="number"
        className="sm:w-24"
        value={props.value}
        invalid={!!props.error}
        onKeyDown={(e) =>
          e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null
        }
        onWheel={(e) => e.currentTarget.blur()}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      />
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

export const TextField = (props: BaseFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <Textarea
        resizable={false}
        rows={3}
        value={props.value}
        invalid={!!props.error}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      />
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

type SelectFieldProps = {
  options: { displayText: string; value: string }[];
};

export const SelectField = (props: BaseFieldProps & SelectFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <Select
        className="sm:w-1/2"
        value={props.value || ''}
        invalid={!!props.error}
        onChange={(e) => props.onChange(e.currentTarget.value)}
      >
        <option value=""></option>
        {props.options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.displayText}
          </option>
        ))}
      </Select>
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

type RadioFieldProps = {
  options: { displayText: string; value: string }[];
};

export const RadioField = (props: BaseFieldProps & RadioFieldProps) => {
  return (
    <Field>
      <Label>{props.label}</Label>
      {props.description && <Description>{props.description}</Description>}
      <RadioGroup data-slot="control" value={props.value} onChange={props.onChange}>
        {props.options.map((option) => (
          <Radio
            key={option.value}
            value={option.value}
            className="inline-block cursor-pointer focus:outline-none items-center justify-center rounded-md bg-white dark:bg-neutral-800 px-8 py-4 sm:px-6 sm:py-3 mr-2 sm:text-sm font-medium text-neutral-900 dark:text-white ring-1 ring-neutral-300 dark:ring-neutral-700 hover:bg-neutral-50 dark:hover:bg-neutral-700 data-[checked]:bg-primary-500 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-primary-500 data-[focus]:ring-offset-2 data-[checked]:hover:bg-primary-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
          >
            {option.displayText}
          </Radio>
        ))}
      </RadioGroup>
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

type MultiSelectFieldProps = {
  values: string[];
  options: { displayText: string; value: string }[];
  onChangeMulti: (values: string[]) => void;
};

export const MultiSelectField = (props: BaseFieldProps & MultiSelectFieldProps) => {
  return (
    <Field>
      {props.label && <Label>{props.label}</Label>}
      {props.description && <Description>{props.description}</Description>}
      <CheckboxGroup className="grid sm:grid-cols-3 xs:grid-cols-2">
        {props.options.map((option) => (
          <__CheckboxField key={option.value}>
            <Checkbox
              color="sky"
              value={option.value}
              checked={props.values.includes(option.value)}
              onChange={(checked) =>
                props.onChangeMulti(
                  checked
                    ? [...props.values, option.value]
                    : props.values.filter((v) => v !== option.value)
                )
              }
            />
            <Label>{option.displayText}</Label>
          </__CheckboxField>
        ))}
      </CheckboxGroup>
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </Field>
  );
};

export const CheckboxField = MultiSelectField;
