import clsx from 'clsx';

export function LegendSkeleton({ className }: { className?: string }) {
  return (
    <div
      data-slot="legend"
      className={clsx(
        className,
        'h-8 py-1 w-1/2 sm:w-48 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
      )}
    />
  );
}

export function LabelSkeleton({
  className,
  long,
  extraLong
}: {
  className?: string;
  long?: boolean;
  extraLong?: boolean;
}) {
  return (
    <div
      data-slot="label"
      className={clsx(
        className,
        extraLong ? 'w-3/4' : long ? 'w-1/2' : 'w-1/2 sm:w-32',
        'select-none text-base/6 text-neutral-950 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-white',
        'h-3 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
      )}
    />
  );
}

export function DescriptionSkeleton({ className, long }: { className?: string; long?: boolean }) {
  return (
    <div
      data-slot="description"
      className={clsx(
        className,
        long ? 'w-3/4' : 'w-3/4 sm:w-48',
        'h-3 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
      )}
    />
  );
}

export function TextSkeleton({ className }: { className?: string }) {
  return (
    <>
      <div
        data-slot="text"
        className={clsx(
          className,
          'h-3 py-1 mt-4 w-full sm:w-3/4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
        )}
      />
      <div
        data-slot="text"
        className={clsx(
          className,
          'h-3 py-1 mt-1 w-3/4 sm:w-1/2 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
        )}
      />
    </>
  );
}

export function InputSkeleton({ className, short }: { className?: string; short?: boolean }) {
  return (
    <div
      data-slot="control"
      className={clsx(
        className,
        short ? 'w-full xs:w-48' : 'w-full',
        'relative block w-full px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing[3])-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]',
        'h-12 sm:h-10 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
      )}
    />
  );
}
