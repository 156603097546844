import styled from 'styled-components';
import { Redirect, Route as BrowserRoute, Switch, useRouteMatch } from 'react-router';
import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);
import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import { SideBar } from '../navbar/sidebar';
import { TopBar } from '../navbar/topbar';
import { Loadable } from '@oysterjs/ui/Loadable';
import {
  getMerchantAccount,
  getMerchantGraphQLClient,
  getMerchantIntegrations,
  getMerchantReferrals,
  getMerchantRentalConfiguration,
  getMerchantUsers,
  getRentalWaivers
} from '@oysterjs/core/api/merchant';
import { HomePage } from './home';
import { ProductInsurancePage } from './insurance/product';
import { PayoutsPage } from './insurance/product/payouts';
import { TeamPage } from './team';
import { RiskPage } from './risk';
import { MerchantAppsPage } from './apps';
import { MerchantRentalPage } from './apps/rental';
import { InvitePage } from './invite';
import { InsurancePage } from './insurance/business';
import { MerchantReportsPage } from './reports';
import { ApolloProvider } from '@apollo/client';
import {
  BusinessPolicy,
  Merchant as GraphQLMerchant,
  GetMerchantBusinessPoliciesDocument,
  useGetMerchantBusinessPoliciesLazyQuery,
  useGetMerchantLazyQuery,
  GetMerchantBusinessPoliciesQuery
} from '@oysterjs/types/merchant/graphql/types-and-hooks';
import { Spinner } from '@oysterjs/ui/Spinner';
import React from 'react';
import { Merchant, MerchantUser, ValidationError } from '@oysterjs/types';

const AsyncMerchantHomePage = () => (
  <Loadable
    request={Promise.all([
      getMerchantAccount(),
      getMerchantGraphQLClient()
        .query<GetMerchantBusinessPoliciesQuery>({ query: GetMerchantBusinessPoliciesDocument })
        .then((res) => res.data?.businessPolicies || []),
      getMerchantReferrals(
        new Date(new Date().getUTCFullYear(), new Date().getUTCMonth()),
        new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1)
      ),
      getMerchantIntegrations(),
      getMerchantRentalConfiguration(),
      getRentalWaivers()
    ])}
  >
    {([account, businessPolicies, referrals, integrations, rental, waivers]) => {
      return (
        <HomePage
          merchant={account.Merchant}
          businessPolicies={businessPolicies as BusinessPolicy[]}
          policyReferrals={referrals.Referrals}
          integrations={integrations.Integrations}
          rentalConfiguration={rental.Configuration}
          rentalWaivers={waivers}
        />
      );
    }}
  </Loadable>
);

const AsyncMerchantProductInsurancePage = () => (
  <Loadable request={Promise.all([getMerchantAccount(), getMerchantIntegrations()])}>
    {([accountRes, integrationsRes]) => {
      return (
        <ProductInsurancePage
          merchant={accountRes.Merchant}
          apiKey={integrationsRes.ApiKey}
          merchantIntegrations={integrationsRes.Integrations}
        />
      );
    }}
  </Loadable>
);

const AsyncMerchantInsurancePage = () => {
  const [accountRes, setAccountRes] = React.useState<{
    Merchant: Merchant;
    MerchantUser: MerchantUser;
    PersonalizationError?: ValidationError;
  }>();
  const [merchant, setMerchant] = React.useState<GraphQLMerchant>();
  const [policies, setPolicies] = React.useState<BusinessPolicy[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [businessPoliciesQuery] = useGetMerchantBusinessPoliciesLazyQuery();
  const [getMerchantQuery] = useGetMerchantLazyQuery();

  React.useEffect(() => {
    setLoading(true);
    Promise.all([getMerchantAccount()]).then(([accountRes]) => {
      setAccountRes(accountRes);
    });
  }, []);

  React.useEffect(() => {
    Promise.all([
      new Promise<void>((resolve) =>
        businessPoliciesQuery({
          onCompleted: (d) => {
            setPolicies(d.businessPolicies as BusinessPolicy[]);
            resolve();
          },
          onError: () => resolve()
        })
      ),
      new Promise<void>((resolve) =>
        getMerchantQuery({
          onCompleted: (d) => {
            setMerchant(d.merchant as GraphQLMerchant);
            resolve();
          },
          onError: () => resolve()
        })
      )
    ]).finally(() => setLoading(false));
  }, [accountRes?.Merchant.ID]);

  return (
    <>
      {loading && <Spinner color="#333333" />}
      {!loading && accountRes?.Merchant && accountRes?.MerchantUser && merchant && (
        <InsurancePage
          merchant={accountRes.Merchant}
          merchantUser={accountRes.MerchantUser}
          graphMerchant={merchant}
          policies={policies}
        />
      )}
    </>
  );
};

const AsyncRiskPage = () => (
  <Loadable request={getMerchantAccount()}>
    {() => {
      return <RiskPage />;
    }}
  </Loadable>
);

const AsyncPayoutsPage = () => (
  <Loadable request={getMerchantAccount()}>
    {(account) => {
      return <PayoutsPage merchant={account.Merchant} />;
    }}
  </Loadable>
);

const AsyncTeamPage = () => (
  <Loadable request={Promise.all([getMerchantAccount(), getMerchantUsers()])}>
    {([account, users]) => {
      return <TeamPage merchant={account.Merchant} merchantUsers={users.Users} />;
    }}
  </Loadable>
);

const AsyncInvitePage = () => (
  <Loadable request={getMerchantAccount()}>
    {(account) => {
      return <InvitePage merchant={account.Merchant} />;
    }}
  </Loadable>
);

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100%;
`;

const DashboardPageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
`;

const DashboardPage = (props) => (
  <DashboardPageContainer>
    <TopBar />
    <PageContainer width="100%">
      <div style={{ padding: '0px 20px' }}>
        <PageSection>{props.children}</PageSection>
      </div>
    </PageContainer>
  </DashboardPageContainer>
);

export default (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <DashboardContainer>
      <SideBar />
      <DashboardPage>
        <Switch>
          <Route path={`${path.replace(/\/+$/, '')}/product-insurance`}>
            <AsyncMerchantProductInsurancePage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/channels`}>
            <AsyncMerchantProductInsurancePage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/insurance`}>
            <ApolloProvider client={getMerchantGraphQLClient()}>
              <AsyncMerchantInsurancePage />
            </ApolloProvider>
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/business-insurance`}>
            <ApolloProvider client={getMerchantGraphQLClient()}>
              <AsyncMerchantInsurancePage />
            </ApolloProvider>
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/home`}>
            <AsyncMerchantHomePage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/payouts`}>
            <AsyncPayoutsPage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/risk`}>
            <AsyncRiskPage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/team`}>
            <AsyncTeamPage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/apps`}>
            <MerchantAppsPage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/rental`}>
            <MerchantRentalPage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/reports`}>
            <MerchantReportsPage />
          </Route>
          <Route path={`${path.replace(/\/+$/, '')}/invite`}>
            <AsyncInvitePage />
          </Route>
          <Redirect to="/home" />
        </Switch>
      </DashboardPage>
    </DashboardContainer>
  );
};
