import * as React from 'react';
import styled, { CSSProperties } from 'styled-components';

import {
  Merchant,
  MerchantIntegration,
  MerchantIntegrationStatus,
  MerchantIntegrationType,
  ValidationError
} from '@oysterjs/types';
import {
  IoQrCodeOutline,
  IoLinkOutline,
  IoChevronForwardOutline,
  IoBookmarkOutline,
  IoStorefrontOutline
} from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router';
import { ShopifySetup } from '../../setups/ShopifySetup';
import { ReferralLinkSetup } from '../../setups/referralLinkSetup';
import { QRCodeSetup } from '../../setups/qrCodeSetup';
import { SlideOut } from '@oysterjs/ui/Modal/slideout';
import { CardGallery, SelectableOptionCard } from '@oysterjs/ui/Card';
import { Badge } from '@oysterjs/ui/Badge';
import { personalizeMerchant, updateMerchantIntegration } from '@oysterjs/core/api/merchant';
import { PageSection, PageTitle } from '@oysterjs/ui/Page/section';
import { LightspeedRetailRSeriesSetup } from '../../setups/lightspeedRetailRSeries';
import { EmbeddablePageSetup } from '../../setups/embeddableMarketingPageSetup';
import { MerchantPersonalizationProductInsuranceForm } from '../../personalization/forms';
import { Button, ButtonContainer } from '@oysterjs/ui/Button';
import { WooCommerceSetup } from '../../setups/woocommerce';
import { CheckfrontSetup } from '../../setups/checkfrontSetup';

const ChannelImage = styled.img`
  height: 38.4px;
  width: 38.4px;
`;

const ChannelSlideoutHeader = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 0px;
`;

const availableChannels: MerchantIntegrationType[] = [
  MerchantIntegrationType.referral_link,
  MerchantIntegrationType.qr_code,
  MerchantIntegrationType.embeddable_marketing_page,
  MerchantIntegrationType.shopify,
  MerchantIntegrationType.lsretail_rseries,
  MerchantIntegrationType.woocommerce,
  MerchantIntegrationType.checkfront
];

export const getChannelLabel = (type: MerchantIntegrationType) => {
  switch (type) {
    case MerchantIntegrationType.shopify:
      return 'Shopify';
    case MerchantIntegrationType.lsretail_rseries:
      return 'Lightspeed';
    case MerchantIntegrationType.woocommerce:
      return 'WooCommerce';
    case MerchantIntegrationType.checkfront:
      return 'Checkfront';
    case MerchantIntegrationType.qr_code:
      return 'Marketing Kit';
    case MerchantIntegrationType.referral_link:
      return 'Referral Link';
    case MerchantIntegrationType.embeddable_marketing_page:
      return 'Embedded Oyster Page';
    case MerchantIntegrationType.custom:
      return 'Other';
    default:
      return '';
  }
};

export const getChannelDescription = (type: MerchantIntegrationType) => {
  switch (type) {
    case MerchantIntegrationType.shopify:
      return 'Configure the Oyster Shopify app to offer customers peace-of-mind.';
    case MerchantIntegrationType.lsretail_rseries:
      return 'Configure the Oyster Lightspeed app to offer customers peace-of-mind.';
    case MerchantIntegrationType.woocommerce:
      return 'Configure the Oyster WooCommerce plugin to offer customers peace-of-mind.';
    case MerchantIntegrationType.qr_code:
      return 'Print out, display, or mail physical insurance offers to your customers.';
    case MerchantIntegrationType.referral_link:
      return 'Offer customers insurance anywhere with your own landing page.';
    case MerchantIntegrationType.embeddable_marketing_page:
      return 'Display Oyster insurance information page on your website.';
    case MerchantIntegrationType.checkfront:
      return 'Configure the Oyster CheckFront app to offer customers peace-of-mind.';
    case MerchantIntegrationType.custom:
      return 'I use a different online sales platform.';
    default:
      return '';
  }
};

export const getChannelImage = (type: MerchantIntegrationType) => {
  switch (type) {
    case MerchantIntegrationType.shopify:
      return <ChannelImage src="/images/shopify.svg" />;
    case MerchantIntegrationType.lsretail_rseries:
      return <ChannelImage src="/images/lightspeed.svg" />;
    case MerchantIntegrationType.woocommerce:
      return <ChannelImage src="/images/woocommerce.svg" />;
    case MerchantIntegrationType.checkfront:
      return <ChannelImage src="/images/checkfront.svg" />;
    case MerchantIntegrationType.qr_code:
      return <IoQrCodeOutline style={{ color: '#000000' }} />;
    case MerchantIntegrationType.referral_link:
      return <IoLinkOutline style={{ color: '#000000' }} />;
    case MerchantIntegrationType.embeddable_marketing_page:
      return <IoBookmarkOutline style={{ color: '#000000' }} />;
    case MerchantIntegrationType.custom:
      return <IoStorefrontOutline style={{ color: '#000000' }} />;
    default:
      return <></>;
  }
};

const getChannelPath = (type: MerchantIntegrationType) => {
  switch (type) {
    case MerchantIntegrationType.shopify:
      return '/channels/shopify';
    case MerchantIntegrationType.lsretail_rseries:
      return '/channels/lsretail-rseries';
    case MerchantIntegrationType.woocommerce:
      return '/channels/woocommerce';
    case MerchantIntegrationType.qr_code:
      return '/channels/qrcode';
    case MerchantIntegrationType.referral_link:
      return '/channels/referral';
    case MerchantIntegrationType.embeddable_marketing_page:
      return '/channels/embeddable-page';
    case MerchantIntegrationType.checkfront:
      return '/channels/checkfront';
    default:
      return '/channels';
  }
};

export const getBadgeColor = (status: MerchantIntegrationStatus): string => {
  switch (status) {
    case MerchantIntegrationStatus.active:
      return '#baf7bb';
    case MerchantIntegrationStatus.pending:
      return '#ffa72494';
    case MerchantIntegrationStatus.disabled:
      return '#e91e63a6';
    case MerchantIntegrationStatus.inactive:
      return '#e6e6e6';
  }
};

const getChannelBadge = (status?: MerchantIntegrationStatus): JSX.Element => {
  return (
    <Badge
      color={getBadgeColor(status || MerchantIntegrationStatus.inactive)}
      label={status || 'Not added'}
    />
  );
};

export const ChannelCard = (props: {
  type: MerchantIntegrationType;
  style?: CSSProperties;
  status?: MerchantIntegrationStatus;
  onClick?: () => void;
}) => (
  <SelectableOptionCard
    title={getChannelLabel(props.type)}
    badge={getChannelBadge(props.status)}
    titleAction={<IoChevronForwardOutline />}
    description={getChannelDescription(props.type)}
    image={getChannelImage(props.type)}
    onClick={props.onClick}
    style={{ maxWidth: '360px' }}
  />
);

interface ChannelSlideOutContentProps {
  title: string;
  description: string;
  integrationStatus?: MerchantIntegrationStatus;
}

export const ChannelSlideoutContent = (
  props: React.PropsWithChildren<ChannelSlideOutContentProps>
) => (
  <div style={{ padding: '0px 20px' }}>
    <ChannelSlideoutHeader>
      <h1>{props.title}</h1>
      {getChannelBadge(props.integrationStatus)}
    </ChannelSlideoutHeader>
    <p style={{ margin: '0 0 1em 0', fontSize: '0.9em', color: '#666666' }}>{props.description}</p>
    {props.children}
    <p style={{ fontSize: '0.9em', color: '#333333' }}>
      <b style={{ fontWeight: 500 }}>Need help?</b>{' '}
      <a target="_blank" href="https://meetings.hubspot.com/vic-yeh/dashboard-meeting">
        Schedule a call
      </a>{' '}
      with us!
    </p>
  </div>
);

export const ChannelsListPage = (props: {
  apiKey: string;
  merchant: Merchant;
  merchantIntegrations: MerchantIntegration[];
  onUpdateIntegration: (integration: MerchantIntegration) => Promise<void>;
}) => {
  const history = useHistory();
  const location = useLocation();

  const onClick = (type: MerchantIntegrationType) => {
    history.push(getChannelPath(type));
  };

  const integrationByType = (type: MerchantIntegrationType): MerchantIntegration | undefined => {
    return props.merchantIntegrations?.find((i) => i.Type === type);
  };

  return (
    <>
      <CardGallery style={{ padding: '0', margin: '0' }}>
        {props.merchantIntegrations.map(
          (integration) =>
            availableChannels.includes(integration.Type) && (
              <ChannelCard
                key={integration.ID}
                onClick={() => onClick(integration.Type)}
                type={integration.Type}
                status={integration.Status}
              />
            )
        )}
      </CardGallery>
      <PageSection
        title="Add new channels"
        description="Make it even easier for customers to add insurance."
      >
        <CardGallery style={{ padding: '0', margin: '0' }}>
          {availableChannels
            .filter((integration) => {
              return props.merchantIntegrations.every(
                (merchantIntegration) => merchantIntegration.Type != integration
              );
            })
            .map((integration) => (
              <ChannelCard
                key={integration}
                onClick={() => onClick(integration)}
                type={integration}
              />
            ))}
        </CardGallery>
      </PageSection>
      <SlideOut
        showing={location.pathname.endsWith('/woocommerce')}
        onClose={() => history.replace('/channels')}
        onBack={() => history.replace('/channels')}
      >
        <ChannelSlideoutContent
          title="WooCommerce"
          description="Offer shoppers peace of mind via the WooCommerce integration."
          integrationStatus={
            props.merchantIntegrations?.find((i) => i.Type === MerchantIntegrationType.woocommerce)
              ?.Status
          }
        >
          <WooCommerceSetup
            apiKey={props.apiKey}
            integration={integrationByType(MerchantIntegrationType.woocommerce)}
            onUpdateIntegration={props.onUpdateIntegration}
          />
        </ChannelSlideoutContent>
      </SlideOut>
      <SlideOut
        showing={location.pathname.endsWith('/lsretail-rseries')}
        onClose={() => history.replace('/channels')}
        onBack={() => history.replace('/channels')}
      >
        <ChannelSlideoutContent
          title="Lightspeed R-Series"
          description="Offer shoppers peace of mind via the Lightspeed R-Series integration."
          integrationStatus={
            props.merchantIntegrations?.find(
              (i) => i.Type === MerchantIntegrationType.lsretail_rseries
            )?.Status
          }
        >
          <LightspeedRetailRSeriesSetup
            integration={integrationByType(MerchantIntegrationType.lsretail_rseries)}
            onUpdateIntegration={props.onUpdateIntegration}
          />
        </ChannelSlideoutContent>
      </SlideOut>
      <SlideOut
        showing={location.pathname.endsWith('/checkfront')}
        onClose={() => history.replace('/channels')}
        onBack={() => history.replace('/channels')}
      >
        <ChannelSlideoutContent
          title="Checkfront"
          description="Offer shoppers peace of mind via the Checkfront integration."
          integrationStatus={integrationByType(MerchantIntegrationType.checkfront)?.Status}
        >
          <CheckfrontSetup
            integration={integrationByType(MerchantIntegrationType.checkfront)}
            onUpdateIntegration={props.onUpdateIntegration}
            publicHandle={props.merchant.PublicHandle}
          />
        </ChannelSlideoutContent>
      </SlideOut>
      <SlideOut
        showing={location.pathname.endsWith('/shopify')}
        onClose={() => history.replace('/channels')}
        onBack={() => history.replace('/channels')}
      >
        <ChannelSlideoutContent
          title="Shopify App"
          description="Offer shoppers peace of mind via the Oyster Shopify app."
          integrationStatus={integrationByType(MerchantIntegrationType.shopify)?.Status}
        >
          <ShopifySetup
            apiKey={props.apiKey}
            integration={props.merchantIntegrations?.find(
              (i) => i.Type === MerchantIntegrationType.shopify
            )}
            onUpdateIntegration={props.onUpdateIntegration}
          ></ShopifySetup>
        </ChannelSlideoutContent>
      </SlideOut>
      <SlideOut
        showing={location.pathname.endsWith('/referral')}
        onClose={() => history.replace('/channels')}
        onBack={() => history.replace('/channels')}
      >
        <ChannelSlideoutContent
          title="Referral Link"
          description="Customize a landing page for your business that customers can use to get a quote."
          integrationStatus={
            props.merchantIntegrations?.find(
              (i) => i.Type === MerchantIntegrationType.referral_link
            )?.Status
          }
        >
          <ReferralLinkSetup
            integration={integrationByType(MerchantIntegrationType.referral_link)}
            onUpdateIntegration={props.onUpdateIntegration}
          ></ReferralLinkSetup>
        </ChannelSlideoutContent>
      </SlideOut>
      <SlideOut
        showing={location.pathname.endsWith('/qrcode')}
        onClose={() => history.replace('/channels')}
        onBack={() => history.replace('/channels')}
      >
        <ChannelSlideoutContent
          title="Marketing Kit"
          description="Customize and print a kit to help offer insurance to your customers."
          integrationStatus={integrationByType(MerchantIntegrationType.lsretail_rseries)?.Status}
        >
          <QRCodeSetup
            integration={integrationByType(MerchantIntegrationType.qr_code)}
            onUpdateIntegration={props.onUpdateIntegration}
          />
        </ChannelSlideoutContent>
      </SlideOut>
      <SlideOut
        showing={location.pathname.endsWith('/embeddable-page')}
        onClose={() => history.replace('/channels')}
        onBack={() => history.replace('/channels')}
      >
        <ChannelSlideoutContent
          title="Embedded Oyster Page"
          description="Display Oyster insurance information page on your website."
          integrationStatus={
            integrationByType(MerchantIntegrationType.embeddable_marketing_page)?.Status
          }
        >
          <EmbeddablePageSetup
            apiKey={props.apiKey}
            integration={integrationByType(MerchantIntegrationType.embeddable_marketing_page)}
          />
        </ChannelSlideoutContent>
      </SlideOut>
    </>
  );
};

export const ChannelsPage = (props: {
  apiKey: string;
  merchant: Merchant;
  merchantIntegrations: MerchantIntegration[];
}) => {
  const [merchantIntegrations, setMerchantIntegrations] = React.useState(
    props.merchantIntegrations
  );
  const onUpdateIntegration = async (integration: MerchantIntegration) => {
    const res = await updateMerchantIntegration(integration);
    setMerchantIntegrations(
      merchantIntegrations.map((i) => (i.ID === integration.ID ? res.Integration : i))
    );
  };

  React.useEffect(() => {
    setMerchantIntegrations(props.merchantIntegrations);
  }, [props.merchantIntegrations]);

  return (
    <>
      <PageTitle
        title="Your Channels"
        description="Make the most of Oyster by offering insurance in each of your sales and marketing channels."
      >
        <ChannelsListPage
          apiKey={props.apiKey}
          merchant={props.merchant}
          merchantIntegrations={merchantIntegrations}
          onUpdateIntegration={onUpdateIntegration}
        />
      </PageTitle>
    </>
  );
};

export const SetupPage = (props: {
  merchant: Merchant;
  onComplete: (merchant: Merchant) => void;
}) => {
  const [loading, setLoading] = React.useState(false);
  const [validationError, setValidationError] = React.useState<ValidationError>();
  const [merchant, setMerchant] = React.useState(props.merchant);

  const onNext = async () => {
    setLoading(true);
    try {
      merchant.BusinessProfile.Personalization.ProductInsuranceEnabled = true;
      const { Merchant, NextValidationError } = await personalizeMerchant(merchant, true);

      if (NextValidationError) {
        setValidationError(NextValidationError);
      } else {
        setValidationError(undefined);
        props.onComplete(Merchant);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageTitle
        title="Product Insurance"
        description="Offer protection from theft, damage, and loss to your customers."
      >
        <div style={{ maxWidth: '600px' }}>
          <p>
            Get started with product insurance for your customers by answering a short
            questionnaire. This helps us ensure the most relevant experience for your business.
          </p>
          <MerchantPersonalizationProductInsuranceForm
            merchant={merchant}
            onUpdate={setMerchant}
            onBack={null}
            onNext={onNext}
            loading={loading}
            validationError={validationError}
          />
          <ButtonContainer style={{ padding: '24px 0px' }}>
            <Button primary onClick={onNext} loading={loading}>
              Complete Setup
            </Button>
          </ButtonContainer>
        </div>
      </PageTitle>
    </>
  );
};

export const ProductInsurancePage = (props: {
  apiKey: string;
  merchant: Merchant;
  merchantIntegrations: MerchantIntegration[];
}) => {
  const [merchant, setMerchant] = React.useState(props.merchant);

  React.useEffect(() => {
    setMerchant(props.merchant);
  }, [props.merchant]);

  if (!merchant.BusinessProfile.Personalization.ProductInsuranceEnabled) {
    return <SetupPage merchant={merchant} onComplete={setMerchant} />;
  } else {
    return <ChannelsPage {...props} />;
  }
};
