import React from 'react';
import ReactMarkdown from 'react-markdown';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import clsx from 'clsx';

import { AddressAutofill } from '@mapbox/search-js-react';
import { MAPBOX_PUBLIC_KEY } from '@oysterjs/core/mapbox';
import {
  Button as HeadlessButton,
  Checkbox as HeadlessCheckbox,
  Radio,
  RadioGroup,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle
} from '@headlessui/react';

import {
  BuildingOffice2Icon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import {
  CheckCircleIcon,
  CheckIcon,
  ChevronDoubleRightIcon,
  TrashIcon,
  PencilSquareIcon,
  PlusIcon,
  CurrencyDollarIcon,
  InformationCircleIcon
} from '@heroicons/react/20/solid';

import { Text } from '../../components/text';
import {
  Fieldset,
  Legend,
  Field,
  Label,
  FieldGroup,
  Description,
  ErrorMessage
} from '../../components/fieldset';
import { Input, InputGroup } from '../../components/input';
import { Select } from '../../components/select';
import { Textarea } from '../../components/textarea';
import { Divider } from '../../components/divider';
import { Combobox } from '../../components/combobox';

import { naics, states } from './naics';
import { Button } from '../../components/button';
import { PageWrapper } from './common';
import {
  AddBusinessApplicationLocationDocument,
  Address,
  ApplicationQuoteFieldsFragment,
  AvailableCoverageLimit,
  BindQuoteDocument,
  BindQuoteMutation,
  BuildingConstructionType,
  BuildingOwnershipType,
  BurglarAlarmType,
  BusinessApplicationCarrier,
  BusinessApplicationQuoteState,
  BusinessApplicationQuoteType,
  BusinessApplicationState,
  BusinessLegalEntityType,
  GetCoverageLimitsDocument,
  GetCoverageLimitsQuery,
  InsuranceLimits,
  InsuranceType,
  PastPolicyLossClaimStatus,
  PastPolicyLossType,
  PaymentPlanInstallmentFrequency,
  PaymentPlanPaymentMethodType,
  QuoteBusinessApplicationDocument,
  QuoteBusinessApplicationMutation,
  RemoveBusinessApplicationLocationDocument,
  SubmitBusinessApplicationDocument,
  SubmitBusinessApplicationMutation,
  UnderwritingQuestion,
  UnderwritingQuestionDependencyType,
  UnderwritingQuestionType,
  UnderwritingStatement,
  UpdateBusinessApplicationDocument,
  UpdateBusinessApplicationLocationDocument,
  UpdateBusinessApplicationMutation,
  ValidationError
} from '../../types/graphql';
import { ApplicationContext } from './context';
import {
  DescriptionSkeleton,
  InputSkeleton,
  LabelSkeleton,
  LegendSkeleton,
  TextSkeleton
} from '../../components/skeleton';
import { getMerchantGraphQLClient, merchantUserSignInInit } from '@oysterjs/core/api/merchant';
import * as UnderwritingForm from './form';
import { getDisplayInsuranceType } from '@oysterjs/types/merchant/graphql/map';
import { Heading } from '../../components/heading';
import { ApolloError } from '@apollo/client';

const commonCoverages = [
  '$0 liability deductible',
  'AM-Best Excellent-rated carrier',
  'Create and manage COIs online',
  'Dedicated risk expert'
];

const insuranceTypes = [
  {
    id: InsuranceType.BusinessOwners,
    title: 'Business Owners',
    description: 'Combines essential coverages that include both liability and property.',
    coverages: [
      'Premise, products, and operations liability including property',
      ...commonCoverages
    ]
  },
  {
    id: InsuranceType.GeneralLiability,
    title: 'General Liability',
    description: 'Covers your business from legal claims arising from normal operations.',
    coverages: ['Premise, products, and operations liability', ...commonCoverages]
  },
  {
    id: InsuranceType.WorkersCompensation,
    title: "Workers' Compensation",
    description: 'Covers your workers by providing benefits in case of illness or injury.',
    coverages: ['Employee physical injury, disability, and death', ...commonCoverages]
  },
  {
    id: InsuranceType.Cyber,
    title: 'Cyber',
    description: 'Covers your business from cyber incidents and data breaches.',
    coverages: ['Data breach, data loss, and hacked equipment', ...commonCoverages]
  },
  {
    id: InsuranceType.DirectorsAndOfficers,
    title: 'Directors and Officers',
    description: 'Protects your company and its executives from legal claims.',
    coverages: [...commonCoverages]
  },
  {
    id: InsuranceType.ErrorsAndOmission,
    title: 'Errors and Omission',
    description: 'Protects your business from claims of inadequate work or negligent actions.',
    coverages: [...commonCoverages]
  },
  {
    id: InsuranceType.Rental,
    title: 'Rental',
    description: 'Covers any equipment your rent out from damage and liability.',
    coverages: [...commonCoverages]
  },
  {
    id: InsuranceType.UmbrellaOrExcess,
    title: 'Umbrella or Excess',
    description: 'Provides additional coverage above your primary policies.',
    coverages: [...commonCoverages]
  }
];

const workersCompensationDisabledStates = ['WY', 'ND', 'WA', 'OH'];

const currencyFormatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

const displaySelectedLimit = (insuranceType: InsuranceType, selectedLimits: InsuranceLimits) => {
  switch (insuranceType) {
    case InsuranceType.WorkersCompensation:
      return `${currencyFormatter.format(selectedLimits.wcPerAccidentLimit || 0)} / ${currencyFormatter.format(
        selectedLimits.wcPerDiseaseEmployeeLimit || 0
      )} / ${currencyFormatter.format(selectedLimits.wcPerDiseasePolicyLimit || 0)}`;
    case InsuranceType.GeneralLiability:
      return `${currencyFormatter.format(selectedLimits.glPerOccurrenceLimit || 0)} / ${currencyFormatter.format(
        selectedLimits.glAggregateLimit || 0
      )}`;
    case InsuranceType.BusinessOwners:
      return `${currencyFormatter.format(selectedLimits.bopPerOccurrenceLimit || 0)} / ${currencyFormatter.format(
        selectedLimits.bopAggregateLimit || 0
      )}`;
    case InsuranceType.Cyber:
      return `${currencyFormatter.format(selectedLimits.cyberRetentionLimit || 0)} / ${currencyFormatter.format(
        selectedLimits.cyberAggregateLimit || 0
      )}`;
    default:
      return '';
  }
};

const PlusDivider = (props: React.PropsWithChildren<unknown>) => {
  return (
    <div className="relative py-8">
      <div aria-hidden="true" className="absolute inset-0 flex items-center" role="presentation">
        <div className="w-full border-t border-neutral-950/10 dark:border-white/10" />
      </div>
      <div className="relative flex justify-center gap-4">{props.children}</div>
    </div>
  );
};

const AddressForm = (props: {
  onChange: (update: (prev: Address) => Address) => void;
  addressTitle: string;
  initialValue: Address;
  validationErrors?: {
    line1?: string;
    line2?: string;
    city?: string;
    zone?: string;
    postalCode?: string;
  };
}) => {
  const onRetrieve = (res) => {
    const address = res?.features?.[0]?.properties;
    if (address) {
      props.onChange(() => ({
        line1: address.address_line1,
        line2: address.address_line2 || undefined,
        city: address.address_level2,
        zone: address.address_level1,
        postalCode: address.postcode
      }));
    }
  };

  const showSecondLine = !!props.initialValue.line2 || !!props.validationErrors?.line2;
  const showCityStateZip =
    !!props.initialValue.city ||
    !!props.initialValue.zone ||
    !!props.initialValue.postalCode ||
    !!props.validationErrors?.city ||
    !!props.validationErrors?.zone ||
    !!props.validationErrors?.postalCode;

  return (
    <>
      <FieldGroup>
        <Field>
          <Label>{props.addressTitle}</Label>
          <div data-slot="control">
            <AddressAutofill
              accessToken={MAPBOX_PUBLIC_KEY()}
              options={{ country: 'us' }}
              onRetrieve={onRetrieve}
            >
              <Input
                type="text"
                placeholder="276 Fifth Ave"
                autoComplete="address-line1"
                value={props.initialValue.line1}
                invalid={!!props.validationErrors?.line1}
                onChange={(e) => {
                  const val = e.currentTarget.value;
                  props.onChange((prev) => ({ ...prev, line1: val }));
                }}
              />
            </AddressAutofill>
          </div>
          {props.validationErrors?.line1 && (
            <ErrorMessage>{props.validationErrors?.line1}</ErrorMessage>
          )}
        </Field>
      </FieldGroup>
      <TransitionGroup mode="out-in">
        {showSecondLine && (
          <CSSTransition timeout={100} classNames="fade">
            <FieldGroup className="pt-6">
              <Field>
                <Input
                  type="text"
                  placeholder="Suite 100"
                  autoComplete="address-line2"
                  value={props.initialValue.line2 || ''}
                  invalid={!!props.validationErrors?.line2}
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    props.onChange((prev) => ({ ...prev, line2: val }));
                  }}
                />
                {props.validationErrors?.line2 && (
                  <ErrorMessage>{props.validationErrors?.line2}</ErrorMessage>
                )}
              </Field>
            </FieldGroup>
          </CSSTransition>
        )}
        {showCityStateZip && (
          <CSSTransition timeout={100} classNames="fade">
            <FieldGroup className="flex flex-col gap-x-4 xs:flex-row">
              <Field className="w-full xs:w-[45%] pt-6">
                <Label>City</Label>
                <Input
                  type="text"
                  placeholder="New York"
                  autoComplete="address-level2"
                  value={props.initialValue.city}
                  invalid={!!props.validationErrors?.city}
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    props.onChange((prev) => ({ ...prev, city: val }));
                  }}
                />
                {props.validationErrors?.city && (
                  <ErrorMessage>{props.validationErrors?.city}</ErrorMessage>
                )}
              </Field>
              <Field className="w-full xs:w-[35%]">
                <Label>State</Label>
                <Select
                  autoComplete="address-level1"
                  value={props.initialValue.zone}
                  invalid={!!props.validationErrors?.zone}
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    props.onChange((prev) => ({ ...prev, zone: val }));
                  }}
                >
                  <option value="" disabled></option>
                  {states.map((state) => (
                    <option key={state.value} value={state.value}>
                      {state.name}
                    </option>
                  ))}
                </Select>
                {props.validationErrors?.zone && (
                  <ErrorMessage>{props.validationErrors?.zone}</ErrorMessage>
                )}
              </Field>
              <Field className="w-1/2 xs:w-[20%]">
                <Label>Zip Code</Label>
                <Input
                  type="text"
                  placeholder="10001"
                  autoComplete="postal-code"
                  value={props.initialValue.postalCode}
                  invalid={!!props.validationErrors?.postalCode}
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    props.onChange((prev) => ({ ...prev, postalCode: val }));
                  }}
                />
                {props.validationErrors?.postalCode && (
                  <ErrorMessage>{props.validationErrors?.postalCode}</ErrorMessage>
                )}
              </Field>
            </FieldGroup>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

export const Intro = () => {
  const history = useHistory();

  // Common state variables
  const { application, updateApplication, setError } = React.useContext(ApplicationContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<ValidationError[]>([]);
  const [availableLimits, setAvailableLimits] = React.useState<AvailableCoverageLimit[]>();

  // Define the form variables
  const [form, setForm] = React.useState<{
    contact?: {
      fullName?: string;
      email?: string;
      phone?: string;
    };
    selectedLimits?: {
      coverageState?: string;
      wcPerAccidentLimit?: number | null;
      wcPerDiseaseEmployeeLimit?: number | null;
      wcPerDiseasePolicyLimit?: number | null;
      glPerOccurrenceLimit?: number | null;
      glAggregateLimit?: number | null;
      bopPerOccurrenceLimit?: number | null;
      bopAggregateLimit?: number | null;
      cyberRetentionLimit?: number | null;
      cyberAggregateLimit?: number | null;
    };
    naicsCode?: string;
    insuranceTypes?: InsuranceType[];
    insuranceEffectiveAt?: string;
  }>({});

  // Set the form values based on the application
  React.useEffect(() => {
    setForm({
      contact: {
        fullName: application?.contact.fullName || '',
        email: application?.contact.email || '',
        phone: application?.contact.phone || ''
      },
      selectedLimits: application?.selectedLimits || {},
      naicsCode: application?.naicsCode || '',
      insuranceTypes: application?.insuranceTypes || [],
      insuranceEffectiveAt: (application?.insuranceEffectiveAt
        ? new Date(application?.insuranceEffectiveAt)
        : new Date()
      )
        .toISOString()
        .split('T')[0]
    });
  }, [application]);

  // If the coverage state changes, reload the available limits based on
  // the state.
  React.useEffect(() => {
    if (!form.selectedLimits?.coverageState) {
      return;
    }

    setLoading(true);
    getMerchantGraphQLClient()
      .query<GetCoverageLimitsQuery>({
        query: GetCoverageLimitsDocument,
        errorPolicy: 'all',
        variables: {
          applicationId: application?.id || '',
          state: form.selectedLimits.coverageState
        }
      })
      .then((res) => {
        if (res.data?.coverageLimits) {
          setAvailableLimits(res.data.coverageLimits);
          chooseDefaultLimits(res.data.coverageLimits);
        }
        if (res.error?.message) {
          setError(res.error);
        }
      })
      .finally(() => setLoading(false));

    return () =>
      updateForm([], (prev) => ({
        ...prev,
        selectedLimits: { coverageState: prev.selectedLimits?.coverageState }
      }));
  }, [form.selectedLimits?.coverageState]);

  React.useEffect(() => {
    // Unselect WC if selected
    if (!checkWorkersCompensationEligibility()) {
      updateForm(['insuranceTypes'], (prev) => ({
        ...prev,
        insuranceTypes: (prev.insuranceTypes || []).filter(
          (v) => v !== InsuranceType.WorkersCompensation
        )
      }));
    }
  }, [form.selectedLimits?.coverageState]);

  // Define handlers for limits
  const onChangeLimit =
    (insuranceType: InsuranceType) => (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedIndex = parseInt(e.currentTarget.value);
      if (isNaN(selectedIndex)) {
        return;
      }

      setLimit(availableLimits || [], insuranceType, selectedIndex);
    };

  const setLimit = (
    limits: AvailableCoverageLimit[],
    insuranceType: InsuranceType,
    index: number
  ) => {
    const fields = {
      [InsuranceType.WorkersCompensation]: [
        'wcPerAccidentLimit',
        'wcPerDiseaseEmployeeLimit',
        'wcPerDiseasePolicyLimit'
      ],
      [InsuranceType.GeneralLiability]: ['glPerOccurrenceLimit', 'glAggregateLimit'],
      [InsuranceType.BusinessOwners]: ['bopPerOccurrenceLimit', 'bopAggregateLimit'],
      [InsuranceType.Cyber]: ['cyberRetentionLimit', 'cyberAggregateLimit']
    };

    updateFormMulti(
      fields[insuranceType]?.map((field) => ['selectedLimits', field]) || [],
      (prev) => ({
        ...prev,
        selectedLimits: {
          ...prev.selectedLimits,
          ...Object.fromEntries(
            Object.entries(limits?.[index]?.limits || {}).filter(([, v]) => !!v)
          )
        }
      })
    );
  };

  const getLimitValue = (insuranceType: InsuranceType) => {
    // Depending on the insurance type, find the index of the available limit based on what
    // is selected in the form.
    let index: number | undefined;

    switch (insuranceType) {
      case InsuranceType.WorkersCompensation:
        index = availableLimits?.findIndex(
          (l) =>
            form.selectedLimits?.wcPerAccidentLimit &&
            form.selectedLimits?.wcPerDiseaseEmployeeLimit &&
            form.selectedLimits?.wcPerDiseasePolicyLimit &&
            l.limits.wcPerAccidentLimit === form.selectedLimits?.wcPerAccidentLimit &&
            l.limits.wcPerDiseaseEmployeeLimit === form.selectedLimits?.wcPerDiseaseEmployeeLimit &&
            l.limits.wcPerDiseasePolicyLimit === form.selectedLimits?.wcPerDiseasePolicyLimit
        );
        break;
      case InsuranceType.GeneralLiability:
        index = availableLimits?.findIndex(
          (l) =>
            form.selectedLimits?.glPerOccurrenceLimit &&
            form.selectedLimits?.glAggregateLimit &&
            l.limits.glPerOccurrenceLimit === form.selectedLimits?.glPerOccurrenceLimit &&
            l.limits.glAggregateLimit === form.selectedLimits?.glAggregateLimit
        );
        break;
      case InsuranceType.BusinessOwners:
        index = availableLimits?.findIndex(
          (l) =>
            form.selectedLimits?.bopPerOccurrenceLimit &&
            form.selectedLimits?.bopAggregateLimit &&
            l.limits.bopPerOccurrenceLimit === form.selectedLimits?.bopPerOccurrenceLimit &&
            l.limits.bopAggregateLimit === form.selectedLimits?.bopAggregateLimit
        );
        break;
      case InsuranceType.Cyber:
        index = availableLimits?.findIndex(
          (l) =>
            form.selectedLimits?.cyberRetentionLimit &&
            form.selectedLimits?.cyberAggregateLimit &&
            l.limits.cyberRetentionLimit === form.selectedLimits?.cyberRetentionLimit &&
            l.limits.cyberAggregateLimit === form.selectedLimits?.cyberAggregateLimit
        );
        break;
    }

    return index === undefined || index < 0 ? '' : index.toString();
  };

  const chooseDefaultLimits = (limits: AvailableCoverageLimit[]) => {
    // Group limits by insurance type
    const groupedLimits: Record<InsuranceType, AvailableCoverageLimit[]> = limits.reduce(
      (acc, limit) => ({
        ...acc,
        [limit.insuranceType]: [...(acc[limit.insuranceType] || []), limit]
      }),
      {} as Record<InsuranceType, AvailableCoverageLimit[]>
    );

    // Choose the default limit for each insurance type if it's not already set
    Object.entries(groupedLimits).forEach(([insuranceType, limits]) => {
      let index: number = -1;

      switch (insuranceType) {
        case InsuranceType.WorkersCompensation:
          if (!form.selectedLimits?.wcPerAccidentLimit) {
            index = limits.findIndex((l) => l.limits.wcPerAccidentLimit === 1_000_000);
          }
          break;
        case InsuranceType.GeneralLiability:
          if (!form.selectedLimits?.glPerOccurrenceLimit) {
            index = limits.findIndex((l) => l.limits.glPerOccurrenceLimit === 1_000_000);
          }
          break;
        case InsuranceType.BusinessOwners:
          if (!form.selectedLimits?.bopPerOccurrenceLimit) {
            index = limits.findIndex((l) => l.limits.bopPerOccurrenceLimit === 1_000_000);
          }
          break;
        case InsuranceType.Cyber:
          if (!form.selectedLimits?.cyberRetentionLimit) {
            index = limits.findIndex((l) => l.limits.cyberRetentionLimit === 10_000);
          }
          break;
      }

      if (index !== -1) {
        setLimit(limits, insuranceType as InsuranceType, index);
      }
    });
  };

  // Define the error fields that need to be validated on this page
  const errorFields = [
    ['contact', 'fullName'],
    ['contact', 'email'],
    ['contact', 'phone'],
    ['naicsCode'],
    ['insuranceTypes'],
    ['selectedLimits', 'coverageState'],
    ['selectedLimits', 'wcPerAccidentLimit'],
    ['selectedLimits', 'wcDiseaseEachEmployeeLimit'],
    ['selectedLimits', 'wcPerDiseasePolicyLimit'],
    ['selectedLimits', 'glPerOccurrenceLimit'],
    ['selectedLimits', 'glAggregateLimit'],
    ['selectedLimits', 'bopPerOccurrenceLimit'],
    ['selectedLimits', 'bopAggregateLimit'],
    ['selectedLimits', 'cyberRetentionLimit'],
    ['selectedLimits', 'cyberAggregateLimit'],
    ['insuranceEffectiveAt']
  ];

  // Define an update handler for the form
  const updateFormMulti = (
    fieldPartsList: string[][],
    update: (prev: typeof form) => typeof form
  ) => {
    fieldPartsList.forEach((fieldParts) =>
      setValidationErrors((prev) =>
        prev.filter(
          (e) =>
            e.field.length !== fieldParts.length || !e.field.every((v, i) => v === fieldParts[i])
        )
      )
    );
    setForm((prev) => update(prev));
  };

  const updateForm = (fieldParts: string[], update: (prev: typeof form) => typeof form) =>
    updateFormMulti([fieldParts], update);

  // Get the error message for a specific field
  const getError = (fieldParts: string[]) =>
    validationErrors.find(
      (e) => e.field.length === fieldParts.length && e.field.every((v, i) => v === fieldParts[i])
    )?.message;

  const checkWorkersCompensationEligibility = () => {
    if (!form.selectedLimits?.coverageState) {
      return true;
    }

    return !workersCompensationDisabledStates.includes(form.selectedLimits?.coverageState);
  };

  // Handle form submission, i.e. updating the application on the backend.
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await getMerchantGraphQLClient().mutate<UpdateBusinessApplicationMutation>({
        mutation: UpdateBusinessApplicationDocument,
        errorPolicy: 'all',
        variables: {
          id: application?.id,
          application: {
            ...form,
            insuranceEffectiveAt: form.insuranceEffectiveAt + 'T00:00:00Z'
          }
        }
      });

      const validationErrors =
        res.errors
          ?.filter((e) => !!e.extensions?.validationError)
          ?.map((e) => e.extensions?.validationError as ValidationError) || [];

      const noValidationErrorMatched = validationErrors.every(
        (e) =>
          !errorFields.some(
            (f) => f.length === e.field.length && f.every((v, i) => v === e.field[i])
          )
      );

      const otherErrors = res.errors?.filter((e) => !e.extensions?.validationError) || [];
      if (otherErrors.length > 0) {
        throw new ApolloError({ graphQLErrors: otherErrors });
      }

      setValidationErrors(validationErrors);
      updateApplication(res.data?.updateBusinessApplication || application);

      if (noValidationErrorMatched) {
        history.push(`/commercial/app/${application?.id}/business`);
      }
    } catch (e) {
      setError(e as ApolloError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper
      title="Let's start a quote for your business"
      description="We'll ask a few qualification questions first."
      progress={1 / 8}
      skeleton={<IntroSkeleton />}
    >
      <form onSubmit={onSubmit}>
        <Fieldset className="py-8">
          <Legend>Contact information</Legend>
          <Text>Provide your information and how we can contact you.</Text>
          <FieldGroup>
            <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
              <Field className="w-full">
                <Label>Full name</Label>
                <Input
                  type="text"
                  placeholder="May Peterson"
                  autoComplete="name"
                  value={form.contact?.fullName}
                  onChange={(e) => {
                    const fullName = e.currentTarget.value;
                    updateForm(['contact', 'fullName'], (prev) => ({
                      ...prev,
                      contact: { ...prev.contact, fullName }
                    }));
                  }}
                  invalid={!!getError(['contact', 'fullName'])}
                />
                {!!getError(['contact', 'fullName']) && (
                  <ErrorMessage>{getError(['contact', 'fullName'])}</ErrorMessage>
                )}
              </Field>
              <Field className="w-full">
                <Label>Email address</Label>
                <Input
                  type="email"
                  placeholder="may@acme.com"
                  autoComplete="email"
                  value={form.contact?.email}
                  onChange={(e) => {
                    const email = e.currentTarget.value;
                    updateForm(['contact', 'email'], (prev) => ({
                      ...prev,
                      contact: { ...prev.contact, email }
                    }));
                  }}
                  invalid={!!getError(['contact', 'email'])}
                />
                {!!getError(['contact', 'email']) && (
                  <ErrorMessage>{getError(['contact', 'email'])}</ErrorMessage>
                )}
              </Field>
              <Field className="w-full">
                <Label>Phone number</Label>
                <Input
                  type="tel"
                  placeholder="+1 (555) 123-4567"
                  autoComplete="tel"
                  value={form.contact?.phone}
                  onChange={(e) => {
                    const phone = e.currentTarget.value;
                    updateForm(['contact', 'phone'], (prev) => ({
                      ...prev,
                      contact: { ...prev.contact, phone }
                    }));
                  }}
                  invalid={!!getError(['contact', 'phone'])}
                />
                {!!getError(['contact', 'phone']) && (
                  <ErrorMessage>{getError(['contact', 'phone'])}</ErrorMessage>
                )}
              </Field>
            </div>
          </FieldGroup>
        </Fieldset>

        <Divider />

        <Fieldset className="py-8">
          <Legend>Business classification</Legend>
          <Text>
            Tell us how to classify your business. This will ensure you get the right coverages for
            your business.
          </Text>

          <FieldGroup>
            <Field className="w-full">
              <Label>Coverage State</Label>
              <Description>
                Choose the state that you're looking for coverage in (most of the time, this is the
                state that the business is based out of).
              </Description>
              <Select
                value={form.selectedLimits?.coverageState || ''}
                invalid={!!getError(['selectedLimits', 'coverageState'])}
                className="w-full xs:w-1/2"
                onChange={(e) => {
                  const val = e.currentTarget.value;
                  updateForm(['selectedLimits', 'coverageState'], (prev) => ({
                    ...prev,
                    selectedLimits: { coverageState: val }
                  }));
                }}
              >
                <option value="" disabled></option>
                {states.map((state) => (
                  <option key={state.value} value={state.value}>
                    {state.name}
                  </option>
                ))}
              </Select>
              {!!getError(['selectedLimits', 'coverageState']) && (
                <ErrorMessage>{getError(['selectedLimits', 'coverageState'])}</ErrorMessage>
              )}
            </Field>
            <Field className="w-full">
              <Label>NAICS Classification</Label>
              <Description>
                Search for the industry classification that best matches your business.
              </Description>
              <Combobox
                value={form.naicsCode}
                onChange={(naicsCode) =>
                  updateForm(['naicsCode'], (prev) => ({ ...prev, naicsCode }))
                }
                options={Object.entries(naics).map(([code, entry]) => ({
                  value: code,
                  label: entry.title,
                  description: entry.description
                }))}
                error={getError(['naicsCode'])}
              />
            </Field>
          </FieldGroup>
        </Fieldset>

        <Divider />

        <Fieldset className="py-8">
          <Legend>Coverage</Legend>
          <Text>
            Select the types of insurance coverage that your business needs. We can get you a
            competitive quote for each of these.
          </Text>

          <FieldGroup>
            <Field>
              <div data-slot="control" className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {insuranceTypes.map((insuranceType) => (
                  <HeadlessCheckbox
                    key={insuranceType.id}
                    value={insuranceType.id}
                    checked={form.insuranceTypes?.includes(insuranceType.id)}
                    onChange={(checked) =>
                      updateForm(['insuranceTypes'], (prev) => ({
                        ...prev,
                        insuranceTypes: checked
                          ? [...(prev.insuranceTypes || []), insuranceType.id]
                          : (prev.insuranceTypes || []).filter((v) => v !== insuranceType.id)
                      }))
                    }
                    disabled={
                      insuranceType.id === InsuranceType.WorkersCompensation &&
                      !checkWorkersCompensationEligibility()
                    }
                    aria-label={insuranceType.title}
                    aria-description={`${insuranceType.title}: ${insuranceType.description}`}
                    className={clsx([
                      'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 data-[disabled]:pointer-events-none',
                      'group relative flex cursor-pointer rounded-lg bg-white p-4 shadow-sm focus:outline-none border border-neutral-950/10 data-[hover]:border-neutral-950/20  data-[focus]:border-primary-500 data-[focus]:ring-2 data-[focus]:ring-primary-500 dark:bg-neutral-800 dark:border-white/10 dark:data-[hover]:border-white/20'
                    ])}
                  >
                    <span className="flex flex-1">
                      <span className="flex flex-col">
                        <span className="block text-sm font-medium text-neutral-900 dark:text-white">
                          {insuranceType.title}
                        </span>
                        <span className="mt-1 flex items-center text-xs text-neutral-500 dark:text-neutral-400 group-data-[disabled]:text-neutral-600 dark:group-data-[disabled]:text-neutral-300">
                          {(insuranceType.id !== InsuranceType.WorkersCompensation ||
                            checkWorkersCompensationEligibility()) &&
                            insuranceType.description}
                          {insuranceType.id === InsuranceType.WorkersCompensation &&
                            !checkWorkersCompensationEligibility() &&
                            "Your state is a monopolistic state. If you need to obtain workers' compensation coverage, please contact your state."}
                        </span>
                      </span>
                    </span>
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-primary-500 [.group:not([data-checked])_&]:invisible"
                    />
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-primary-500"
                    />
                  </HeadlessCheckbox>
                ))}
              </div>
              {!!getError(['insuranceTypes']) && (
                <ErrorMessage>{getError(['insuranceTypes'])}</ErrorMessage>
              )}
            </Field>
          </FieldGroup>
          {!!availableLimits?.length && (
            <FieldGroup>
              {form.insuranceTypes?.includes(InsuranceType.BusinessOwners) && (
                <Field>
                  <Label>Desired business owner's limits</Label>
                  <Description>
                    Choose the liability limits you'd like on your business owner's policy.
                  </Description>
                  <Select
                    className="w-full sm:w-3/4"
                    value={getLimitValue(InsuranceType.BusinessOwners)}
                    onChange={onChangeLimit(InsuranceType.BusinessOwners)}
                    disabled={loading}
                  >
                    <option value="" disabled></option>
                    {availableLimits.map((limit, i) =>
                      limit.insuranceType !== InsuranceType.BusinessOwners ? null : (
                        <option key={i} value={i.toString()}>
                          {displaySelectedLimit(InsuranceType.BusinessOwners, limit.limits)}
                        </option>
                      )
                    )}
                  </Select>
                  {!!getError(['selectedLimits', 'bopPerOccurrenceLimit']) && (
                    <ErrorMessage>
                      {getError(['selectedLimits', 'bopPerOccurrenceLimit'])}
                    </ErrorMessage>
                  )}
                </Field>
              )}
              {form.insuranceTypes?.includes(InsuranceType.GeneralLiability) && (
                <Field>
                  <Label>Desired liability limits</Label>
                  <Description>Choose the limits you'd like on your liability policy.</Description>
                  <Select
                    className="w-full sm:w-3/4"
                    value={getLimitValue(InsuranceType.GeneralLiability)}
                    onChange={onChangeLimit(InsuranceType.GeneralLiability)}
                    disabled={loading}
                  >
                    <option value="" disabled></option>
                    {availableLimits.map((limit, i) =>
                      limit.insuranceType !== InsuranceType.GeneralLiability ? null : (
                        <option key={i} value={i.toString()}>
                          {displaySelectedLimit(InsuranceType.GeneralLiability, limit.limits)}
                        </option>
                      )
                    )}
                  </Select>
                  {!!getError(['selectedLimits', 'glPerOccurrenceLimit']) && (
                    <ErrorMessage>
                      {getError(['selectedLimits', 'glPerOccurrenceLimit'])}
                    </ErrorMessage>
                  )}
                </Field>
              )}
              {form.insuranceTypes?.includes(InsuranceType.WorkersCompensation) && (
                <Field>
                  <Label>Desired workers' compensation limits</Label>
                  <Description>
                    Choose the limits you'd like on your workers' compensation policy.
                  </Description>
                  <Select
                    className="w-full sm:w-3/4"
                    value={getLimitValue(InsuranceType.WorkersCompensation)}
                    onChange={onChangeLimit(InsuranceType.WorkersCompensation)}
                    disabled={loading}
                  >
                    <option value="" disabled></option>
                    {availableLimits.map((limit, i) =>
                      limit.insuranceType !== InsuranceType.WorkersCompensation ? null : (
                        <option key={i} value={i.toString()}>
                          {displaySelectedLimit(InsuranceType.WorkersCompensation, limit.limits)}
                        </option>
                      )
                    )}
                  </Select>
                  {!!getError(['selectedLimits', 'wcPerAccidentLimit']) && (
                    <ErrorMessage>
                      {getError(['selectedLimits', 'wcPerAccidentLimit'])}
                    </ErrorMessage>
                  )}
                </Field>
              )}
              {form.insuranceTypes?.includes(InsuranceType.Cyber) && (
                <Field>
                  <Label>Desired cyber limits</Label>
                  <Description>
                    Choose the liability limits you'd like on your cyber policy.
                  </Description>
                  <Select
                    className="w-full sm:w-3/4"
                    value={getLimitValue(InsuranceType.Cyber)}
                    onChange={onChangeLimit(InsuranceType.Cyber)}
                    disabled={loading}
                  >
                    <option value="" disabled></option>
                    {availableLimits.map((limit, i) =>
                      limit.insuranceType !== InsuranceType.Cyber ? null : (
                        <option key={i} value={i.toString()}>
                          {new Intl.NumberFormat(undefined, {
                            style: 'currency',
                            currency: 'usd',
                            maximumFractionDigits: 0
                          }).format(limit.limits.cyberRetentionLimit || 0)}{' '}
                          /{' '}
                          {new Intl.NumberFormat(undefined, {
                            style: 'currency',
                            currency: 'usd',
                            maximumFractionDigits: 0
                          }).format(limit.limits.cyberAggregateLimit || 0)}
                        </option>
                      )
                    )}
                  </Select>
                  {!!getError(['selectedLimits', 'cyberRetentionLimit']) && (
                    <ErrorMessage>
                      {getError(['selectedLimits', 'cyberRetentionLimit'])}
                    </ErrorMessage>
                  )}
                </Field>
              )}
            </FieldGroup>
          )}

          <FieldGroup>
            <Field>
              <Label>Insurance Effective Date</Label>
              <Description>
                This is the date you'd like your insurance coverage to start. You can change this
                later.
              </Description>
              <Input
                type="date"
                className="sm:w-36"
                value={form.insuranceEffectiveAt}
                onChange={(e) => {
                  const insuranceEffectiveAt = e.currentTarget.value;
                  updateForm(['insuranceEffectiveAt'], (prev) => ({
                    ...prev,
                    insuranceEffectiveAt
                  }));
                }}
                invalid={!!getError(['insuranceEffectiveAt'])}
              />
              {!!getError(['insuranceEffectiveAt']) && (
                <ErrorMessage>{getError(['insuranceEffectiveAt'])}</ErrorMessage>
              )}
            </Field>
          </FieldGroup>
        </Fieldset>

        <div className="flex justify-center gap-4">
          <Button
            color="sky"
            type="submit"
            disabled={loading}
            className={clsx(
              loading && ['animate-pulse !bg-primary-100 !text-primary-700'],
              'transition-all hover:!bg-primary-100 hover:!text-primary-700'
            )}
          >
            Save and continue
          </Button>
        </div>
      </form>
    </PageWrapper>
  );
};

export const IntroSkeleton = () => {
  return (
    <div>
      <Fieldset className="py-8">
        <LegendSkeleton />
        <TextSkeleton />
        <FieldGroup>
          <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
            <Field className="w-full">
              <LabelSkeleton />
              <InputSkeleton />
            </Field>
            <Field className="w-full">
              <LabelSkeleton />
              <InputSkeleton />
            </Field>
            <Field className="w-full">
              <LabelSkeleton />
              <InputSkeleton />
            </Field>
          </div>
        </FieldGroup>
      </Fieldset>

      <Divider />

      <Fieldset className="py-8">
        <LegendSkeleton />
        <TextSkeleton />

        <FieldGroup>
          <Field className="w-full xs:w-1/2">
            <LabelSkeleton />
            <InputSkeleton />
          </Field>
          <Field className="w-full">
            <LabelSkeleton />
            <InputSkeleton />
          </Field>
        </FieldGroup>
      </Fieldset>

      <Divider />

      <Fieldset className="py-8">
        <LegendSkeleton />
        <TextSkeleton />

        <FieldGroup>
          <Field>
            <LabelSkeleton />
            <DescriptionSkeleton />
            <InputSkeleton />
          </Field>
        </FieldGroup>
      </Fieldset>
    </div>
  );
};

export const Business = () => {
  const history = useHistory();

  // Common state variables
  const { application, updateApplication, setError } = React.useContext(ApplicationContext);
  // const [jobCodes, setJobCodes] = React.useState<JobCode[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<ValidationError[]>([]);

  // Define the form variables
  const [form, setForm] = React.useState<{
    businessName?: string;
    businessDba?: string;
    mailingAddress: Address;
    fein?: string;
    legalEntityType?: string;
    annualRevenue?: string;
    yearOfFounding?: string;
    yearsOfManagementExperience?: string;
    employeeInfo?: {
      numFullTimeEmployees?: string;
      numPartTimeEmployees?: string;
      totalEmployeePayroll?: string;
    };
    owners?: {
      fullName?: string;
      annualPayroll?: string;
      dateOfBirth?: string;
      jobCode?: string;
    }[];
  }>({ mailingAddress: { line1: '', city: '', zone: '', postalCode: '' } });

  // Set the form values based on the application
  React.useEffect(() => {
    setForm({
      businessName: application?.businessName || '',
      businessDba: application?.businessDba || '',
      mailingAddress: {
        line1: application?.mailingAddress?.line1 || '',
        line2: application?.mailingAddress?.line2,
        city: application?.mailingAddress?.city || '',
        zone: application?.mailingAddress?.zone || '',
        postalCode: application?.mailingAddress?.postalCode || ''
      },
      fein: application?.fein || '',
      legalEntityType: application?.legalEntityType || '',
      annualRevenue: !application?.annualRevenue ? '' : application.annualRevenue.toString(),
      yearOfFounding: application?.yearOfFounding || '',
      yearsOfManagementExperience: !application?.annualRevenue
        ? ''
        : application.yearsOfManagementExperience.toString(),
      employeeInfo: {
        numFullTimeEmployees: !application?.employeeInfo?.numFullTimeEmployees
          ? ''
          : application.employeeInfo.numFullTimeEmployees.toString(),
        numPartTimeEmployees: !application?.employeeInfo?.numPartTimeEmployees
          ? ''
          : application.employeeInfo.numPartTimeEmployees.toString(),
        totalEmployeePayroll: !application?.employeeInfo?.totalEmployeePayroll
          ? ''
          : application.employeeInfo.totalEmployeePayroll.toString()
      },
      owners: application?.owners?.length
        ? application.owners.map((owner) => ({
            fullName: owner.fullName,
            annualPayroll: !owner.annualPayroll ? '' : owner.annualPayroll.toString(),
            dateOfBirth: (owner.dateOfBirth ? new Date(owner.dateOfBirth) : new Date())
              .toISOString()
              .split('T')[0],
            jobCode: owner.jobCode || ''
          }))
        : [
            {
              fullName: '',
              annualPayroll: '',
              dateOfBirth: new Date().toISOString().split('T')[0],
              jobCode: ''
            }
          ]
    });
  }, [application]);

  // Define the error fields that need to be validated on this page
  const errorFields = [
    ['businessName'],
    ['businessDba'],
    ['mailingAddress', 'line1'],
    ['mailingAddress', 'line2'],
    ['mailingAddress', 'city'],
    ['mailingAddress', 'zone'],
    ['mailingAddress', 'postalCode'],
    ['fein'],
    ['legalEntityType'],
    ['annualRevenue'],
    ['yearOfFounding'],
    ['yearsOfManagementExperience'],
    ['employeeInfo', 'numFullTimeEmployees'],
    ['employeeInfo', 'numPartTimeEmployees'],
    ['employeeInfo', 'totalEmployeePayroll'],
    ...(form.owners?.flatMap((_, i) => [
      ['owners', i.toString(), 'fullName'],
      ['owners', i.toString(), 'annualPayroll'],
      ['owners', i.toString(), 'dateOfBirth'],
      ['owners', i.toString(), 'jobCode']
    ]) || [])
  ];

  // Define an update handler for the form
  const updateFormMulti = (
    fieldPartsList: string[][],
    update: (prev: typeof form) => typeof form
  ) => {
    fieldPartsList.forEach((fieldParts) =>
      setValidationErrors((prev) =>
        prev.filter(
          (e) =>
            e.field.length !== fieldParts.length || !e.field.every((v, i) => v === fieldParts[i])
        )
      )
    );
    setForm((prev) => update(prev));
  };

  const updateForm = (fieldParts: string[], update: (prev: typeof form) => typeof form) =>
    updateFormMulti([fieldParts], update);

  const addEmptyOwner = () =>
    updateForm([], (prev) => ({
      ...prev,
      owners: [
        ...(prev.owners || []),
        {
          fullName: '',
          annualPayroll: '',
          dateOfBirth: new Date().toISOString().split('T')[0]
        }
      ]
    }));

  const removePreviousOwner = () =>
    updateForm([], (prev) => ({
      ...prev,
      owners: prev.owners?.slice(0, prev.owners.length - 1)
    }));

  // Get the error message for a specific field
  const getError = (fieldParts: string[]) =>
    validationErrors.find(
      (e) => e.field.length === fieldParts.length && e.field.every((v, i) => v === fieldParts[i])
    )?.message;

  // Handle form submission, i.e. updating the application on the backend.
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await getMerchantGraphQLClient().mutate<UpdateBusinessApplicationMutation>({
        mutation: UpdateBusinessApplicationDocument,
        errorPolicy: 'all',
        variables: {
          id: application?.id,
          application: {
            ...form,
            employeeInfo: {
              numFullTimeEmployees: parseInt(
                (form.employeeInfo?.numFullTimeEmployees || '0').replace(/[$,]/g, '')
              ),
              numPartTimeEmployees: parseInt(
                (form.employeeInfo?.numPartTimeEmployees || '0').replace(/[$,]/g, '')
              ),
              totalEmployeePayroll: parseFloat(
                (form.employeeInfo?.totalEmployeePayroll || '0').replace(/[$,]/g, '')
              )
            },
            legalEntityType: form.legalEntityType || null,
            annualRevenue: parseFloat((form.annualRevenue || '').replace(/[$,]/g, '')),
            yearsOfManagementExperience: parseInt(form.yearsOfManagementExperience || '0'),
            owners: form.owners?.map((owner) => ({
              ...owner,
              annualPayroll: parseFloat((owner.annualPayroll || '0').replace(/[$,]/g, ''))
            }))
          }
        }
      });

      const validationErrors =
        res.errors
          ?.filter((e) => !!e.extensions?.validationError)
          ?.map((e) => e.extensions?.validationError as ValidationError) || [];

      const noValidationErrorMatched = validationErrors.every(
        (e) =>
          !errorFields.some(
            (f) => f.length === e.field.length && f.every((v, i) => v === e.field[i])
          )
      );

      const otherErrors = res.errors?.filter((e) => !e.extensions?.validationError) || [];
      if (otherErrors.length > 0) {
        throw new ApolloError({ graphQLErrors: otherErrors });
      }

      setValidationErrors(validationErrors);
      updateApplication(res.data?.updateBusinessApplication || application);

      if (noValidationErrorMatched) {
        history.push(`/commercial/app/${application?.id}/locations`);
      }
    } catch (e) {
      setError(e as ApolloError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper
      title="Tell us about your business"
      description="These questions help us understand the shape and scale of your operations."
      progress={2 / 8}
      skeleton={<BusinessSkeleton />}
    >
      <form onSubmit={onSubmit}>
        <Fieldset className="py-8">
          <Legend>Business Profile</Legend>
          <Text>Provide some basic details about the business you'd like insurance for.</Text>
          <FieldGroup>
            <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
              <Field className="w-full">
                <Label>Business name</Label>
                <Input
                  type="text"
                  placeholder="ACME Corporation"
                  value={form.businessName}
                  onChange={(e) => {
                    const businessName = e.currentTarget.value;
                    updateForm(['businessName'], (prev) => ({ ...prev, businessName }));
                  }}
                  invalid={!!getError(['businessName'])}
                />
                {!!getError(['businessName']) && (
                  <ErrorMessage>{getError(['businessName'])}</ErrorMessage>
                )}
              </Field>
              <Field className="w-full">
                <Label>Business DBA</Label>
                <Input
                  type="text"
                  value={form.businessDba}
                  onChange={(e) => {
                    const businessDba = e.currentTarget.value;
                    updateForm(['businessDba'], (prev) => ({ ...prev, businessDba }));
                  }}
                  invalid={!!getError(['businessDba'])}
                />
                {!!getError(['businessDba']) && (
                  <ErrorMessage>{getError(['businessDba'])}</ErrorMessage>
                )}
              </Field>
            </div>
          </FieldGroup>
          <AddressForm
            addressTitle="Mailing address"
            onChange={(update) =>
              updateFormMulti(
                [
                  ['mailingAddress', 'line1'],
                  ['mailingAddress', 'line2'],
                  ['mailingAddress', 'city'],
                  ['mailingAddress', 'zone'],
                  ['mailingAddress', 'postalCode']
                ],
                (prev) => ({
                  ...prev,
                  mailingAddress: update(prev.mailingAddress)
                })
              )
            }
            initialValue={{
              line1: form.mailingAddress?.line1 || '',
              line2: form.mailingAddress?.line2,
              city: form.mailingAddress?.city || '',
              zone: form.mailingAddress?.zone || '',
              postalCode: form.mailingAddress?.postalCode || ''
            }}
            validationErrors={{
              line1: getError(['mailingAddress', 'line1']),
              line2: getError(['mailingAddress', 'line2']),
              city: getError(['mailingAddress', 'city']),
              zone: getError(['mailingAddress', 'zone']),
              postalCode: getError(['mailingAddress', 'postalCode'])
            }}
          />
        </Fieldset>

        <Divider />

        <Fieldset className="py-8">
          <Legend>Business Details</Legend>
          <Text>
            Tell us more about your business, including your FEIN, legal entity type, annual
            revenue, and year of founding.
          </Text>

          <FieldGroup>
            <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
              <Field className="w-full">
                <Label>FEIN (Employer Identification Number)</Label>
                <Input
                  type="text"
                  placeholder="XX-XXXXXX"
                  value={form.fein}
                  onChange={(e) => {
                    const fein = e.currentTarget.value;
                    updateForm(['fein'], (prev) => ({ ...prev, fein }));
                  }}
                  invalid={!!getError(['fein'])}
                />
                {!!getError(['fein']) && <ErrorMessage>{getError(['fein'])}</ErrorMessage>}
              </Field>
              <Field className="w-full">
                <Label>Entity type</Label>
                <Select
                  value={form.legalEntityType}
                  onChange={(e) => {
                    const legalEntityType = e.currentTarget.value;
                    updateForm(['legalEntityType'], (prev) => ({ ...prev, legalEntityType }));
                  }}
                  invalid={!!getError(['legalEntityType'])}
                >
                  <option value="" disabled></option>
                  <option value={BusinessLegalEntityType.Association}>Association</option>
                  <option value={BusinessLegalEntityType.Corporation}>Corporation</option>
                  <option value={BusinessLegalEntityType.Estate}>Estate</option>
                  <option value={BusinessLegalEntityType.Executor}>Executor</option>
                  <option value={BusinessLegalEntityType.GeneralPartnership}>
                    General Partnership
                  </option>
                  <option value={BusinessLegalEntityType.GovernmentEntity}>
                    Government Entity
                  </option>
                  <option value={BusinessLegalEntityType.Individual}>Individual</option>
                  <option value={BusinessLegalEntityType.JointEmployers}>Joint Employers</option>
                  <option value={BusinessLegalEntityType.JointVenture}>Joint Venture</option>
                  <option value={BusinessLegalEntityType.LaborUnion}>Labor Union</option>
                  <option value={BusinessLegalEntityType.LimitedLiabilityCompany}>
                    Limited Liability Company
                  </option>
                  <option value={BusinessLegalEntityType.LimitedLiabilityPartnership}>
                    Limited Liability Partnership
                  </option>
                  <option value={BusinessLegalEntityType.LimitedPartnership}>
                    Limited Partnership
                  </option>
                  <option value={BusinessLegalEntityType.MultipleStatus}>Multiple Statuses</option>
                  <option value={BusinessLegalEntityType.NonProfitAssociation}>
                    Non-Profit Association
                  </option>
                  <option value={BusinessLegalEntityType.NonProfitCorporation}>
                    Non-Profit Corporation
                  </option>
                  <option value={BusinessLegalEntityType.SCorporation}>S Corporation</option>
                  <option value={BusinessLegalEntityType.TenantsInCommon}>Tenants in Common</option>
                  <option value={BusinessLegalEntityType.Trust}>Trust</option>
                </Select>
                {!!getError(['legalEntityType']) && (
                  <ErrorMessage>{getError(['legalEntityType'])}</ErrorMessage>
                )}
              </Field>
            </div>
            <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-3">
              <Field>
                <Label>Annual Revenue</Label>
                <InputGroup>
                  <CurrencyDollarIcon />
                  <Input
                    type="number"
                    min={0}
                    value={form.annualRevenue}
                    onKeyDown={(e) =>
                      e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null
                    }
                    onWheel={(e) => e.currentTarget.blur()}
                    onChange={(e) => {
                      const annualRevenue = e.currentTarget.value;
                      updateForm(['annualRevenue'], (prev) => ({ ...prev, annualRevenue }));
                    }}
                    invalid={!!getError(['annualRevenue'])}
                  />
                </InputGroup>

                {!!getError(['annualRevenue']) && (
                  <ErrorMessage>{getError(['annualRevenue'])}</ErrorMessage>
                )}
              </Field>
              <Field>
                <Label>Year of Founding</Label>
                <Input
                  type="number"
                  className="w-24"
                  value={form.yearOfFounding}
                  maxLength={4}
                  min={1000}
                  onKeyDown={(e) =>
                    e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null
                  }
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    const yearOfFounding = e.currentTarget.value;
                    updateForm(['yearOfFounding'], (prev) => ({ ...prev, yearOfFounding }));
                  }}
                  invalid={!!getError(['yearOfFounding'])}
                />
                {!!getError(['yearOfFounding']) && (
                  <ErrorMessage>{getError(['yearOfFounding'])}</ErrorMessage>
                )}
              </Field>
              <Field>
                <Label>Years in Managment</Label>
                <Input
                  type="number"
                  className="w-24"
                  value={form.yearsOfManagementExperience}
                  maxLength={4}
                  min={0}
                  onKeyDown={(e) =>
                    e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null
                  }
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    const yearsOfManagementExperience = e.currentTarget.value;
                    updateForm(['yearsOfManagementExperience'], (prev) => ({
                      ...prev,
                      yearsOfManagementExperience
                    }));
                  }}
                  invalid={!!getError(['yearsOfManagementExperience'])}
                />
                {!!getError(['yearsOfManagementExperience']) && (
                  <ErrorMessage>{getError(['yearsOfManagementExperience'])}</ErrorMessage>
                )}
              </Field>
            </div>
          </FieldGroup>
        </Fieldset>

        <Divider />

        <Fieldset className="py-8">
          <Legend>Employees</Legend>
          <Text>
            Tell us how many employees work at your business and the total payroll amount (excluding
            owners).
          </Text>

          <FieldGroup>
            <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-3">
              <Field>
                <Label>Full-Time Employees</Label>
                <Input
                  type="number"
                  value={form.employeeInfo?.numFullTimeEmployees || ''}
                  onKeyDown={(e) =>
                    e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null
                  }
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    const numFullTimeEmployees = e.currentTarget.value;
                    updateForm(['employeeInfo', 'numFullTimeEmployees'], (prev) => ({
                      ...prev,
                      employeeInfo: {
                        ...prev?.employeeInfo,
                        numFullTimeEmployees
                      }
                    }));
                  }}
                  invalid={!!getError(['employeeInfo', 'numFullTimeEmployees'])}
                />
                {!!getError(['employeeInfo', 'numFullTimeEmployees']) && (
                  <ErrorMessage>{getError(['employeeInfo', 'numFullTimeEmployees'])}</ErrorMessage>
                )}
              </Field>
              <Field>
                <Label>Part-Time Employees</Label>
                <Input
                  type="number"
                  value={form.employeeInfo?.numPartTimeEmployees || ''}
                  onKeyDown={(e) =>
                    e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null
                  }
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    const numPartTimeEmployees = e.currentTarget.value;
                    updateForm(['employeeInfo', 'numPartTimeEmployees'], (prev) => ({
                      ...prev,
                      employeeInfo: {
                        ...prev?.employeeInfo,
                        numPartTimeEmployees
                      }
                    }));
                  }}
                  invalid={!!getError(['employeeInfo', 'numPartTimeEmployees'])}
                />
                {!!getError(['employeeInfo', 'numPartTimeEmployees']) && (
                  <ErrorMessage>{getError(['employeeInfo', 'numPartTimeEmployees'])}</ErrorMessage>
                )}
              </Field>
              <Field>
                <Label>Total Payroll</Label>
                <InputGroup>
                  <CurrencyDollarIcon />
                  <Input
                    type="number"
                    value={form.employeeInfo?.totalEmployeePayroll || ''}
                    onKeyDown={(e) =>
                      e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null
                    }
                    onWheel={(e) => e.currentTarget.blur()}
                    onChange={(e) => {
                      const totalEmployeePayroll = e.currentTarget.value;
                      updateForm(['employeeInfo', 'totalEmployeePayroll'], (prev) => ({
                        ...prev,
                        employeeInfo: {
                          ...prev?.employeeInfo,
                          totalEmployeePayroll
                        }
                      }));
                    }}
                    invalid={!!getError(['employeeInfo', 'totalEmployeePayroll'])}
                  />
                </InputGroup>
                {!!getError(['employeeInfo', 'totalEmployeePayroll']) && (
                  <ErrorMessage>{getError(['employeeInfo', 'totalEmployeePayroll'])}</ErrorMessage>
                )}
              </Field>
            </div>
          </FieldGroup>
        </Fieldset>

        <Divider />

        <Fieldset className="py-8">
          <Legend>Business owners</Legend>
          <Text>Tell us about each individual considered an owner of your business.</Text>

          {form.owners?.map((owner, index) => (
            <FieldGroup key={index}>
              <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-3">
                <Field>
                  <Label>Owner Name</Label>
                  <Input
                    type="text"
                    placeholder="Beth Wu"
                    value={owner.fullName}
                    onChange={(e) => {
                      const fullName = e.currentTarget.value;
                      updateForm(['owners', index.toString(), 'fullName'], (prev) => ({
                        ...prev,
                        owners: prev.owners?.map((owner, i) =>
                          i !== index ? owner : { ...owner, fullName }
                        )
                      }));
                    }}
                    invalid={!!getError(['owners', index.toString(), 'fullName'])}
                  />
                  {!!getError(['owners', index.toString(), 'fullName']) && (
                    <ErrorMessage>
                      {getError(['owners', index.toString(), 'fullName'])}
                    </ErrorMessage>
                  )}
                </Field>

                <Field>
                  <Label>Annual Payroll</Label>
                  <InputGroup>
                    <CurrencyDollarIcon />
                    <Input
                      type="number"
                      value={owner.annualPayroll}
                      onKeyDown={(e) =>
                        e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null
                      }
                      onWheel={(e) => e.currentTarget.blur()}
                      onChange={(e) => {
                        const annualPayroll = e.currentTarget.value;
                        updateForm(['owners', index.toString(), 'annualPayroll'], (prev) => ({
                          ...prev,
                          owners: prev.owners?.map((owner, i) =>
                            i !== index ? owner : { ...owner, annualPayroll }
                          )
                        }));
                      }}
                      invalid={!!getError(['owners', index.toString(), 'annualPayroll'])}
                    />
                  </InputGroup>
                  {!!getError(['owners', index.toString(), 'annualPayroll']) && (
                    <ErrorMessage>
                      {getError(['owners', index.toString(), 'annualPayroll'])}
                    </ErrorMessage>
                  )}
                </Field>

                <Field>
                  <Label>Date of Birth</Label>
                  <Input
                    type="date"
                    className="w-36"
                    value={owner.dateOfBirth}
                    onChange={(e) => {
                      const dateOfBirth = e.currentTarget.value;
                      updateForm(['owners', index.toString(), 'dateOfBirth'], (prev) => ({
                        ...prev,
                        owners: prev.owners?.map((owner, i) =>
                          i !== index ? owner : { ...owner, dateOfBirth }
                        )
                      }));
                    }}
                    invalid={!!getError(['owners', index.toString(), 'dateOfBirth'])}
                  />
                  {!!getError(['owners', index.toString(), 'dateOfBirth']) && (
                    <ErrorMessage>
                      {getError(['owners', index.toString(), 'dateOfBirth'])}
                    </ErrorMessage>
                  )}
                </Field>

                {/* <Field className="sm:col-span-3">
                  <Label>Job Code</Label>
                  <Description>
                    Choose the job code that best describes this owner's role
                  </Description>
                  <Combobox
                    value={owner.jobCode}
                    onChange={(jobCode) =>
                      updateForm(['owners', index.toString(), 'jobCode'], (prev) => ({
                        ...prev,
                        owners: prev.owners?.map((owner, i) =>
                          i !== index ? owner : { ...owner, jobCode }
                        )
                      }))
                    }
                    options={
                      jobCodes?.map((jobCode) => ({
                        label: jobCode.description,
                        value: jobCode.id
                      })) || []
                    }
                    error={getError(['owners', index.toString(), 'jobCode'])}
                    disabled={loading}
                  />
                </Field> */}
              </div>
            </FieldGroup>
          ))}

          <PlusDivider>
            {form.owners && form.owners.length > 1 && (
              <Button color="light" type="button" onClick={removePreviousOwner}>
                <TrashIcon aria-hidden="true" className="-ml-1 -mr-0.5 h-5 w-5 text-neutral-400" />
                Remove previous owner
              </Button>
            )}
            <Button color="light" type="button" onClick={addEmptyOwner}>
              <PlusIcon aria-hidden="true" className="-ml-1 -mr-0.5 h-5 w-5 text-neutral-400" />
              Add another owner
            </Button>
          </PlusDivider>
        </Fieldset>

        <div className="flex justify-center gap-4">
          <Button color="light" onClick={() => history.push(`/commercial/app/${application?.id}`)}>
            Previous Page
          </Button>
          <Button color="sky" type="submit" disabled={loading} className="transition-all">
            Save and continue
          </Button>
        </div>
      </form>
    </PageWrapper>
  );
};

export const BusinessSkeleton = () => {
  return (
    <div>
      <Fieldset className="py-8">
        <LegendSkeleton />
        <TextSkeleton />
        <FieldGroup>
          <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
            <Field className="w-full">
              <LabelSkeleton />
              <InputSkeleton />
            </Field>
            <Field className="w-full">
              <LabelSkeleton />
              <InputSkeleton />
            </Field>
          </div>
        </FieldGroup>
        <FieldGroup>
          <Field>
            <LabelSkeleton />
            <InputSkeleton />
          </Field>
        </FieldGroup>
      </Fieldset>

      <Divider />

      <Fieldset className="py-8">
        <LegendSkeleton />
        <TextSkeleton />
        <FieldGroup>
          <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
            <Field className="w-full">
              <LabelSkeleton />
              <InputSkeleton />
            </Field>
            <Field className="w-full">
              <LabelSkeleton />
              <InputSkeleton />
            </Field>
          </div>
          <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-3">
            <Field className="w-full">
              <LabelSkeleton />
              <InputSkeleton />
            </Field>
            <Field className="w-full">
              <LabelSkeleton />
              <InputSkeleton />
            </Field>
            <Field className="w-full">
              <LabelSkeleton />
              <InputSkeleton />
            </Field>
          </div>
        </FieldGroup>
      </Fieldset>

      <Divider />

      <Fieldset className="py-8">
        <LegendSkeleton />
        <TextSkeleton />

        <FieldGroup>
          <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-3">
            <Field>
              <LabelSkeleton />
              <InputSkeleton />
            </Field>

            <Field>
              <LabelSkeleton />
              <InputSkeleton />
            </Field>

            <Field>
              <LabelSkeleton />
              <InputSkeleton />
            </Field>
          </div>
        </FieldGroup>
      </Fieldset>

      <Divider />

      <Fieldset className="py-8">
        <LegendSkeleton />
        <TextSkeleton />

        <FieldGroup>
          <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-3">
            <Field>
              <LabelSkeleton />
              <InputSkeleton />
            </Field>

            <Field>
              <LabelSkeleton />
              <InputSkeleton />
            </Field>

            <Field>
              <LabelSkeleton />
              <InputSkeleton />
            </Field>
          </div>
        </FieldGroup>
      </Fieldset>
    </div>
  );
};

export const Locations = () => {
  const history = useHistory();

  // Common state variables
  const { application, updateApplication, setError } = React.useContext(ApplicationContext);
  // const [jobCodes, setJobCodes] = React.useState<JobCode[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<ValidationError[]>([]);

  // Define the form variables
  const [open, setOpen] = React.useState(false);
  const [form, setForm] = React.useState<{
    id: string;
    isPrimary: boolean;
    address: Address;
    buildingInfo: {
      annualSales?: string;
      areaOccupiedByBusiness?: string;
      buildingCoverage?: string;
      burglarAlarmType?: BurglarAlarmType | '';
      constructionType?: BuildingConstructionType | '';
      ownershipType?: BuildingOwnershipType | '';
      personalPropertyCoverage?: string;
      sprinkleredPercentage?: string;
      totalArea?: string;
      totalStories?: string;
      yearBuilt?: string;
    };
    employeeInfo: {
      numFullTimeEmployees?: string;
      numPartTimeEmployees?: string;
      totalEmployeePayroll?: string;
      jobCode?: string;
    };
  }>({
    id: '',
    address: { line1: '', city: '', zone: '', postalCode: '' },
    buildingInfo: {},
    isPrimary: false,
    employeeInfo: {}
  });

  // Define the error fields that need to be validated on this page
  const errorFields = Array((application?.locations?.length || 0) + 1)
    .fill(0)
    .flatMap((_, i) => [
      ['locations', i.toString(), 'isPrimary'],
      ['locations', i.toString(), 'address', 'line1'],
      ['locations', i.toString(), 'address', 'line2'],
      ['locations', i.toString(), 'address', 'city'],
      ['locations', i.toString(), 'address', 'zone'],
      ['locations', i.toString(), 'address', 'postalCode'],
      ['locations', i.toString(), 'buildingInfo', 'annualSales'],
      ['locations', i.toString(), 'buildingInfo', 'areaOccupiedByBusiness'],
      ['locations', i.toString(), 'buildingInfo', 'buildingCoverage'],
      ['locations', i.toString(), 'buildingInfo', 'burglarAlarmType'],
      ['locations', i.toString(), 'buildingInfo', 'constructionType'],
      ['locations', i.toString(), 'buildingInfo', 'ownershipType'],
      ['locations', i.toString(), 'buildingInfo', 'personalPropertyCoverage'],
      ['locations', i.toString(), 'buildingInfo', 'sprinkleredPercentage'],
      ['locations', i.toString(), 'buildingInfo', 'totalArea'],
      ['locations', i.toString(), 'buildingInfo', 'totalStories'],
      ['locations', i.toString(), 'buildingInfo', 'yearBuilt'],
      ['locations', i.toString(), 'employeeInfo', 'numFullTimeEmployees'],
      ['locations', i.toString(), 'employeeInfo', 'numPartTimeEmployees'],
      ['locations', i.toString(), 'employeeInfo', 'totalEmployeePayroll'],
      ['locations', i.toString(), 'employeeInfo', 'jobCode']
    ]);

  // Define an update handler for the form
  const updateFormMulti = (
    fieldPartsList: string[][],
    update: (prev: typeof form) => typeof form
  ) => {
    fieldPartsList.forEach((fieldParts) => {
      const foundIdx = application?.locations?.findIndex((l) => l.id === form.id) || 0;
      const idx = foundIdx !== -1 ? foundIdx : (application?.locations?.length || 1) - 1;

      fieldParts = ['locations', idx.toString(), ...fieldParts];
      setValidationErrors((prev) =>
        prev.filter(
          (e) =>
            e.field.length !== fieldParts.length || !e.field.every((v, i) => v === fieldParts[i])
        )
      );
    });
    setForm((prev) => update(prev));
  };

  const updateForm = (fieldParts: string[], update: (prev: typeof form) => typeof form) =>
    updateFormMulti([fieldParts], update);

  // Get the error message for a specific field
  const getError = (fieldParts: string[]) => {
    const foundIdx = application?.locations?.findIndex((l) => l.id === form.id) || 0;
    const idx = foundIdx !== -1 ? foundIdx : application?.locations?.length || 0;

    fieldParts = ['locations', idx.toString(), ...fieldParts];
    return validationErrors.find(
      (e) => e.field.length === fieldParts.length && e.field.every((v, i) => v === fieldParts[i])
    )?.message;
  };

  const locationIndexHasError = (index: number) =>
    !open && !!validationErrors.find((e) => e.field[1] === index.toString());

  const getDefaultLocationInfo = () => ({
    id: '' + Math.random(),
    isPrimary: !application?.locations?.length,
    address: { line1: '', city: '', zone: '', postalCode: '' },
    buildingInfo: {
      annualSales:
        application?.locations.length === 0 ? application?.annualRevenue.toString() : undefined
    },
    employeeInfo: {
      numFullTimeEmployees:
        application?.locations.length === 0
          ? application?.employeeInfo.numFullTimeEmployees.toString()
          : undefined,
      numPartTimeEmployees:
        application?.locations.length === 0
          ? application?.employeeInfo.numPartTimeEmployees.toString()
          : undefined,
      totalEmployeePayroll:
        application?.locations.length === 0
          ? application?.employeeInfo.totalEmployeePayroll.toString()
          : undefined
    }
  });

  const addLocation = () => {
    setValidationErrors([]);
    updateForm([], () => ({ ...getDefaultLocationInfo() }));
    setOpen(true);
  };

  const addLocationFromMailingAddress = () => {
    setValidationErrors([]);
    updateForm([], () => ({
      ...getDefaultLocationInfo(),
      address: application?.mailingAddress || {
        line1: '',
        city: '',
        zone: '',
        postalCode: ''
      }
    }));
    setOpen(true);
  };

  const discardChanges = () => {
    editLocation(form.id);
    setValidationErrors([]);
    setOpen(false);
  };

  // Handle form submission, i.e. updating the application on the backend.
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      // determine if update or addition
      const isUpdate = application?.locations?.find((l) => l.id === form.id);
      const mutation = isUpdate
        ? UpdateBusinessApplicationLocationDocument
        : AddBusinessApplicationLocationDocument;

      const res = await getMerchantGraphQLClient().mutate({
        mutation: mutation,
        errorPolicy: 'all',
        variables: {
          applicationId: application?.id,
          locationId: form.id,
          location: {
            ...form,
            buildingInfo: {
              annualSales: parseFloat((form.buildingInfo?.annualSales || '0').replace(/[$,]/g, '')),
              areaOccupiedByBusiness: parseFloat(
                (form.buildingInfo?.areaOccupiedByBusiness || '0').replace(/[,]/g, '')
              ),
              buildingCoverage: parseFloat(
                (form.buildingInfo?.buildingCoverage || '0').replace(/[$,]/g, '')
              ),
              burglarAlarmType: form.buildingInfo.burglarAlarmType || undefined,
              constructionType: form.buildingInfo.constructionType || undefined,
              ownershipType: form.buildingInfo.ownershipType || undefined,
              personalPropertyCoverage: parseFloat(
                (form.buildingInfo?.personalPropertyCoverage || '0').replace(/[$,]/g, '')
              ),
              sprinkleredPercentage: parseFloat(
                (form.buildingInfo?.sprinkleredPercentage || '0').replace(/[,]/g, '')
              ),
              totalArea: parseFloat((form.buildingInfo?.totalArea || '0').replace(/[,]/g, '')),
              totalStories: parseFloat(
                (form.buildingInfo?.totalStories || '0').replace(/[,]/g, '')
              ),
              yearBuilt: form.buildingInfo.yearBuilt || undefined
            },
            employeeInfo: {
              jobCode: form.employeeInfo?.jobCode || '',
              numFullTimeEmployees: parseInt(
                (form.employeeInfo?.numFullTimeEmployees || '0').replace(/[$,]/g, '')
              ),
              numPartTimeEmployees: parseInt(
                (form.employeeInfo?.numPartTimeEmployees || '0').replace(/[$,]/g, '')
              ),
              totalEmployeePayroll: parseFloat(
                (form.employeeInfo?.totalEmployeePayroll || '0').replace(/[$,]/g, '')
              )
            }
          }
        }
      });

      const validationErrors =
        res.errors
          ?.filter((e) => !!e.extensions?.validationError)
          ?.map((e) => e.extensions?.validationError as ValidationError) || [];

      const noValidationErrorMatched = validationErrors.every(
        (e) =>
          !errorFields.some(
            (f) => f.length === e.field.length && f.every((v, i) => v === e.field[i])
          )
      );

      const otherErrors = res.errors?.filter((e) => !e.extensions?.validationError) || [];
      if (otherErrors.length > 0) {
        throw new ApolloError({ graphQLErrors: otherErrors });
      }

      setValidationErrors(validationErrors);
      updateApplication(res.data?.businessApplication || application);

      if (noValidationErrorMatched) {
        setOpen(false);
      }
    } catch (e) {
      setError(e as ApolloError);
    } finally {
      setLoading(false);
    }
  };

  const editLocation = (id: string) => {
    const location = application?.locations?.find((l) => l.id === id);
    if (!location) {
      return;
    }

    updateForm([], () => ({
      id: location.id,
      isPrimary: location.isPrimary,
      address: location.address,
      buildingInfo: {
        ...location.buildingInfo,
        annualSales: location.buildingInfo.annualSales
          ? location.buildingInfo.annualSales.toString()
          : '',
        areaOccupiedByBusiness: location.buildingInfo.areaOccupiedByBusiness
          ? location.buildingInfo.areaOccupiedByBusiness.toString()
          : '',
        buildingCoverage: location.buildingInfo.buildingCoverage
          ? location.buildingInfo.buildingCoverage.toString()
          : '',
        burglarAlarmType: location.buildingInfo.burglarAlarmType || '',
        constructionType: location.buildingInfo.constructionType || '',
        ownershipType: location.buildingInfo.ownershipType || '',
        personalPropertyCoverage: location.buildingInfo.personalPropertyCoverage
          ? location.buildingInfo.personalPropertyCoverage.toString()
          : '',
        sprinkleredPercentage: location.buildingInfo.sprinkleredPercentage
          ? location.buildingInfo.sprinkleredPercentage.toString()
          : '',
        totalArea: location.buildingInfo.totalArea
          ? location.buildingInfo.totalArea.toString()
          : '',
        totalStories: location.buildingInfo.totalStories
          ? location.buildingInfo.totalStories.toString()
          : ''
      },
      employeeInfo: {
        numFullTimeEmployees: !location?.employeeInfo?.numFullTimeEmployees
          ? ''
          : location.employeeInfo.numFullTimeEmployees.toString(),
        numPartTimeEmployees: !location?.employeeInfo?.numPartTimeEmployees
          ? ''
          : location.employeeInfo.numPartTimeEmployees.toString(),
        totalEmployeePayroll: !location?.employeeInfo?.totalEmployeePayroll
          ? ''
          : location.employeeInfo.totalEmployeePayroll.toString(),
        jobCode: location.employeeInfo.jobCode || ''
      }
    }));
    setOpen(true);
  };

  const removeLocation = async (id: string) => {
    setLoading(true);

    try {
      const res = await getMerchantGraphQLClient().mutate({
        mutation: RemoveBusinessApplicationLocationDocument,
        errorPolicy: 'all',
        variables: {
          applicationId: application?.id,
          locationId: id
        }
      });

      const validationErrors =
        res.errors
          ?.filter((e) => !!e.extensions?.validationError)
          ?.map((e) => e.extensions?.validationError as ValidationError) || [];

      const otherErrors = res.errors?.filter((e) => !e.extensions?.validationError) || [];
      if (otherErrors.length > 0) {
        throw new ApolloError({ graphQLErrors: otherErrors });
      }

      setValidationErrors(validationErrors);
      updateApplication(res.data?.businessApplication || application);
    } catch (e) {
      setError(e as ApolloError);
    } finally {
      setLoading(false);
    }
  };

  const onContinue = async () => {
    setLoading(true);

    try {
      // Trigger an update to get any validation errors
      const res = await getMerchantGraphQLClient().mutate<UpdateBusinessApplicationMutation>({
        mutation: UpdateBusinessApplicationDocument,
        errorPolicy: 'all',
        variables: {
          id: application?.id,
          application: {}
        }
      });

      const validationErrors =
        res.errors
          ?.filter((e) => !!e.extensions?.validationError)
          ?.map((e) => e.extensions?.validationError as ValidationError) || [];

      const noValidationErrorMatched = validationErrors.every(
        (e) =>
          !errorFields.some(
            (f) => f.length === e.field.length && f.every((v, i) => v === e.field[i])
          )
      );

      const otherErrors = res.errors?.filter((e) => !e.extensions?.validationError) || [];
      if (otherErrors.length > 0) {
        throw new ApolloError({ graphQLErrors: otherErrors });
      }

      setValidationErrors(validationErrors);
      updateApplication(res.data?.updateBusinessApplication || application);

      if (noValidationErrorMatched) {
        history.push(`/commercial/app/${application?.id}/losses`);
      }
    } catch (e) {
      setError(e as ApolloError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper
      title="Your business locations"
      description="Describe each location your business operates from."
      progress={3 / 8}
      skeleton={<LocationsSkeleton />}
    >
      <Dialog open={open} onClose={discardChanges} className="relative z-20">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-all duration-300 data-[closed]:opacity-0"
        />

        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-10">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-full sm:max-w-lg transform transition-all duration-300 data-[closed]:translate-x-full"
              >
                <div className="flex h-full flex-col overflow-y-scroll divide-neutral-200 bg-white dark:bg-neutral-900 shadow-xl">
                  <div className="bg-primary-700 dark:bg-primary-800 px-4 py-6 sm:px-6">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-white m-0">
                        Add a new location
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="relative rounded-md bg-primary-800 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="relative flex-1 px-4 py-6 pb-24 sm:px-6">
                      <Fieldset className="py-4">
                        <Legend>Location Address</Legend>
                        <Text>Enter the address for the location you'd like to add.</Text>
                        <AddressForm
                          addressTitle="Street Address"
                          onChange={(update) => {
                            updateFormMulti(
                              [
                                ['address', 'line1'],
                                ['address', 'line2'],
                                ['address', 'city'],
                                ['address', 'zone'],
                                ['address', 'postalCode']
                              ],
                              (prev) => ({ ...prev, address: update(prev.address) })
                            );
                          }}
                          initialValue={{
                            line1: form.address?.line1 || '',
                            line2: form.address?.line2,
                            city: form.address?.city || '',
                            zone: form.address?.zone || '',
                            postalCode: form.address?.postalCode || ''
                          }}
                          validationErrors={{
                            line1: getError(['address', 'line1']),
                            line2: getError(['address', 'line2']),
                            city: getError(['address', 'city']),
                            zone: getError(['address', 'zone']),
                            postalCode: getError(['address', 'postalCode'])
                          }}
                        />
                      </Fieldset>
                      {application?.insuranceTypes.includes(InsuranceType.BusinessOwners) && (
                        <Fieldset className="py-4">
                          <Legend>Building Information</Legend>
                          <Text>Tell us about some building characteristics</Text>
                          <FieldGroup>
                            <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
                              <Field>
                                <Label>Area Occupied By Business</Label>
                                <InputGroup>
                                  <Input
                                    type="number"
                                    value={form.buildingInfo?.areaOccupiedByBusiness || ''}
                                    invalid={!!getError(['buildingInfo', 'areaOccupiedByBusiness'])}
                                    onKeyDown={(e) =>
                                      e.key === 'ArrowUp' || e.key === 'ArrowDown'
                                        ? e.preventDefault()
                                        : null
                                    }
                                    onWheel={(e) => e.currentTarget.blur()}
                                    onChange={(e) => {
                                      const areaOccupiedByBusiness = e.currentTarget.value;
                                      updateForm(
                                        ['buildingInfo', 'areaOccupiedByBusiness'],
                                        (prev) => ({
                                          ...prev,
                                          buildingInfo: {
                                            ...prev.buildingInfo,
                                            areaOccupiedByBusiness
                                          }
                                        })
                                      );
                                    }}
                                  />
                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <span className="text-neutral-500 sm:text-sm">square feet</span>
                                  </div>
                                </InputGroup>
                                {!!getError(['buildingInfo', 'areaOccupiedByBusiness']) && (
                                  <ErrorMessage>
                                    {getError(['buildingInfo', 'areaOccupiedByBusiness'])}
                                  </ErrorMessage>
                                )}
                              </Field>

                              <Field>
                                <Label>Burglar Alarm Type</Label>
                                <Select
                                  value={form.buildingInfo?.burglarAlarmType || ''}
                                  invalid={!!getError(['buildingInfo', 'burglarAlarmType'])}
                                  onChange={(e) => {
                                    const burglarAlarmType = e.currentTarget.value;
                                    updateForm(['buildingInfo', 'burglarAlarmType'], (prev) => ({
                                      ...prev,
                                      buildingInfo: {
                                        ...prev.buildingInfo,
                                        burglarAlarmType: burglarAlarmType as BurglarAlarmType
                                      }
                                    }));
                                  }}
                                >
                                  <option value="" disabled></option>
                                  <option value={BurglarAlarmType.BurglarCentral}>Central</option>
                                  <option value={BurglarAlarmType.BurglarPoliceFire}>
                                    Police and Fire
                                  </option>
                                  <option value={BurglarAlarmType.BurglarLocal}>Local</option>
                                  <option value={BurglarAlarmType.BurglarNone}>None</option>
                                </Select>
                                {!!getError(['buildingInfo', 'burglarAlarmType']) && (
                                  <ErrorMessage>
                                    {getError(['buildingInfo', 'burglarAlarmType'])}
                                  </ErrorMessage>
                                )}
                              </Field>

                              <Field>
                                <Label>Construction Type</Label>
                                <Select
                                  value={form.buildingInfo?.constructionType || ''}
                                  invalid={!!getError(['buildingInfo', 'constructionType'])}
                                  onChange={(e) => {
                                    const constructionType = e.currentTarget.value;
                                    updateForm(['buildingInfo', 'constructionType'], (prev) => ({
                                      ...prev,
                                      buildingInfo: {
                                        ...prev.buildingInfo,
                                        constructionType:
                                          constructionType as BuildingConstructionType
                                      }
                                    }));
                                  }}
                                >
                                  <option value="" disabled></option>
                                  <option value={BuildingConstructionType.Frame}>Frame</option>
                                  <option value={BuildingConstructionType.JoistedMasonry}>
                                    Joisted Masonry
                                  </option>
                                  <option value={BuildingConstructionType.NonCombustible}>
                                    Non-Combustible
                                  </option>
                                  <option value={BuildingConstructionType.MasonryNonCombustible}>
                                    Masonry Non-Combustible
                                  </option>
                                  <option value={BuildingConstructionType.ModifiedFireResistive}>
                                    Modified Fire Resistive
                                  </option>
                                  <option value={BuildingConstructionType.FireResistive}>
                                    Fire Resistive
                                  </option>
                                </Select>
                                {!!getError(['buildingInfo', 'constructionType']) && (
                                  <ErrorMessage>
                                    {getError(['buildingInfo', 'constructionType'])}
                                  </ErrorMessage>
                                )}
                              </Field>

                              <Field>
                                <Label>Ownership Type</Label>
                                <Select
                                  value={form.buildingInfo?.ownershipType || ''}
                                  invalid={!!getError(['buildingInfo', 'ownershipType'])}
                                  onChange={(e) => {
                                    const ownershipType = e.currentTarget.value;
                                    updateForm(['buildingInfo', 'ownershipType'], (prev) => ({
                                      ...prev,
                                      buildingInfo: {
                                        ...prev.buildingInfo,
                                        ownershipType: ownershipType as BuildingOwnershipType
                                      }
                                    }));
                                  }}
                                >
                                  <option value="" disabled></option>
                                  <option value={BuildingOwnershipType.Home}>Home</option>
                                  <option value={BuildingOwnershipType.Owned}>Owned</option>
                                  <option value={BuildingOwnershipType.Leased}>Leased</option>
                                </Select>
                                {!!getError(['buildingInfo', 'ownershipType']) && (
                                  <ErrorMessage>
                                    {getError(['buildingInfo', 'ownershipType'])}
                                  </ErrorMessage>
                                )}
                              </Field>

                              <Field>
                                <Label>Sprinklered Percentage</Label>
                                <InputGroup>
                                  <Input
                                    type="number"
                                    value={form.buildingInfo?.sprinkleredPercentage || ''}
                                    invalid={!!getError(['buildingInfo', 'sprinkleredPercentage'])}
                                    maxLength={3}
                                    max={100}
                                    min={0}
                                    onKeyDown={(e) =>
                                      e.key === 'ArrowUp' || e.key === 'ArrowDown'
                                        ? e.preventDefault()
                                        : null
                                    }
                                    onWheel={(e) => e.currentTarget.blur()}
                                    onChange={(e) => {
                                      const sprinkleredPercentage = e.currentTarget.value;
                                      updateForm(
                                        ['buildingInfo', 'sprinkleredPercentage'],
                                        (prev) => ({
                                          ...prev,
                                          buildingInfo: {
                                            ...prev.buildingInfo,
                                            sprinkleredPercentage
                                          }
                                        })
                                      );
                                    }}
                                  />

                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <span className="text-neutral-500 sm:text-sm">%</span>
                                  </div>
                                </InputGroup>
                                {!!getError(['buildingInfo', 'sprinkleredPercentage']) && (
                                  <ErrorMessage>
                                    {getError(['buildingInfo', 'sprinkleredPercentage'])}
                                  </ErrorMessage>
                                )}
                              </Field>

                              <Field>
                                <Label>Total Building Area</Label>
                                <InputGroup>
                                  <Input
                                    type="number"
                                    value={form.buildingInfo?.totalArea || ''}
                                    invalid={!!getError(['buildingInfo', 'totalArea'])}
                                    min={0}
                                    onKeyDown={(e) =>
                                      e.key === 'ArrowUp' || e.key === 'ArrowDown'
                                        ? e.preventDefault()
                                        : null
                                    }
                                    onWheel={(e) => e.currentTarget.blur()}
                                    onChange={(e) => {
                                      const totalArea = e.currentTarget.value;
                                      updateForm(['buildingInfo', 'totalArea'], (prev) => ({
                                        ...prev,
                                        buildingInfo: {
                                          ...prev.buildingInfo,
                                          totalArea
                                        }
                                      }));
                                    }}
                                  />

                                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                    <span className="text-neutral-500 sm:text-sm">square feet</span>
                                  </div>
                                </InputGroup>
                                {!!getError(['buildingInfo', 'totalArea']) && (
                                  <ErrorMessage>
                                    {getError(['buildingInfo', 'totalArea'])}
                                  </ErrorMessage>
                                )}
                              </Field>

                              <Field>
                                <Label>Total Stories</Label>
                                <Input
                                  type="number"
                                  value={form.buildingInfo?.totalStories || ''}
                                  invalid={!!getError(['buildingInfo', 'totalStories'])}
                                  min={0}
                                  onKeyDown={(e) =>
                                    e.key === 'ArrowUp' || e.key === 'ArrowDown'
                                      ? e.preventDefault()
                                      : null
                                  }
                                  onWheel={(e) => e.currentTarget.blur()}
                                  onChange={(e) => {
                                    const totalStories = e.currentTarget.value;
                                    updateForm(['buildingInfo', 'totalStories'], (prev) => ({
                                      ...prev,
                                      buildingInfo: {
                                        ...prev.buildingInfo,
                                        totalStories
                                      }
                                    }));
                                  }}
                                />
                                {!!getError(['buildingInfo', 'totalStories']) && (
                                  <ErrorMessage>
                                    {getError(['buildingInfo', 'totalStories'])}
                                  </ErrorMessage>
                                )}
                              </Field>

                              <Field>
                                <Label>Year Built</Label>
                                <Input
                                  type="number"
                                  className="sm:w-24"
                                  value={form.buildingInfo?.yearBuilt || ''}
                                  invalid={!!getError(['buildingInfo', 'yearBuilt'])}
                                  maxLength={4}
                                  min={1000}
                                  onKeyDown={(e) =>
                                    e.key === 'ArrowUp' || e.key === 'ArrowDown'
                                      ? e.preventDefault()
                                      : null
                                  }
                                  onWheel={(e) => e.currentTarget.blur()}
                                  onChange={(e) => {
                                    const yearBuilt = e.currentTarget.value;
                                    updateForm(['buildingInfo', 'yearBuilt'], (prev) => ({
                                      ...prev,
                                      buildingInfo: {
                                        ...prev.buildingInfo,
                                        yearBuilt
                                      }
                                    }));
                                  }}
                                />
                                {!!getError(['buildingInfo', 'yearBuilt']) && (
                                  <ErrorMessage>
                                    {getError(['buildingInfo', 'yearBuilt'])}
                                  </ErrorMessage>
                                )}
                              </Field>
                            </div>
                          </FieldGroup>
                        </Fieldset>
                      )}
                      {application?.insuranceTypes.includes(InsuranceType.BusinessOwners) && (
                        <Fieldset className="py-4">
                          <Legend>Operational Information</Legend>
                          <Text>Tell us about the operations tied to this location</Text>
                          <FieldGroup>
                            <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
                              <Field>
                                <Label>Annual Sales</Label>
                                <Description>
                                  Sales per year associated with this location
                                </Description>
                                <InputGroup>
                                  <CurrencyDollarIcon />
                                  <Input
                                    type="number"
                                    min={0}
                                    value={form.buildingInfo?.annualSales || ''}
                                    invalid={!!getError(['buildingInfo', 'annualSales'])}
                                    onKeyDown={(e) =>
                                      e.key === 'ArrowUp' || e.key === 'ArrowDown'
                                        ? e.preventDefault()
                                        : null
                                    }
                                    onWheel={(e) => e.currentTarget.blur()}
                                    onChange={(e) => {
                                      const annualSales = e.currentTarget.value;
                                      updateForm(['buildingInfo', 'annualSales'], (prev) => ({
                                        ...prev,
                                        buildingInfo: {
                                          ...prev.buildingInfo,
                                          annualSales
                                        }
                                      }));
                                    }}
                                  />
                                </InputGroup>
                                {!!getError(['buildingInfo', 'annualSales']) && (
                                  <ErrorMessage>
                                    {getError(['buildingInfo', 'annualSales'])}
                                  </ErrorMessage>
                                )}
                              </Field>

                              <Field>
                                <Label>Building</Label>
                                <Description>
                                  Coverage for the structure of the building
                                </Description>
                                <InputGroup>
                                  <CurrencyDollarIcon />
                                  <Input
                                    type="number"
                                    min={0}
                                    value={form.buildingInfo?.buildingCoverage || ''}
                                    invalid={!!getError(['buildingInfo', 'buildingCoverage'])}
                                    onKeyDown={(e) =>
                                      e.key === 'ArrowUp' || e.key === 'ArrowDown'
                                        ? e.preventDefault()
                                        : null
                                    }
                                    onWheel={(e) => e.currentTarget.blur()}
                                    onChange={(e) => {
                                      const buildingCoverage = e.currentTarget.value;
                                      updateForm(['buildingInfo', 'buildingCoverage'], (prev) => ({
                                        ...prev,
                                        buildingInfo: {
                                          ...prev.buildingInfo,
                                          buildingCoverage
                                        }
                                      }));
                                    }}
                                  />
                                </InputGroup>
                                {!!getError(['buildingInfo', 'buildingCoverage']) && (
                                  <ErrorMessage>
                                    {getError(['buildingInfo', 'buildingCoverage'])}
                                  </ErrorMessage>
                                )}
                              </Field>

                              <Field>
                                <Label>Contents</Label>
                                <Description>
                                  Covers business property inside, e.g. inventory, furniture, and
                                  equipment
                                </Description>
                                <InputGroup>
                                  <CurrencyDollarIcon />
                                  <Input
                                    type="number"
                                    min={0}
                                    value={form.buildingInfo?.personalPropertyCoverage || ''}
                                    invalid={
                                      !!getError(['buildingInfo', 'personalPropertyCoverage'])
                                    }
                                    onKeyDown={(e) =>
                                      e.key === 'ArrowUp' || e.key === 'ArrowDown'
                                        ? e.preventDefault()
                                        : null
                                    }
                                    onWheel={(e) => e.currentTarget.blur()}
                                    onChange={(e) => {
                                      const personalPropertyCoverage = e.currentTarget.value;
                                      updateForm(
                                        ['buildingInfo', 'personalPropertyCoverage'],
                                        (prev) => ({
                                          ...prev,
                                          buildingInfo: {
                                            ...prev.buildingInfo,
                                            personalPropertyCoverage
                                          }
                                        })
                                      );
                                    }}
                                  />
                                </InputGroup>
                                {!!getError(['buildingInfo', 'personalPropertyCoverage']) && (
                                  <ErrorMessage>
                                    {getError(['buildingInfo', 'personalPropertyCoverage'])}
                                  </ErrorMessage>
                                )}
                              </Field>
                            </div>
                          </FieldGroup>
                        </Fieldset>
                      )}
                      {application?.insuranceTypes.includes(InsuranceType.GeneralLiability) &&
                        !application?.insuranceTypes.includes(InsuranceType.BusinessOwners) && (
                          <Fieldset className="py-4">
                            <Legend>Operational Information</Legend>
                            <Text>Tell us about the operations tied to this location</Text>
                            <FieldGroup>
                              <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
                                <Field>
                                  <Label>Annual Sales</Label>
                                  <InputGroup>
                                    <CurrencyDollarIcon />
                                    <Input
                                      type="number"
                                      min={0}
                                      value={form.buildingInfo?.annualSales || ''}
                                      invalid={!!getError(['buildingInfo', 'annualSales'])}
                                      onKeyDown={(e) =>
                                        e.key === 'ArrowUp' || e.key === 'ArrowDown'
                                          ? e.preventDefault()
                                          : null
                                      }
                                      onWheel={(e) => e.currentTarget.blur()}
                                      onChange={(e) => {
                                        const annualSales = e.currentTarget.value;
                                        updateForm(['buildingInfo', 'annualSales'], (prev) => ({
                                          ...prev,
                                          buildingInfo: {
                                            ...prev.buildingInfo,
                                            annualSales
                                          }
                                        }));
                                      }}
                                    />
                                  </InputGroup>
                                  {!!getError(['buildingInfo', 'annualSales']) && (
                                    <ErrorMessage>
                                      {getError(['buildingInfo', 'annualSales'])}
                                    </ErrorMessage>
                                  )}
                                </Field>
                              </div>
                            </FieldGroup>
                          </Fieldset>
                        )}
                      {application?.insuranceTypes.includes(InsuranceType.WorkersCompensation) && (
                        <Fieldset className="py-4">
                          <Legend>Employee Information</Legend>
                          <Text>Tell us about the employees tied to this location</Text>
                          <FieldGroup>
                            <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-3">
                              <Field>
                                <Label>Full-Time Staff</Label>
                                <Input
                                  type="number"
                                  min={0}
                                  value={form.employeeInfo?.numFullTimeEmployees || ''}
                                  onKeyDown={(e) =>
                                    e.key === 'ArrowUp' || e.key === 'ArrowDown'
                                      ? e.preventDefault()
                                      : null
                                  }
                                  onWheel={(e) => e.currentTarget.blur()}
                                  onChange={(e) => {
                                    const numFullTimeEmployees = e.currentTarget.value;
                                    updateForm(
                                      ['employeeInfo', 'numFullTimeEmployees'],
                                      (prev) => ({
                                        ...prev,
                                        employeeInfo: {
                                          ...prev?.employeeInfo,
                                          numFullTimeEmployees
                                        }
                                      })
                                    );
                                  }}
                                  invalid={!!getError(['employeeInfo', 'numFullTimeEmployees'])}
                                />
                                {!!getError(['employeeInfo', 'numFullTimeEmployees']) && (
                                  <ErrorMessage>
                                    {getError(['employeeInfo', 'numFullTimeEmployees'])}
                                  </ErrorMessage>
                                )}
                              </Field>
                              <Field>
                                <Label>Part-Time Staff</Label>
                                <Input
                                  type="number"
                                  min={0}
                                  value={form.employeeInfo?.numPartTimeEmployees || ''}
                                  onKeyDown={(e) =>
                                    e.key === 'ArrowUp' || e.key === 'ArrowDown'
                                      ? e.preventDefault()
                                      : null
                                  }
                                  onWheel={(e) => e.currentTarget.blur()}
                                  onChange={(e) => {
                                    const numPartTimeEmployees = e.currentTarget.value;
                                    updateForm(
                                      ['employeeInfo', 'numPartTimeEmployees'],
                                      (prev) => ({
                                        ...prev,
                                        employeeInfo: {
                                          ...prev?.employeeInfo,
                                          numPartTimeEmployees
                                        }
                                      })
                                    );
                                  }}
                                  invalid={!!getError(['employeeInfo', 'numPartTimeEmployees'])}
                                />
                                {!!getError(['employeeInfo', 'numPartTimeEmployees']) && (
                                  <ErrorMessage>
                                    {getError(['employeeInfo', 'numPartTimeEmployees'])}
                                  </ErrorMessage>
                                )}
                              </Field>
                              <Field>
                                <Label>Total Payroll</Label>
                                <InputGroup>
                                  <CurrencyDollarIcon />
                                  <Input
                                    type="number"
                                    min={0}
                                    value={form.employeeInfo?.totalEmployeePayroll || ''}
                                    onKeyDown={(e) =>
                                      e.key === 'ArrowUp' || e.key === 'ArrowDown'
                                        ? e.preventDefault()
                                        : null
                                    }
                                    onWheel={(e) => e.currentTarget.blur()}
                                    onChange={(e) => {
                                      const totalEmployeePayroll = e.currentTarget.value;
                                      updateForm(
                                        ['employeeInfo', 'totalEmployeePayroll'],
                                        (prev) => ({
                                          ...prev,
                                          employeeInfo: {
                                            ...prev?.employeeInfo,
                                            totalEmployeePayroll
                                          }
                                        })
                                      );
                                    }}
                                    invalid={!!getError(['employeeInfo', 'totalEmployeePayroll'])}
                                  />
                                </InputGroup>
                                {!!getError(['employeeInfo', 'totalEmployeePayroll']) && (
                                  <ErrorMessage>
                                    {getError(['employeeInfo', 'totalEmployeePayroll'])}
                                  </ErrorMessage>
                                )}
                              </Field>
                              {/* <Field className="col-span-3">
                                <Label>Job Code</Label>
                                <Description>
                                  Choose the role that best describes these employees.
                                </Description>
                                <Combobox
                                  value={form.employeeInfo?.jobCode || ''}
                                  onChange={(jobCode) =>
                                    updateForm(['employeeInfo', 'jobCode'], (prev) => ({
                                      ...prev,
                                      employeeInfo: {
                                        ...prev?.employeeInfo,
                                        jobCode
                                      }
                                    }))
                                  }
                                  options={
                                    jobCodes?.map((jobCode) => ({
                                      label: jobCode.description,
                                      value: jobCode.id
                                    })) || []
                                  }
                                  error={getError(['employeeInfo', 'jobCode'])}
                                  disabled={loading}
                                />
                              </Field> */}
                            </div>
                          </FieldGroup>
                        </Fieldset>
                      )}
                    </div>
                    <div className="absolute bottom-0 w-full flex gap-2 flex-shrink-0 px-4 py-4 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-800 shadow">
                      <Button
                        onClick={discardChanges}
                        type="button"
                        color="white"
                        disabled={loading}
                      >
                        Discard
                      </Button>
                      <Button type="submit" color="sky" disabled={loading}>
                        Save Location
                      </Button>
                    </div>
                  </form>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>

      {!application?.locations?.length && (
        <div className="text-center py-8">
          <BuildingOffice2Icon className="h-12 w-12 text-neutral-300 mx-auto" />
          <h3 className="mt-2 text-sm font-semibold text-neutral-900">No locations added</h3>
          <p className="mt-1 text-sm text-neutral-500">Add a business location to continue.</p>
          <div className="mt-6 flex flex-col gap-2 justify-center sm:flex-row max-w-96 m-auto sm:max-w-full">
            <Button
              color="light"
              type="button"
              onClick={() => history.push(`/commercial/app/${application?.id}/business`)}
              disabled={loading}
            >
              Previous Page
            </Button>
            <Button color="white" onClick={addLocation} disabled={loading}>
              <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5" />
              Add a location
            </Button>
            <Button color="sky" onClick={addLocationFromMailingAddress} disabled={loading}>
              <ChevronDoubleRightIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5" />
              Add location using mailing address
            </Button>
          </div>
        </div>
      )}

      {!!application?.locations?.length && (
        <ul role="list" className="py-8 grid grid-cols-1 gap-4 sm:grid-cols-2">
          {application.locations.map((location, i) => (
            <li
              key={location.id}
              className={clsx([
                locationIndexHasError(i) && 'ring-1 ring-red-600',
                'col-span-1 flex flex-col justify-between divide-y divide-neutral-200 dark:divide-neutral-600 rounded-lg bg-white dark:bg-neutral-700 shadow'
              ])}
            >
              <div className="p-6">
                <div className="flex w-full justify-between space-x-6">
                  <div className="flex-1">
                    <h3 className="truncate text-sm font-medium mt-0 dark:text-white">
                      {location.address.line1}
                    </h3>
                    <p className="truncate mt-0 text-sm text-neutral-500 dark:text-neutral-400">
                      {location.address.city}, {location.address.zone}
                    </p>
                    {location.isPrimary && (
                      <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-2 py-0.5 mt-2 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        Primary Location
                      </span>
                    )}
                  </div>
                  <BuildingOffice2Icon className="h-12 w-12 text-neutral-300" />
                </div>

                {locationIndexHasError(i) && (
                  <span className="mt-2 text-xs/3  text-red-600">
                    Please correct errors before proceeding.
                  </span>
                )}
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-neutral-200 dark:divide-neutral-600">
                  <div className="flex w-0 flex-1">
                    <button
                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-neutral-900 dark:text-white hover:bg-primary-500 hover:text-white transition-all"
                      onClick={() => removeLocation(location.id)}
                      disabled={loading}
                    >
                      <TrashIcon aria-hidden="true" className="h-5 w-5 text-neutral-300" />
                      Remove
                    </button>
                  </div>
                  <div className="-ml-px flex w-0 flex-1">
                    <button
                      className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-neutral-900 dark:text-white hover:bg-primary-500 hover:text-white transition-all"
                      onClick={() => editLocation(location.id)}
                      disabled={loading}
                    >
                      <PencilSquareIcon aria-hidden="true" className="h-5 w-5 text-neutral-300" />
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </li>
          ))}
          <li className="col-span-1">
            <button
              className="w-full h-full p-8 rounded-lg border-2 border-dashed border-neutral-300 dark:border-neutral-700 text-center hover:border-neutral-400 dark:hover:border-neutral-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              onClick={addLocation}
            >
              <PlusIcon className="mx-auto h-12 w-12 text-neutral-500 dark:text-neutral-400" />
              <span className="block text-sm font-semibold text-neutral-500 dark:text-neutral-400">
                Add another location
              </span>
            </button>
          </li>
        </ul>
      )}

      {!!application?.locations?.length && (
        <div className="flex justify-center gap-4">
          <Button
            color="light"
            type="button"
            onClick={() => history.push(`/commercial/app/${application?.id}/business`)}
            disabled={loading}
          >
            Previous Page
          </Button>
          <Button
            color="sky"
            type="button"
            className="transition-all"
            onClick={onContinue}
            disabled={loading}
          >
            Save and continue
          </Button>
        </div>
      )}
    </PageWrapper>
  );
};

export const LocationsSkeleton = () => {
  return (
    <div>
      <div className="py-8 grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div className="col-span-1 flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700 rounded-lg border border-neutral-200 dark:border-neutral-800 animate-pulse">
          <div className="flex w-full justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="h-4 py-1 mt-0 w-3/4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
              <div className="h-2 py-1 mt-4 w-1/2 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
              <div className="h-2 py-1 mt-2 w-1/4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
            </div>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-neutral-200 dark:divide-neutral-800">
              <div className="flex w-0 flex-1">
                <div className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4">
                  <div className="h-4 py-1 mt-0 w-3/4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
                </div>
              </div>
              <div className="-ml-px flex w-0 flex-1">
                <div className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4">
                  <div className="h-4 py-1 mt-0 w-3/4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1 flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700 rounded-lg border border-neutral-200 dark:border-neutral-800 animate-pulse">
          <div className="flex w-full justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="h-4 py-1 mt-0 w-3/4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
              <div className="h-2 py-1 mt-4 w-1/2 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
              <div className="h-2 py-1 mt-2 w-1/4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
            </div>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-neutral-200 dark:divide-neutral-800">
              <div className="flex w-0 flex-1">
                <div className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4">
                  <div className="h-4 py-1 mt-0 w-3/4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
                </div>
              </div>
              <div className="-ml-px flex w-0 flex-1">
                <div className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4">
                  <div className="h-4 py-1 mt-0 w-3/4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Losses = () => {
  const history = useHistory();

  // Common state variables
  const { application, updateApplication, setError } = React.useContext(ApplicationContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<ValidationError[]>([]);

  // Define the form variables
  const [hasLosses, setHasLosses] = React.useState<'YES' | 'NO' | null>(null);
  const [form, setForm] = React.useState<
    {
      claimDate?: string;
      claimStatus?: string;
      lossDate?: string;
      lossDescription?: string;
      lossState?: string;
      lossType?: string;
      policyEffectiveDate?: string;
      policyExpirationDate?: string;
      policyType?: string;
      totalPaidAmount?: string;
      totalReservedAmount?: string;
    }[]
  >([]);

  React.useEffect(() => {
    if (application?.pastPolicyLosses?.length) {
      setHasLosses('YES');
      setForm(
        application.pastPolicyLosses.map((l) => ({
          claimDate: l.claimDate ? new Date(l.claimDate).toISOString().split('T')[0] : undefined,
          claimStatus: l.claimStatus || '',
          lossDate: l.lossDate ? new Date(l.lossDate).toISOString().split('T')[0] : undefined,
          lossDescription: l.lossDescription,
          lossState: l.lossState || '',
          lossType: l.lossType || '',
          policyEffectiveDate: l.policyEffectiveDate
            ? new Date(l.policyEffectiveDate).toISOString().split('T')[0]
            : undefined,
          policyExpirationDate: l.policyExpirationDate
            ? new Date(l.policyExpirationDate).toISOString().split('T')[0]
            : undefined,
          policyType: l.policyType || '',
          totalPaidAmount: (l.totalPaidAmount || '').toString(),
          totalReservedAmount: (l.totalReservedAmount || '').toString()
        }))
      );
    }
  }, [application]);

  React.useEffect(() => {
    if (hasLosses === 'YES' && form.length === 0) {
      setForm([{}]);
    }
  }, [hasLosses]);

  // Define the error fields that need to be validated on this page
  const errorFields = Array((application?.pastPolicyLosses?.length || 0) + 1)
    .fill(0)
    .flatMap((_, i) => [
      ['pastPolicyLosses', i.toString(), 'claimDate'],
      ['pastPolicyLosses', i.toString(), 'claimStatus'],
      ['pastPolicyLosses', i.toString(), 'lossDate'],
      ['pastPolicyLosses', i.toString(), 'lossDescription'],
      ['pastPolicyLosses', i.toString(), 'lossState'],
      ['pastPolicyLosses', i.toString(), 'lossType'],
      ['pastPolicyLosses', i.toString(), 'policyEffectiveDate'],
      ['pastPolicyLosses', i.toString(), 'policyExpirationDate'],
      ['pastPolicyLosses', i.toString(), 'policyType'],
      ['pastPolicyLosses', i.toString(), 'totalPaidAmount'],
      ['pastPolicyLosses', i.toString(), 'totalReservedAmount']
    ]);

  // Define an update handler for the form
  const updateFormMulti = (
    fieldPartsList: string[][],
    update: (prev: typeof form) => typeof form
  ) => {
    fieldPartsList.forEach((fieldParts) => {
      setValidationErrors((prev) =>
        prev.filter(
          (e) =>
            e.field.length !== fieldParts.length || !e.field.every((v, i) => v === fieldParts[i])
        )
      );
    });
    setForm((prev) => update(prev));
  };

  const updateForm = (fieldParts: string[], update: (prev: typeof form) => typeof form) =>
    updateFormMulti([fieldParts], update);

  // Get the error message for a specific field
  const getError = (fieldParts: string[]) =>
    validationErrors.find(
      (e) => e.field.length === fieldParts.length && e.field.every((v, i) => v === fieldParts[i])
    )?.message;

  const addLoss = () => updateForm([], (prev) => [...prev, {}]);

  const removeLoss = async (index: number) => {
    setValidationErrors([]);
    updateForm([], (prev) => prev.filter((_, i) => i !== index));
  };

  // Handle form submission, i.e. updating the application on the backend.
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await getMerchantGraphQLClient().mutate<UpdateBusinessApplicationMutation>({
        mutation: UpdateBusinessApplicationDocument,
        errorPolicy: 'all',
        variables: {
          id: application?.id,
          application: {
            pastPolicyLosses:
              hasLosses === 'YES'
                ? form.map((l) => ({
                    claimDate: l.claimDate || '',
                    claimStatus: l.claimStatus || undefined,
                    lossDate: l.lossDate || '',
                    lossDescription: l.lossDescription || '',
                    lossState: l.lossState || '',
                    lossType: l.lossType || undefined,
                    policyEffectiveDate: l.policyExpirationDate
                      ? yearsAgo(l.policyExpirationDate, 1)
                      : '',
                    policyExpirationDate: l.policyExpirationDate || '',
                    policyType: l.policyType || undefined,
                    totalPaidAmount: parseFloat((l.totalPaidAmount || '0').replace(/[$,]/g, '')),
                    totalReservedAmount: parseFloat(
                      (l.totalReservedAmount || '0').replace(/[$,]/g, '')
                    )
                  }))
                : []
          }
        }
      });

      const validationErrors =
        res.errors
          ?.filter((e) => !!e.extensions?.validationError)
          ?.map((e) => e.extensions?.validationError as ValidationError) || [];

      const noValidationErrorMatched = validationErrors.every(
        (e) =>
          !errorFields.some(
            (f) => f.length === e.field.length && f.every((v, i) => v === e.field[i])
          )
      );

      const otherErrors = res.errors?.filter((e) => !e.extensions?.validationError) || [];
      if (otherErrors.length > 0) {
        throw new ApolloError({ graphQLErrors: otherErrors });
      }

      setValidationErrors(validationErrors);
      updateApplication(res.data?.updateBusinessApplication || application);

      if (noValidationErrorMatched) {
        history.push(`/commercial/app/${application?.id}/carriers`);
      }
    } catch (e) {
      setError(e as ApolloError);
    } finally {
      setLoading(false);
    }
  };

  const yearsAgo = (d: string, n: number) => {
    const date = new Date(d);
    date.setFullYear(date.getFullYear() - n);
    return date.toISOString().split('T')[0];
  };

  return (
    <PageWrapper
      title="Past losses"
      description="To qualify for certain coverage, we need to understand any prior losses your business has had."
      progress={4 / 8}
      skeleton={<LossesSkeleton />}
    >
      <form onSubmit={onSubmit}>
        <Fieldset className="py-8">
          <FieldGroup>
            <Field>
              <Label>Have you had any losses in the last five years?</Label>
              <Description>
                Include all losses regardless of whether a claim was paid out by an insurance policy
              </Description>
              <RadioGroup value={hasLosses} onChange={setHasLosses} className="mt-2">
                <Radio
                  value="YES"
                  className="inline-block cursor-pointer focus:outline-none items-center justify-center rounded-md bg-white dark:bg-neutral-800 px-8 py-4 sm:px-6 sm:py-3 mr-2 sm:text-sm font-medium text-neutral-900 dark:text-white ring-1 ring-neutral-300 dark:ring-neutral-700 hover:bg-neutral-50 dark:hover:bg-neutral-700 data-[checked]:bg-primary-500 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-primary-500 data-[focus]:ring-offset-2 data-[checked]:hover:bg-primary-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                >
                  Yes
                </Radio>
                <Radio
                  value="NO"
                  className="inline-block cursor-pointer focus:outline-none items-center justify-center rounded-md bg-white dark:bg-neutral-800 px-8 py-4 sm:px-6 sm:py-3 mr-2 sm:text-sm font-medium text-neutral-900 dark:text-white ring-1 ring-neutral-300 dark:ring-neutral-700 hover:bg-neutral-50 dark:hover:bg-neutral-700 data-[checked]:bg-primary-500 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-primary-500 data-[focus]:ring-offset-2 data-[checked]:hover:bg-primary-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                >
                  No
                </Radio>
              </RadioGroup>
            </Field>
          </FieldGroup>
          {hasLosses === 'YES' &&
            form.map((loss, i) => (
              <FieldGroup>
                <div className="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-3">
                  <Field>
                    <Label>Policy Type</Label>
                    <Select
                      value={loss.policyType || ''}
                      invalid={!!getError(['pastPolicyLosses', i.toString(), 'policyType'])}
                      onChange={(e) => {
                        const policyType = e.currentTarget.value;
                        updateForm(['pastPolicyLosses', i.toString(), 'policyType'], (prev) =>
                          prev.map((loss, index) =>
                            i !== index
                              ? loss
                              : { ...loss, policyType: policyType as InsuranceType, lossType: '' }
                          )
                        );
                      }}
                    >
                      <option value="" disabled></option>
                      <option value={InsuranceType.BusinessOwners}>Business Owners</option>
                      <option value={InsuranceType.Cyber}>Cyber</option>
                      <option value={InsuranceType.GeneralLiability}>General Liability</option>
                      <option value={InsuranceType.WorkersCompensation}>
                        Workers Compensation
                      </option>
                    </Select>
                    {!!getError(['pastPolicyLosses', i.toString(), 'policyType']) && (
                      <ErrorMessage>
                        {getError(['pastPolicyLosses', i.toString(), 'policyType'])}
                      </ErrorMessage>
                    )}
                  </Field>

                  <Field>
                    <Label>Policy Expiration Date</Label>
                    <Input
                      type="date"
                      value={loss.policyExpirationDate}
                      invalid={
                        !!getError(['pastPolicyLosses', i.toString(), 'policyExpirationDate'])
                      }
                      onChange={(e) => {
                        const date = e.currentTarget.value;
                        updateForm(
                          ['pastPolicyLosses', i.toString(), 'policyExpirationDate'],
                          (prev) =>
                            prev.map((loss, index) =>
                              i !== index
                                ? loss
                                : {
                                    ...loss,
                                    policyExpirationDate: date
                                  }
                            )
                        );
                      }}
                    />
                    {!!getError(['pastPolicyLosses', i.toString(), 'policyExpirationDate']) && (
                      <ErrorMessage>
                        {getError(['pastPolicyLosses', i.toString(), 'policyExpirationDate'])}
                      </ErrorMessage>
                    )}
                  </Field>

                  <Field>
                    <Label>Loss Type</Label>
                    <Select
                      value={loss.lossType || ''}
                      invalid={!!getError(['pastPolicyLosses', i.toString(), 'lossType'])}
                      onChange={(e) => {
                        const lossType = e.currentTarget.value;
                        updateForm(['pastPolicyLosses', i.toString(), 'lossType'], (prev) =>
                          prev.map((loss, index) =>
                            i !== index
                              ? loss
                              : { ...loss, lossType: lossType as PastPolicyLossType }
                          )
                        );
                      }}
                      disabled={!loss.policyType}
                    >
                      <option value="" disabled></option>
                      {loss.policyType === InsuranceType.WorkersCompensation && (
                        <>
                          <option value={PastPolicyLossType.Indemnity}>Indemnity</option>
                          <option value={PastPolicyLossType.Medical}>Medical</option>
                          <option value={PastPolicyLossType.MedicalAndIndemnity}>
                            Medical and Indemnity
                          </option>
                          <option value={PastPolicyLossType.Other}>Other</option>
                        </>
                      )}
                      {loss.policyType === InsuranceType.GeneralLiability && (
                        <>
                          <option value={PastPolicyLossType.BodilyInjuryOther}>
                            Bodily Injury (Other)
                          </option>
                          <option value={PastPolicyLossType.EmployeePractices}>
                            Employee Practices
                          </option>
                          <option value={PastPolicyLossType.ErrorsAndOmissions}>
                            Errors and Omission
                          </option>
                          <option value={PastPolicyLossType.GeneralLiabilityProducts}>
                            General Liability (Products)
                          </option>
                          <option value={PastPolicyLossType.GlPropertyDamage}>
                            General Liability (Property Damage)
                          </option>
                          <option value={PastPolicyLossType.InlandMarine}>Inland Marine</option>
                          <option value={PastPolicyLossType.LiabilityMedicalPayments}>
                            Liability (Medical Payments)
                          </option>
                          <option value={PastPolicyLossType.Other}>Other</option>
                          <option value={PastPolicyLossType.PersonalInjury}>Personal Injury</option>
                          <option value={PastPolicyLossType.ProfessionalLiability}>
                            Professional Liability
                          </option>
                          <option value={PastPolicyLossType.SlipFallInside}>
                            Slip or Fall (Inside)
                          </option>
                          <option value={PastPolicyLossType.SlipFallOutside}>
                            Slip of Fall (Outside)
                          </option>
                        </>
                      )}
                      {loss.policyType === InsuranceType.BusinessOwners && (
                        <>
                          <option value={PastPolicyLossType.BodilyInjuryOther}>
                            Bodily Injury (Other)
                          </option>
                          <option value={PastPolicyLossType.EmployeePractices}>
                            Employee Practices
                          </option>
                          <option value={PastPolicyLossType.ErrorsAndOmissions}>
                            Errors and Omissions
                          </option>
                          <option value={PastPolicyLossType.Fire}>Fire</option>
                          <option value={PastPolicyLossType.FoodSpoilage}>Food Spoilage</option>
                          <option value={PastPolicyLossType.GeneralLiabilityProducts}>
                            General Liability (Products)
                          </option>
                          <option value={PastPolicyLossType.GlPropertyDamage}>
                            General Liability (Property Damage)
                          </option>
                          <option value={PastPolicyLossType.Hail}>Hail</option>
                          <option value={PastPolicyLossType.InlandMarine}>Inland Marine</option>
                          <option value={PastPolicyLossType.LiabilityMedicalPayments}>
                            Liability (Medical Payments)
                          </option>
                          <option value={PastPolicyLossType.Other}>Other</option>
                          <option value={PastPolicyLossType.PersonalInjury}>Personal Injury</option>
                          <option value={PastPolicyLossType.ProfessionalLiability}>
                            Professional Liability
                          </option>
                          <option value={PastPolicyLossType.PropertyDamageCollapse}>
                            Property Damage (Collapse)
                          </option>
                          <option value={PastPolicyLossType.PropertyDamageOther}>
                            Property Damage (Other)
                          </option>
                          <option value={PastPolicyLossType.SlipFallInside}>
                            Slip or Fall (Inside)
                          </option>
                          <option value={PastPolicyLossType.SlipFallOutside}>
                            Slip of Fall (Outside)
                          </option>
                          <option value={PastPolicyLossType.Theft}>Theft</option>
                          <option value={PastPolicyLossType.Vandalism}>Vandalism</option>
                          <option value={PastPolicyLossType.WaterNonWeatherRelated}>
                            Water (Non-Weather Related)
                          </option>
                          <option value={PastPolicyLossType.Windstorm}>Windstorm</option>
                        </>
                      )}
                      {loss.policyType === InsuranceType.Cyber && (
                        <>
                          <option value={PastPolicyLossType.BusinessInterruption}>
                            Business Interruption
                          </option>
                          <option value={PastPolicyLossType.ComputerFraud}>Computer Fraud</option>
                          <option value={PastPolicyLossType.ContingentBusinessInterruption}>
                            Contingent Business Interruption
                          </option>
                          <option value={PastPolicyLossType.CyberIncident}>Cyber Incident</option>
                          <option value={PastPolicyLossType.DigitalData}>Digital Data</option>
                          <option value={PastPolicyLossType.FundTransferFraud}>
                            Fund Transfer Fraud
                          </option>
                          <option value={PastPolicyLossType.MediaLiability}>Media Liability</option>
                          <option value={PastPolicyLossType.NetworkExtortion}>
                            Network Extortion
                          </option>
                          <option value={PastPolicyLossType.PaymentCard}>Payment Card</option>
                          <option value={PastPolicyLossType.PrivacyAndNetworkSecurity}>
                            Privacy and Network Security
                          </option>
                          <option value={PastPolicyLossType.RegulatoryProceeding}>
                            Regulatory Proceeding
                          </option>
                          <option value={PastPolicyLossType.SocialEngineeringFraud}>
                            Social Engineering Fraud
                          </option>
                          <option value={PastPolicyLossType.TechnologyErrorsAndOmissions}>
                            Technology Errors and Omissions
                          </option>
                        </>
                      )}
                    </Select>
                    {!!getError(['pastPolicyLosses', i.toString(), 'lossType']) && (
                      <ErrorMessage>
                        {getError(['pastPolicyLosses', i.toString(), 'lossType'])}
                      </ErrorMessage>
                    )}
                  </Field>

                  <Field>
                    <Label>Loss State</Label>
                    <Select
                      value={loss.lossState || ''}
                      invalid={!!getError(['pastPolicyLosses', i.toString(), 'lossState'])}
                      onChange={(e) => {
                        const lossState = e.currentTarget.value;
                        updateForm(['pastPolicyLosses', i.toString(), 'lossState'], (prev) =>
                          prev.map((loss, index) => (i !== index ? loss : { ...loss, lossState }))
                        );
                      }}
                    >
                      <option value="" disabled></option>
                      {states.map((state) => (
                        <option key={state.value} value={state.value}>
                          {state.name}
                        </option>
                      ))}
                    </Select>
                    {!!getError(['pastPolicyLosses', i.toString(), 'lossState']) && (
                      <ErrorMessage>
                        {getError(['pastPolicyLosses', i.toString(), 'lossState'])}
                      </ErrorMessage>
                    )}
                  </Field>

                  <Field>
                    <Label>Loss Date</Label>
                    <Input
                      type="date"
                      value={loss.lossDate}
                      invalid={!!getError(['pastPolicyLosses', i.toString(), 'lossDate'])}
                      onChange={(e) => {
                        const lossDate = e.currentTarget.value;
                        updateForm(['pastPolicyLosses', i.toString(), 'lossDate'], (prev) =>
                          prev.map((loss, index) => (i !== index ? loss : { ...loss, lossDate }))
                        );
                      }}
                    />
                    {!!getError(['pastPolicyLosses', i.toString(), 'lossDate']) && (
                      <ErrorMessage>
                        {getError(['pastPolicyLosses', i.toString(), 'lossDate'])}
                      </ErrorMessage>
                    )}
                  </Field>

                  <Field>
                    <Label>Claim Date</Label>
                    <Input
                      type="date"
                      value={loss.claimDate}
                      invalid={!!getError(['pastPolicyLosses', i.toString(), 'claimDate'])}
                      onChange={(e) => {
                        const claimDate = e.currentTarget.value;
                        updateForm(['pastPolicyLosses', i.toString(), 'claimDate'], (prev) =>
                          prev.map((loss, index) => (i !== index ? loss : { ...loss, claimDate }))
                        );
                      }}
                    />
                    {!!getError(['pastPolicyLosses', i.toString(), 'claimDate']) && (
                      <ErrorMessage>
                        {getError(['pastPolicyLosses', i.toString(), 'claimDate'])}
                      </ErrorMessage>
                    )}
                  </Field>

                  <Field>
                    <Label>Claim Status</Label>
                    <Select
                      value={loss.claimStatus || ''}
                      invalid={!!getError(['pastPolicyLosses', i.toString(), 'claimStatus'])}
                      onChange={(e) => {
                        const claimStatus = e.currentTarget.value;
                        updateForm(['pastPolicyLosses', i.toString(), 'claimStatus'], (prev) =>
                          prev.map((loss, index) =>
                            i !== index
                              ? loss
                              : { ...loss, claimStatus: claimStatus as PastPolicyLossClaimStatus }
                          )
                        );
                      }}
                    >
                      <option value="" disabled></option>
                      <option value={PastPolicyLossClaimStatus.Open}>Open</option>
                      <option value={PastPolicyLossClaimStatus.Closed}>Closed</option>
                    </Select>
                    {!!getError(['pastPolicyLosses', i.toString(), 'claimStatus']) && (
                      <ErrorMessage>
                        {getError(['pastPolicyLosses', i.toString(), 'claimStatus'])}
                      </ErrorMessage>
                    )}
                  </Field>

                  <Field>
                    <Label>Claim Amount</Label>
                    <InputGroup>
                      <CurrencyDollarIcon />
                      <Input
                        type="number"
                        min={0}
                        value={loss.totalPaidAmount}
                        invalid={!!getError(['pastPolicyLosses', i.toString(), 'totalPaidAmount'])}
                        onKeyDown={(e) =>
                          e.key === 'ArrowUp' || e.key === 'ArrowDown' ? e.preventDefault() : null
                        }
                        onWheel={(e) => e.currentTarget.blur()}
                        onChange={(e) => {
                          const amount = e.currentTarget.value;
                          updateForm(
                            ['pastPolicyLosses', i.toString(), 'totalPaidAmount'],
                            (prev) =>
                              prev.map((loss, index) =>
                                i !== index
                                  ? loss
                                  : {
                                      ...loss,
                                      totalPaidAmount: amount,
                                      totalReservedAmount: '0'
                                    }
                              )
                          );
                        }}
                      />
                    </InputGroup>
                    {!!getError(['pastPolicyLosses', i.toString(), 'totalPaidAmount']) && (
                      <ErrorMessage>
                        {getError(['pastPolicyLosses', i.toString(), 'totalPaidAmount'])}
                      </ErrorMessage>
                    )}
                  </Field>

                  <Field className="sm:col-span-2">
                    <Label>Loss Description</Label>
                    <Textarea
                      resizable={false}
                      value={loss.lossDescription}
                      invalid={!!getError(['pastPolicyLosses', i.toString(), 'lossDescription'])}
                      onChange={(e) => {
                        const lossDescription = e.currentTarget.value;
                        updateForm(['pastPolicyLosses', i.toString(), 'lossDescription'], (prev) =>
                          prev.map((loss, index) =>
                            i !== index ? loss : { ...loss, lossDescription }
                          )
                        );
                      }}
                    />
                    {!!getError(['pastPolicyLosses', i.toString(), 'lossDescription']) && (
                      <ErrorMessage>
                        {getError(['pastPolicyLosses', i.toString(), 'lossDescription'])}
                      </ErrorMessage>
                    )}
                  </Field>
                </div>

                <div className="mt-6 flex flex-col gap-2 sm:flex-row max-w-96 sm:max-w-full">
                  {form.length > 1 && (
                    <Button color="light" onClick={() => removeLoss(i)}>
                      <TrashIcon
                        aria-hidden="true"
                        className="-ml-1 -mr-0.5 h-5 w-5 text-neutral-400"
                      />
                      Remove loss
                    </Button>
                  )}
                  {i === form.length - 1 && (
                    <Button color="light" onClick={addLoss}>
                      <PlusIcon
                        aria-hidden="true"
                        className="-ml-1 -mr-0.5 h-5 w-5 text-neutral-400"
                      />
                      Save and add another loss
                    </Button>
                  )}
                </div>
              </FieldGroup>
            ))}
        </Fieldset>

        <div className="flex justify-center gap-4">
          <Button
            color="light"
            onClick={() => history.push(`/commercial/app/${application?.id}/locations`)}
            disabled={loading}
          >
            Previous Page
          </Button>
          <Button
            color="sky"
            type="submit"
            className="transition-all"
            disabled={loading || hasLosses === null}
          >
            Save and continue
          </Button>
        </div>
      </form>
    </PageWrapper>
  );
};

export const LossesSkeleton = () => {
  return (
    <div>
      <Fieldset className="py-8">
        <FieldGroup>
          <Field>
            <LabelSkeleton long />
            <DescriptionSkeleton long />
            <InputSkeleton short />
          </Field>
        </FieldGroup>
      </Fieldset>
    </div>
  );
};

export const CarrierSelection = () => {
  const history = useHistory();

  const { application, updateApplication, setError } = React.useContext(ApplicationContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<ValidationError[]>([]);

  // Define the form variables
  const [form, setForm] = React.useState<{
    selectedCarriers: BusinessApplicationCarrier[];
  }>({ selectedCarriers: [] });

  // Set the form values based on the application
  React.useEffect(() => {
    setForm({
      selectedCarriers: application?.selectedCarriers || []
    });
  }, [application]);

  // Define the error fields that need to be validated on this page
  const errorFields = [['selectedCarriers']];

  // Define an update handler for the form
  const updateFormMulti = (
    fieldPartsList: string[][],
    update: (prev: typeof form) => typeof form
  ) => {
    fieldPartsList.forEach((fieldParts) =>
      setValidationErrors((prev) =>
        prev.filter(
          (e) =>
            e.field.length !== fieldParts.length || !e.field.every((v, i) => v === fieldParts[i])
        )
      )
    );
    setForm((prev) => update(prev));
  };

  const updateForm = (fieldParts: string[], update: (prev: typeof form) => typeof form) =>
    updateFormMulti([fieldParts], update);

  // Get the error message for a specific field
  const getError = (fieldParts: string[]) =>
    validationErrors.find(
      (e) => e.field.length === fieldParts.length && e.field.every((v, i) => v === fieldParts[i])
    )?.message;

  // Handle form submission, i.e. updating the application on the backend.
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await getMerchantGraphQLClient().mutate<UpdateBusinessApplicationMutation>({
        mutation: UpdateBusinessApplicationDocument,
        errorPolicy: 'all',
        variables: {
          id: application?.id,
          application: {
            selectedCarriers: form.selectedCarriers
          }
        }
      });

      const validationErrors =
        res.errors
          ?.filter((e) => !!e.extensions?.validationError)
          ?.map((e) => e.extensions?.validationError as ValidationError) || [];

      const noValidationErrorMatched = validationErrors.every(
        (e) =>
          !errorFields.some(
            (f) => f.length === e.field.length && f.every((v, i) => v === e.field[i])
          )
      );

      const otherErrors = res.errors?.filter((e) => !e.extensions?.validationError) || [];
      if (otherErrors.length > 0) {
        throw new ApolloError({ graphQLErrors: otherErrors });
      }

      setValidationErrors(validationErrors);
      updateApplication(res.data?.updateBusinessApplication || application);

      if (noValidationErrorMatched) {
        history.push(`/commercial/app/${application?.id}/underwriting`);
      }
    } catch (e) {
      setError(e as ApolloError);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitWithNoQuotes = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!application) {
      return;
    }

    try {
      setLoading(true);

      const res = await getMerchantGraphQLClient().mutate<SubmitBusinessApplicationMutation>({
        mutation: SubmitBusinessApplicationDocument,
        errorPolicy: 'all',
        variables: {
          id: application.id
        }
      });

      if (res.data?.submitBusinessApplication) {
        updateApplication(res.data.submitBusinessApplication);
      }

      if (res.errors?.[0]?.message) {
        throw new ApolloError({ graphQLErrors: res.errors });
      }

      history.push(`/commercial/app/${application.id}/complete`);
    } catch (e) {
      setError(e as ApolloError);
    } finally {
      setLoading(false);
    }
  };

  const rates =
    application?.quotableCarriers?.length === undefined
      ? []
      : application?.insuranceTypes?.map((t) => ({
          name: getDisplayInsuranceType(t),
          id: t,
          description: insuranceTypes.find((i) => i.id === t)?.description || '',
          features: insuranceTypes.find((i) => i.id === t)?.coverages || commonCoverages,
          online: !!application?.quotableCarriers?.some((c) => c.supportedPolicies?.includes(t))
        })) || [];

  return (
    <PageWrapper
      title="Your coverage options"
      description={
        application?.quotableCarriers?.length === undefined
          ? "One moment please. We're verifying your information and consolidating the best coverage options for your business."
          : "Based on your information, we've found some initial coverage options. Choose the carriers you'd like a quote from to proceed."
      }
      progress={5 / 8}
      skeleton={<CarrierSelectionSkeleton />}
    >
      <div className="py-8">
        <div className="isolate mx-auto mt-6 grid grid-cols-1 gap-8 sm:grid-cols-2">
          {rates.map((quote) => (
            <div key={quote.id}>
              {quote.online && (
                <div className="py-6 px-8 bg-gradient-to-r from-primary-500 to-primary-400 rounded-t-3xl text-sm text-emerald-50">
                  <h5 className="font-semibold block">
                    <ShieldCheckIcon className="h-6 w-6 inline align-middle" />
                    <span className="pl-2 h-full align-middle">Great news! We found coverage.</span>
                  </h5>
                </div>
              )}
              {!quote.online && (
                <div className="py-6 px-8 bg-gradient-to-r from-neutral-500 to-neutral-400 dark:from-neutral-700 dark:to-neutral-600 rounded-t-3xl text-sm text-neutral-50">
                  <h5 className="font-semibold block">
                    <ShieldExclamationIcon className="h-6 w-6 inline align-middle" />
                    <span className="pl-2 h-full align-middle">Online coverage not available.</span>
                  </h5>
                </div>
              )}
              <div
                className={clsx(
                  !quote.online &&
                    'opacity-100 bg-neutral-100/50 ring-neutral-500/10 dark:bg-neutral-800',
                  quote.online &&
                    'bg-white ring-neutral-200 dark:bg-neutral-950 dark:ring-neutral-700',
                  'border border-t-0 rounded-b-3xl px-8 pt-3 pb-8 transition-all'
                )}
              >
                <h4
                  id={quote.id}
                  className="text-neutral-900 dark:text-white mt-3 text-lg font-medium transition-all"
                >
                  {quote.name}
                </h4>
                <p className="mt-1 text-sm text-neutral-500 dark:text-neutral-400">
                  {quote.description}
                </p>
                {quote.online && (
                  <ul
                    role="list"
                    className="mt-4 space-y-2 text-sm leading-6 text-neutral-700 dark:text-neutral-300"
                  >
                    {quote.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          aria-hidden="true"
                          className="h-6 w-5 flex-none text-primary-500"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                )}
                {!quote.online && (
                  <p className="mt-4 mb-1 text-sm text-neutral-700 dark:text-neutral-300">
                    We can't quote your business online, but we'll pair you with a dedicated
                    insurance expert to find you coverage after you submit your application.
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {!!application?.quotableCarriers?.length && (
        <form onSubmit={onSubmit}>
          <Fieldset>
            <Legend>Select carriers</Legend>
            <Text>
              By choosing more carriers, you'll increase the likelihood of getting a competitive
              quote. However, you may also need to answer more underwriting questions.
            </Text>
            <FieldGroup>
              <UnderwritingForm.CheckboxField
                label=""
                values={form.selectedCarriers}
                options={
                  application?.quotableCarriers?.map((c) => ({
                    displayText: c.name,
                    value: c.id
                  })) || []
                }
                onChange={() => {}}
                onChangeMulti={(selected) => {
                  updateForm(['selectedCarriers'], (prev) => ({
                    ...prev,
                    selectedCarriers: selected as BusinessApplicationCarrier[]
                  }));
                }}
                error={getError(['selectedCarriers'])}
              />
            </FieldGroup>
          </Fieldset>
          <div className="flex justify-center gap-4 mt-8">
            <Button
              color="light"
              disabled={loading}
              onClick={() => history.push(`/commercial/app/${application?.id}/losses`)}
            >
              Back to Application
            </Button>
            <Button color="sky" className="transition-all" type="submit" disabled={loading}>
              Save and continue
            </Button>
          </div>
        </form>
      )}

      {!application?.quotableCarriers?.length && (
        <form onSubmit={onSubmitWithNoQuotes}>
          <div className="flex justify-center gap-4 mt-8">
            <Button
              color="light"
              disabled={loading}
              onClick={() => history.push(`/commercial/app/${application?.id}/losses`)}
            >
              Back to Application
            </Button>
            <Button color="sky" className="transition-all" type="submit" disabled={loading}>
              Submit Application
            </Button>
          </div>
        </form>
      )}
    </PageWrapper>
  );
};

export const CarrierSelectionSkeleton = () => {
  return (
    <div>
      <div className="py-8">
        <div className="isolate mx-auto mt-6 grid grid-cols-1 gap-8 sm:grid-cols-2">
          <div className="ring-1 ring-neutral-200 dark:ring-neutral-700 rounded-3xl p-8 transition-all animate-pulse">
            <div
              className={clsx(
                'w-3/4 sm:w-48',
                'h-6 py-1 mt-3 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />
            <div
              className={clsx(
                'w-16',
                'mt-4 h-2 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />
            <div
              className={clsx(
                'w-48',
                'mt-2 h-12 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />
            <ul role="list" className="mt-4 space-y-3 text-sm leading-6 text-neutral-600">
              <div
                className={clsx(
                  'w-32',
                  'h-3 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
                )}
              />
              <div
                className={clsx(
                  'w-48',
                  'h-3 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
                )}
              />
              <div
                className={clsx(
                  'w-24',
                  'h-3 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
                )}
              />
              <div
                className={clsx(
                  'w-48',
                  'h-3 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
                )}
              />
            </ul>
          </div>
          <div className="ring-1 ring-neutral-200 dark:ring-neutral-700 rounded-3xl p-8 transition-all animate-pulse">
            <div
              className={clsx(
                'w-3/4 sm:w-48',
                'h-6 py-1 mt-3 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />
            <div
              className={clsx(
                'w-16',
                'mt-4 h-2 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />
            <div
              className={clsx(
                'w-48',
                'mt-2 h-12 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />
            <ul role="list" className="mt-4 space-y-3 text-sm leading-6 text-neutral-600">
              <div
                className={clsx(
                  'w-32',
                  'h-3 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
                )}
              />
              <div
                className={clsx(
                  'w-48',
                  'h-3 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
                )}
              />
              <div
                className={clsx(
                  'w-24',
                  'h-3 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
                )}
              />
              <div
                className={clsx(
                  'w-48',
                  'h-3 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
                )}
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UnderwritingProgress = (props: {
  steps: string[];
  currentStep: number;
  onClick: (step: number) => void;
}) => {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-4 md:space-y-0">
        {props.steps.map((step, i) => (
          <li
            key={step}
            role="button"
            className="md:flex-1 cursor-pointer"
            onClick={() => props.onClick(i)}
          >
            {i < props.currentStep ? (
              <span className="group flex flex-col border-l-4 border-primary-500 py-2 pl-4 hover:border-primary-700 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                <span className="text-sm font-medium dark:text-white">{step}</span>
              </span>
            ) : i === props.currentStep ? (
              <span
                aria-current="step"
                className="flex flex-col border-l-4 border-primary-500 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              >
                <span className="text-sm font-medium text-primary-500">{step}</span>
              </span>
            ) : (
              <span className="group flex flex-col border-l-4 border-neutral-200 dark:border-neutral-700 py-2 pl-4 hover:border-neutral-300 dark:hover:border-neutral-600 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                <span className="text-sm font-medium text-neutral-500 dark:text-neutral-400 group-hover:text-neutral-700 dark:group-hover:text-neutral-300">
                  {step}
                </span>
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

const MarkdownStyleContainer = styled.div`
  color: #4b5563;

  h1 {
    &:first-child {
      margin-top: 0;
      padding-top: 0;
    }

    font-size: 1.375rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1.125rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5 {
    font-size: 0.875rem;
  }
  h6 {
    font-size: 0.875rem;
  }
  p {
    font-size: 0.875rem;
    line-height: 1rem;
    margin: 0.5rem 0;
  }
`;

export const CarrierStatement = (props: { statements: UnderwritingStatement[] }) => {
  return (
    <div className="bg-neutral-300/20 p-8 border-8 border-transparent rounded-xl max-h-72 overflow-auto">
      <MarkdownStyleContainer>
        <ReactMarkdown>{props.statements.map((s) => s.markdown).join('\n\n')}</ReactMarkdown>
      </MarkdownStyleContainer>
    </div>
  );
};

const getCarrierName = (carrier: BusinessApplicationCarrier): string => {
  switch (carrier) {
    case BusinessApplicationCarrier.Amtrust:
      return 'AmTrust';
    case BusinessApplicationCarrier.Biberk:
      return 'BiBerk';
    case BusinessApplicationCarrier.Chubb:
      return 'Chubb';
    case BusinessApplicationCarrier.Cna:
      return 'CNA';
    case BusinessApplicationCarrier.Coalition:
      return 'Coalition';
    case BusinessApplicationCarrier.Coterie:
      return 'Coterie';
    case BusinessApplicationCarrier.Employers:
      return 'Employers';
    case BusinessApplicationCarrier.Gaig:
      return 'Great American';
    case BusinessApplicationCarrier.Guard:
      return 'Guard';
    case BusinessApplicationCarrier.Hiscox:
      return 'Hiscox';
    case BusinessApplicationCarrier.Libertymutual:
      return 'Liberty Mutual';
    case BusinessApplicationCarrier.Markel:
      return 'Markel';
    case BusinessApplicationCarrier.Nationwide:
      return 'Nationwide';
    case BusinessApplicationCarrier.Next:
      return 'Next';
    case BusinessApplicationCarrier.Progressive:
      return 'Progressive';
    case BusinessApplicationCarrier.Travelers:
      return 'Travelers';
  }
};

export const Underwriting = () => {
  const history = useHistory();

  // Common state variables
  const { application, updateApplication, setError } = React.useContext(ApplicationContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<ValidationError[]>([]);
  const [carrierIndex, setCarrierIndex] = React.useState(0);

  // Define the form variables
  const [form, setForm] = React.useState<{
    generalAnswers: { [key: string]: string | null | undefined };
    locationAnswers: { [key: string]: { [key: string]: string | null | undefined } };
  }>({ generalAnswers: {}, locationAnswers: {} });

  // Set the form values based on the application
  React.useEffect(() => {
    setForm({
      generalAnswers:
        application?.underwritingAnswers
          ?.filter((a) => !a.locationId)
          .reduce(
            (acc, answer) => ({
              ...acc,
              [answer.questionId]: answer.answer
            }),
            {}
          ) || {},
      locationAnswers:
        application?.underwritingAnswers
          ?.filter((a) => !!a.locationId)
          .reduce(
            (acc, answer) => ({
              ...acc,
              [answer.locationId || '']: {
                ...acc[answer.locationId || ''],
                [answer.questionId]: answer.answer
              }
            }),
            {}
          ) || {}
    });
  }, [application]);

  // Reset the scroll position when the carrier index changes
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [carrierIndex]);

  // Define the error fields that need to be validated on this page
  const errorFields =
    application?.underwritingQuestions.map((question) => ['underwritingQuestions', question.id]) ||
    [];

  // List of carriers to display questions for
  const carriers = [
    ...new Set(application?.underwritingQuestions.flatMap((question) => question.carriers))
  ].sort((a, b) => a.localeCompare(b));

  // Questions grouped by carriers
  const carrierQuestionGroups = carriers.map((carrier) => ({
    carrier: carrier,
    generalQuestions:
      application?.underwritingQuestions.filter(
        (question) => !question.locationId && question.carriers.includes(carrier)
      ) || [],
    locationQuestions:
      application?.locations
        .map((location) => ({
          location: location.address.line1,
          questions: application?.underwritingQuestions.filter(
            (question) => question.locationId === location.id && question.carriers.includes(carrier)
          )
        }))
        .filter((v) => !!v.questions?.length) || [],
    statements: application?.underwritingStatements.filter((s) => s.carrier === carrier) || []
  }));

  // Define an update handler for the form
  const updateFormMulti = (
    fieldPartsList: string[][],
    update: (prev: typeof form) => typeof form
  ) => {
    fieldPartsList.forEach((fieldParts) =>
      setValidationErrors((prev) =>
        prev.filter(
          (e) =>
            e.field.length !== fieldParts.length || !e.field.every((v, i) => v === fieldParts[i])
        )
      )
    );
    setForm((prev) => update(prev));
  };

  const updateForm = (fieldParts: string[], update: (prev: typeof form) => typeof form) =>
    updateFormMulti([fieldParts], update);

  // Get the error message for a specific field
  const getError = (questionId: string, locationId?: string) =>
    validationErrors.find(
      (e) =>
        e.field[0] === 'underwritingQuestions' &&
        (e.field[1] === questionId || (e.field[1] === locationId && e.field[2] === questionId))
    )?.message;

  const renderQuestion = (question: UnderwritingQuestion) => {
    const componentTypeMap = {
      [UnderwritingQuestionType.Date]: UnderwritingForm.DateField,
      [UnderwritingQuestionType.Radio]: UnderwritingForm.RadioField,
      [UnderwritingQuestionType.Email]: UnderwritingForm.EmailField,
      [UnderwritingQuestionType.Phonenumber]: UnderwritingForm.PhoneNumberField,
      [UnderwritingQuestionType.Number]: UnderwritingForm.NumberField,
      [UnderwritingQuestionType.Year]: UnderwritingForm.YearField,
      [UnderwritingQuestionType.Text]: UnderwritingForm.TextField,
      [UnderwritingQuestionType.Singleselectdropdown]: UnderwritingForm.SelectField,
      [UnderwritingQuestionType.Multiselectdropdown]: UnderwritingForm.MultiSelectField
    };

    // Check if the question type is present in the component type map
    if (!componentTypeMap[question.type]) {
      return null;
    }

    const updateGeneralForm = (value: string) =>
      updateForm(['underwritingQuestions', question.id], (prev) => ({
        ...prev,
        generalAnswers: {
          ...prev.generalAnswers,
          [question.id]: value
        }
      }));

    const updateLocationForm = (value: string) =>
      updateForm(['underwritingQuestions', question.id], (prev) => ({
        ...prev,
        locationAnswers: {
          ...prev.locationAnswers,
          [question.locationId || '']: {
            ...prev.locationAnswers[question.locationId || ''],
            [question.id]: value
          }
        }
      }));

    const updateGeneralFormMulti = (values: string[]) =>
      updateForm(['underwritingQuestions', question.id], (prev) => ({
        ...prev,
        generalAnswers: {
          ...prev.generalAnswers,
          [question.id]: values.filter((v) => !!v).join(':#:')
        }
      }));

    const updateLocationFormMulti = (values: string[]) =>
      updateForm(['underwritingQuestions', question.id], (prev) => ({
        ...prev,
        locationAnswers: {
          ...prev.locationAnswers,
          [question.locationId || '']: {
            ...prev.locationAnswers[question.locationId || ''],
            [question.id]: values.filter((v) => !!v).join(':#:')
          }
        }
      }));

    return React.createElement(componentTypeMap[question.type], {
      // Common props
      key: question.id,
      label: question.question,
      description: question.tooltip,
      error: getError(question.id),

      // Props for single-value fields
      value: question.locationId
        ? form.locationAnswers[question.locationId]?.[question.id] || undefined
        : form.generalAnswers[question.id] || undefined,
      onChange: question.locationId ? updateLocationForm : updateGeneralForm,

      // Props for multiple-value fields
      options: question.answerOptions || [],
      values: question.locationId
        ? form.locationAnswers[question.locationId]?.[question.id]?.split(':#:') || []
        : form.generalAnswers[question.id]?.split(':#:') || [],
      onChangeMulti: question.locationId ? updateLocationFormMulti : updateGeneralFormMulti
    });
  };

  const filterQuestion = (question: UnderwritingQuestion) => {
    // Question should be shown if there are no parent questions.
    if (!question.hasParentQuestion) {
      return true;
    }

    // Otherwise, evaluate the rules to determine if the question should be shown.
    // First find all questions that have a rule that references this question.
    const parentQuestions =
      application?.underwritingQuestions.filter((q) =>
        q.dependentQuestions?.some((d) => d.dependentQuestionIds.includes(question.id))
      ) || [];

    return parentQuestions.every((parentQuestion) => {
      const rules =
        parentQuestion.dependentQuestions?.filter((d) =>
          d.dependentQuestionIds.includes(question.id)
        ) || [];

      return (
        filterQuestion(parentQuestion) &&
        rules.some((rule) => {
          // If the parent condition is a direct dependency, return try
          if (rule.type === UnderwritingQuestionDependencyType.Direct) {
            return true;
          }

          // check if the rule is satisfied directly
          const answerForm = question.locationId
            ? form.locationAnswers[question.locationId]
            : form.generalAnswers;
          const parentAnswer = answerForm?.[parentQuestion.id]?.split(':#:') || [];
          const validAnswers = rule.condition?.split('|') || [];

          if (parentAnswer && validAnswers.some((answer) => parentAnswer.includes(answer))) {
            return true;
          }

          // check if the rule is satisfied by condition. make this more robust by actually
          // evaluating the condition, but requires backend implementation too.
          if (
            ['<', '>', '<=', '>=', '==', '&&', '||'].some(
              (op) => rule.condition && rule.condition.indexOf(op) >= 0
            )
          ) {
            return true;
          }

          return false;
        })
      );
    });
  };

  // Handle form submission, i.e. updating the application on the backend.
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await getMerchantGraphQLClient().mutate<UpdateBusinessApplicationMutation>({
        mutation: UpdateBusinessApplicationDocument,
        errorPolicy: 'all',
        variables: {
          id: application?.id,
          application: {
            underwritingAnswers: [
              ...Object.entries(form.generalAnswers)
                .filter(([, answer]) => !!answer)
                .map(([questionId, answer]) => ({
                  questionId,
                  answer
                })),
              ...Object.entries(form.locationAnswers).flatMap(([locationId, answers]) =>
                Object.entries(answers)
                  .filter(([, answer]) => !!answer)
                  .map(([questionId, answer]) => ({
                    questionId,
                    answer,
                    locationId
                  }))
              )
            ]
          }
        }
      });

      const validationErrors =
        res.errors
          ?.filter((e) => !!e.extensions?.validationError)
          ?.map((e) => e.extensions?.validationError as ValidationError) || [];

      const noValidationErrorMatched = validationErrors.every(
        (e) =>
          !errorFields.some(
            (f) => f.length === e.field.length && f.every((v, i) => v === e.field[i])
          )
      );

      const otherErrors = res.errors?.filter((e) => !e.extensions?.validationError) || [];
      if (otherErrors.length > 0) {
        throw new ApolloError({ graphQLErrors: otherErrors });
      }

      setValidationErrors(validationErrors);
      updateApplication(res.data?.updateBusinessApplication || application);

      if (noValidationErrorMatched) {
        history.push(`/commercial/app/${application?.id}/quote`);
      } else {
        // Set the carrier index of the first carrier that had an error
        setCarrierIndex(
          carrierQuestionGroups.findIndex(
            (c) =>
              c.generalQuestions.some((q) =>
                validationErrors.some((v) => v.field.at(-1) === q.id)
              ) ||
              c.locationQuestions.some((location) =>
                location.questions.some((q) =>
                  validationErrors.some((v) => v.field.at(-1) === q.id)
                )
              )
          )
        );
      }
    } catch (e) {
      setError(e as ApolloError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper
      title="Additional underwriting questions"
      description="Some carriers require us to ask additional underwriting questions. Please answer them to the best of your ability."
      progress={6 / 8}
      skeleton={<UnderwritingSkeleton />}
    >
      {carriers.length > 1 && (
        <div className="pt-8">
          <UnderwritingProgress
            steps={carriers.map(getCarrierName)}
            currentStep={carrierIndex}
            onClick={setCarrierIndex}
          />
        </div>
      )}
      <form onSubmit={onSubmit}>
        {!!carrierQuestionGroups[carrierIndex]?.generalQuestions?.length && (
          <Fieldset className="pt-8">
            <Legend>General Questions</Legend>
            <FieldGroup>
              {carrierQuestionGroups[carrierIndex].generalQuestions
                .filter(filterQuestion)
                .map((question) => renderQuestion(question))}
            </FieldGroup>
          </Fieldset>
        )}
        {carrierQuestionGroups[carrierIndex]?.locationQuestions.map((location) => (
          <Fieldset key={location.location} className="pt-8">
            <Legend>Questions for location: {location.location}</Legend>
            <FieldGroup>
              {location.questions
                .filter(filterQuestion)
                .map((question) => renderQuestion(question))}
            </FieldGroup>
          </Fieldset>
        ))}
        {!!carrierQuestionGroups[carrierIndex]?.statements.length && (
          <div className="pt-8">
            <CarrierStatement statements={carrierQuestionGroups[carrierIndex].statements} />
          </div>
        )}

        <div className="flex justify-center gap-4 pt-8">
          <Button
            color="light"
            onClick={() => history.push(`/commercial/app/${application?.id}/carriers`)}
            disabled={loading}
          >
            Back to Carriers
          </Button>
          {carrierIndex > 0 && carriers.length > 1 && (
            <Button
              color="light"
              onClick={() => setCarrierIndex((prev) => prev - 1)}
              disabled={loading}
            >
              Previous Page
            </Button>
          )}
          {carrierIndex < carriers.length - 1 && carriers.length > 1 && (
            <Button
              color="light"
              onClick={() => setCarrierIndex((prev) => prev + 1)}
              disabled={loading}
            >
              Next Page
            </Button>
          )}
          {carrierIndex === carriers.length - 1 && (
            <Button color="sky" className="transition-all" type="submit" disabled={loading}>
              Continue to Quote
            </Button>
          )}
        </div>
      </form>
    </PageWrapper>
  );
};

const UnderwritingSkeleton = () => {
  return (
    <div>
      <Fieldset className="py-8">
        <LegendSkeleton />
        <FieldGroup>
          <Field>
            <LabelSkeleton extraLong />
            <InputSkeleton short />
          </Field>
          <Field>
            <LabelSkeleton extraLong />
            <InputSkeleton short />
          </Field>
          <Field>
            <LabelSkeleton extraLong />
            <InputSkeleton short />
          </Field>
          <Field>
            <LabelSkeleton extraLong />
            <InputSkeleton short />
          </Field>
          <Field>
            <LabelSkeleton extraLong />
            <InputSkeleton short />
          </Field>
        </FieldGroup>
      </Fieldset>
    </div>
  );
};

const BindQuote = (props: {
  quote?: ApplicationQuoteFieldsFragment['quotes'][number];
  loading: boolean;
  error?: string;
  open: boolean;
  onClose: () => void;
  onBind: (
    paymentPlanId?: string,
    paymentMethod?: PaymentPlanPaymentMethodType
  ) => Promise<unknown>;
}) => {
  const [bindOptions, setBindOptions] = React.useState({
    paymentMethod: '',
    paymentPlanId: ''
  });

  React.useEffect(() => {
    setBindOptions({
      paymentMethod: '',
      paymentPlanId: ''
    });
  }, [props.quote]);

  const describePlan = (
    quote: ApplicationQuoteFieldsFragment['quotes'][number]['paymentPlans'][number]
  ) => {
    if (quote.installmentCount == 0) {
      return `Single payment of ${currencyFormatter.format(quote.totalAmount)} due today.`;
    }

    const cadance =
      quote.installmentFrequency === PaymentPlanInstallmentFrequency.Monthly
        ? 'monthly'
        : quote.installmentFrequency === PaymentPlanInstallmentFrequency.Quarterly
          ? 'quarterly'
          : 'annual';

    const firstInstallmentDate = quote.installmentDueAt
      ? new Date(quote.installmentDueAt).toLocaleDateString()
      : null;

    return `Down payment of ${currencyFormatter.format(quote.downAmount)} due today, followed by ${quote.installmentCount} ${cadance} payments of ${currencyFormatter.format(quote.installmentAmount)} starting ${firstInstallmentDate}.`;
  };

  const paymentPlansSorted =
    props.quote?.paymentPlans
      .filter((plan) => plan.paymentMethod === bindOptions.paymentMethod)
      .sort((a, b) => b.downAmount - a.downAmount) || [];

  const paymentPlans =
    paymentPlansSorted.length > 2
      ? [paymentPlansSorted.at(0)!, paymentPlansSorted.at(-1)!]
      : paymentPlansSorted;

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onBind(
      !!bindOptions.paymentPlanId && bindOptions.paymentPlanId !== 'NO_PAYMENT_PLAN'
        ? bindOptions.paymentPlanId
        : undefined,
      (bindOptions.paymentMethod as PaymentPlanPaymentMethodType) || undefined
    );
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} className="relative z-20">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-all duration-300 data-[closed]:opacity-0"
      />

      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-full sm:max-w-lg transform transition-all duration-300 data-[closed]:translate-x-full"
            >
              <div className="flex h-full flex-col overflow-y-scroll divide-neutral-200 bg-white dark:bg-neutral-900 shadow-xl">
                <div className="bg-primary-700 dark:bg-primary-800 px-4 py-6 sm:px-6">
                  <div className="flex items-center justify-between">
                    <DialogTitle className="text-base font-semibold leading-6 text-white m-0">
                      Bind Coverage
                    </DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={props.onClose}
                        className="relative rounded-md bg-primary-800 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                  <div className="mt-1">
                    <p className="text-sm text-primary-200">
                      Complete this form to finalize your coverage
                    </p>
                  </div>
                </div>
                {props.quote && (
                  <form onSubmit={onSubmit}>
                    <div className="relative flex-1 px-4 py-6 pb-12 sm:px-6">
                      <Heading level={2} className="text-2xl/8 dark:text-white">
                        {getDisplayInsuranceType(props.quote.policyType)}
                      </Heading>
                      <p className="text-neutral-700 dark:text-neutral-300 leading-normal">
                        Thank you for choosing Oyster, in partnership with{' '}
                        {getCarrierName(props.quote.carrier)}, for your{' '}
                        {getDisplayInsuranceType(props.quote.policyType)} coverage. Please review
                        the details below and confirm your selection.
                      </p>
                      <Fieldset className="mt-8">
                        <div data-slot="control" className="grid gap-4 grid-cols-2">
                          <Field data-slot="control">
                            <Label>Premium</Label>
                            <Text>{currencyFormatter.format(props.quote.premiumAmount)}</Text>
                          </Field>
                          <Field data-slot="control">
                            <Label>Total Amount</Label>
                            <Text>{currencyFormatter.format(props.quote.totalAmount)}</Text>
                          </Field>
                        </div>
                        {props.quote.type === BusinessApplicationQuoteType.Bridge && (
                          <FieldGroup>
                            <Field>
                              <Label>Payment</Label>
                              <Text>An agent will be in touch to review payment options.</Text>
                            </Field>
                          </FieldGroup>
                        )}
                        {props.quote.type === BusinessApplicationQuoteType.BindOnline &&
                          props.quote.paymentPlans.length > 0 && (
                            <FieldGroup>
                              <Field>
                                <Label>Payment Method</Label>
                                <Select
                                  className="w-full xs:w-1/2"
                                  value={bindOptions.paymentMethod}
                                  onChange={(e) => {
                                    const paymentMethod = e.currentTarget
                                      .value as PaymentPlanPaymentMethodType;
                                    setBindOptions((prev) => ({
                                      ...prev,
                                      paymentPlanId: '',
                                      paymentMethod
                                    }));
                                  }}
                                >
                                  <option value="" disabled></option>
                                  <option
                                    value={PaymentPlanPaymentMethodType.DirectDebit}
                                    disabled={
                                      !props.quote.paymentPlans.some(
                                        (p) =>
                                          p.paymentMethod ===
                                          PaymentPlanPaymentMethodType.DirectDebit
                                      )
                                    }
                                  >
                                    Direct Debit
                                  </option>
                                  <option
                                    value={PaymentPlanPaymentMethodType.ElectronicFundTransfer}
                                    disabled={
                                      !props.quote.paymentPlans.some(
                                        (p) =>
                                          p.paymentMethod ===
                                          PaymentPlanPaymentMethodType.ElectronicFundTransfer
                                      )
                                    }
                                  >
                                    EFT
                                  </option>
                                  <option
                                    value={PaymentPlanPaymentMethodType.CreditCard}
                                    disabled={
                                      !props.quote.paymentPlans.some(
                                        (p) =>
                                          p.paymentMethod ===
                                          PaymentPlanPaymentMethodType.CreditCard
                                      )
                                    }
                                  >
                                    Credit Card
                                  </option>
                                </Select>
                              </Field>
                              {!!paymentPlans.length && (
                                <Field>
                                  <Label>Payment Plan</Label>
                                  <RadioGroup
                                    data-slot="control"
                                    className="space-y-4"
                                    value={bindOptions.paymentPlanId}
                                    onChange={(e) =>
                                      setBindOptions((prev) => ({ ...prev, paymentPlanId: e }))
                                    }
                                  >
                                    {paymentPlans.map((plan) => (
                                      <Radio
                                        value={plan.paymentPlanId}
                                        aria-label={plan.description}
                                        aria-description={describePlan(plan)}
                                        className="group relative block cursor-pointer rounded-lg bg-white p-4 shadow-sm focus:outline-none border border-neutral-950/10 data-[hover]:border-neutral-950/20  data-[focus]:border-primary-500 data-[focus]:ring-2 data-[focus]:ring-primary-500 dark:bg-neutral-800 dark:border-white/10 dark:data-[hover]:border-white/20"
                                      >
                                        <span>
                                          <span className="flex items-center text-sm">
                                            <span
                                              aria-hidden="true"
                                              className="flex h-4 w-4 items-center justify-center rounded-full border border-neutral-300 dark:border-neutral-600 bg-white dark:bg-neutral-800 group-data-[checked]:border-transparent group-data-[checked]:bg-primary-500 group-data-[focus]:ring-2 group-data-[focus]:ring-primary-500 group-data-[focus]:ring-offset-2"
                                            >
                                              <span className="h-1.5 w-1.5 rounded-full bg-white dark:bg-neutral-300 group-data-[checked]:bg-neutral-100" />
                                            </span>
                                            <span className="ml-3 font-medium text-neutral-900 dark:text-white group-data-[checked]:text-primary-500">
                                              {plan.title}
                                            </span>
                                          </span>
                                          <div className="ml-[1.75rem] mt-1 text-neutral-500 dark:text-neutral-400 text-xs/4">
                                            {describePlan(plan)}
                                          </div>
                                        </span>
                                        <span
                                          aria-hidden="true"
                                          className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-primary-500"
                                        />
                                      </Radio>
                                    ))}
                                  </RadioGroup>
                                </Field>
                              )}
                            </FieldGroup>
                          )}
                        {!!props.quote && !props.quote.paymentPlans.length && (
                          <FieldGroup>
                            <Field>
                              <Label>Payment Plan</Label>
                              <RadioGroup
                                data-slot="control"
                                className="space-y-4"
                                value={bindOptions.paymentPlanId}
                                onChange={(e) =>
                                  setBindOptions((prev) => ({ ...prev, paymentPlanId: e }))
                                }
                              >
                                <Radio
                                  value="NO_PAYMENT_PLAN"
                                  aria-label="Pay in full"
                                  aria-description={`Single payment of ${currencyFormatter.format(props.quote.totalAmount)} due today.`}
                                  className="group relative block cursor-pointer rounded-lg bg-white p-4 shadow-sm focus:outline-none border border-neutral-950/10 data-[hover]:border-neutral-950/20  data-[focus]:border-primary-500 data-[focus]:ring-2 data-[focus]:ring-primary-500 dark:bg-neutral-800 dark:border-white/10 dark:data-[hover]:border-white/20"
                                >
                                  <span>
                                    <span className="flex items-center text-sm">
                                      <span
                                        aria-hidden="true"
                                        className="flex h-4 w-4 items-center justify-center rounded-full border border-neutral-300 dark:border-neutral-600 bg-white dark:bg-neutral-800 group-data-[checked]:border-transparent group-data-[checked]:bg-primary-500 group-data-[focus]:ring-2 group-data-[focus]:ring-primary-500 group-data-[focus]:ring-offset-2"
                                      >
                                        <span className="h-1.5 w-1.5 rounded-full bg-white dark:bg-neutral-300 group-data-[checked]:bg-neutral-100" />
                                      </span>
                                      <span className="ml-3 font-medium text-neutral-900 dark:text-white group-data-[checked]:text-primary-500">
                                        Pay in full
                                      </span>
                                    </span>
                                    <div className="ml-[1.75rem] mt-1 text-neutral-500 dark:text-neutral-400 text-xs/4">
                                      Single payment of{' '}
                                      {currencyFormatter.format(props.quote.totalAmount)} due today.
                                    </div>
                                  </span>
                                  <span
                                    aria-hidden="true"
                                    className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-primary-500"
                                  />
                                </Radio>
                              </RadioGroup>
                            </Field>
                          </FieldGroup>
                        )}
                      </Fieldset>
                      {props.quote.type === BusinessApplicationQuoteType.BindOnline && (
                        <p className="mt-8 text-xs text-neutral-500">
                          By clicking below and binding this policy, you agree to the terms laid out
                          in <a href="#">the quote proposal</a> and agree to make payments according
                          to the selected schedule. Payment amounts may differ slightly in the
                          carrier's system.
                        </p>
                      )}
                      {props.quote.type === BusinessApplicationQuoteType.Bridge && (
                        <p className="mt-8 text-xs text-neutral-500">
                          By clicking below and binding this policy, you agree to the terms laid out
                          in <a href="#">the quote proposal</a> and agree to make the required
                          payments for this policy. An agent will reach out to you to finalize the
                          policy details and payment schedule.
                        </p>
                      )}
                      <HeadlessButton
                        type="submit"
                        disabled={
                          (props.quote.type === BusinessApplicationQuoteType.BindOnline &&
                            !bindOptions.paymentPlanId) ||
                          props.loading
                        }
                        className={clsx(
                          'w-full bg-primary-500 text-white shadow-sm hover:bg-primary-50 hover:text-primary-700 hover:outline hover:outline-primary-700 cursor-pointer',
                          'font-heading transition-all',
                          'mt-4 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500',
                          'data-[disabled]:opacity-75 data-[disabled]:cursor-not-allowed data-[disabled]:hover:bg-primary-500 data-[disabled]:hover:text-white data-[disabled]:hover:outline-none data-[disabled]:focus-visible:outline-none',
                          props.loading && 'animate-pulse'
                        )}
                      >
                        Agree and Bind
                      </HeadlessButton>
                      {props.error && (
                        <div className="mt-4 text-red-600 text-sm/4">{props.error}</div>
                      )}
                    </div>
                  </form>
                )}
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export const Quote = () => {
  const history = useHistory();
  const { applicationId, application, updateApplication, getApplication, setError } =
    React.useContext(ApplicationContext);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [showProgress, setShowProgress] = React.useState(false);
  const [bindLoading, setBindLoading] = React.useState(false);
  const [bindError, setBindError] = React.useState<string>();
  const [bindQuote, setBindQuote] =
    React.useState<ApplicationQuoteFieldsFragment['quotes'][number]>();
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const onLoadLoop = async () => {
    setShowProgress(true);

    try {
      const res = await getApplication();
      if (res.state === BusinessApplicationState.Quoted) {
        updateApplication(res);
        return res;
      } else {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        return await onLoadLoop();
      }
    } catch (e) {
      setError(e as ApolloError);
    } finally {
      setShowProgress(false);
    }

    return null;
  };

  const onLoad = async () => {
    // Submit the application for quoting
    setLoading(true);
    updateApplication(undefined);

    try {
      const [res] = await Promise.all([
        getMerchantGraphQLClient().mutate<QuoteBusinessApplicationMutation>({
          mutation: QuoteBusinessApplicationDocument,
          errorPolicy: 'all',
          variables: {
            id: applicationId
          }
        }),
        new Promise((resolve) => setTimeout(resolve, 500))
      ]);

      if (res.errors?.[0]?.message) {
        throw new ApolloError({ graphQLErrors: res.errors });
      }

      if (res.data?.quoteBusinessApplication?.state) {
        updateApplication(res.data.quoteBusinessApplication);

        if (res.data.quoteBusinessApplication.state === BusinessApplicationState.Submitted) {
          history.push(`/commercial/app/${applicationId}/complete`);
        }

        if (res.data.quoteBusinessApplication?.state !== BusinessApplicationState.Quoted) {
          return await onLoadLoop();
        }

        return res.data.quoteBusinessApplication;
      }
    } catch (e) {
      setError(e as ApolloError);
    } finally {
      setLoading(false);
    }

    return null;
  };

  // Quote display criteria:
  const selectQuote = (quotes: ApplicationQuoteFieldsFragment['quotes']) => {
    // If there are any quotes that are in the binding state, return those
    const bindingQuote = quotes.find((q) => q.state === BusinessApplicationQuoteState.Binding);
    if (bindingQuote) {
      return {
        ...bindingQuote,
        bound: true,
        isOnline: bindingQuote.type === BusinessApplicationQuoteType.BindOnline
      };
    }

    const bindOnlineQuotes = quotes.filter(
      (q) => q.type === BusinessApplicationQuoteType.BindOnline && q.premiumAmount > 0
    );
    const bridgeQuotes = quotes.filter(
      (q) => q.type === BusinessApplicationQuoteType.Bridge && q.premiumAmount > 0
    );

    // Choose the cheapest quote for each type
    const bindOnlineQuote =
      bindOnlineQuotes.length === 0
        ? null
        : bindOnlineQuotes.reduce(
            (prev, curr) => (prev.totalAmount < curr.totalAmount ? prev : curr),
            bindOnlineQuotes[0]
          );

    const bridgeQuote =
      bridgeQuotes.length === 0
        ? null
        : bridgeQuotes.reduce(
            (prev, curr) => (prev.totalAmount < curr.totalAmount ? prev : curr),
            bridgeQuotes[0]
          );

    if (!bridgeQuote && bindOnlineQuote) {
      return { ...bindOnlineQuote, isOnline: true, bound: false };
    }

    if (!bindOnlineQuote && bridgeQuote) {
      return { ...bridgeQuote, isOnline: false, bound: false };
    }

    if (!!bridgeQuote && !!bindOnlineQuote) {
      const bindOnlineQuoteAmount = bindOnlineQuote.totalAmount;
      const bridgeQuoteAmount = bridgeQuote.totalAmount;

      // If the BIND_ONLINE is <= 15% more expensive than the BRIDGE quote, select the BIND_ONLINE quote.
      if (bindOnlineQuoteAmount <= bridgeQuoteAmount * 1.15) {
        return { ...bindOnlineQuote, isOnline: true, bound: false };
      }

      return { ...bridgeQuote, isOnline: false, bound: false };
    }

    return null;
  };

  const coverageTypes =
    application?.insuranceTypes.map((t) => ({
      name: getDisplayInsuranceType(t),
      id: t,
      features: insuranceTypes.find((i) => i.id === t)?.coverages || commonCoverages,
      quote: selectQuote(application?.quotes?.filter((q) => q.policyType === t) || [])
    })) || [];

  const onBind = async (paymentPlanId?: string, paymentMethod?: PaymentPlanPaymentMethodType) => {
    if (!application?.id || !bindQuote?.id) {
      return;
    }

    setBindLoading(true);

    try {
      const res = await getMerchantGraphQLClient().mutate<BindQuoteMutation>({
        mutation: BindQuoteDocument,
        errorPolicy: 'all',
        variables: {
          applicationId: application.id,
          quoteId: bindQuote.id,
          paymentPlanId,
          paymentMethod
        }
      });

      if (res.data?.bindQuote) {
        updateApplication(res.data.bindQuote);
      }

      if (res.errors?.[0]?.message) {
        setBindError(res.errors[0].message);
      } else {
        setOpen(false);
      }
    } catch (e) {
      setBindError((e as Error).message);
    } finally {
      setBindLoading(false);
    }
  };

  const onSubmitApplication = async () => {
    if (!application) {
      return;
    }

    try {
      setSubmitLoading(true);

      const res = await getMerchantGraphQLClient().mutate<SubmitBusinessApplicationMutation>({
        mutation: SubmitBusinessApplicationDocument,
        errorPolicy: 'all',
        variables: {
          id: application.id
        }
      });

      if (res.data?.submitBusinessApplication) {
        updateApplication(res.data.submitBusinessApplication);
      }

      if (res.errors?.[0]?.message) {
        throw new ApolloError({ graphQLErrors: res.errors });
      }

      history.push(`/commercial/app/${application.id}/complete`);
    } catch (e) {
      setError(e as ApolloError);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <PageWrapper
      title="Your quote"
      description={
        application?.state === BusinessApplicationState.Quoted
          ? "Based on your information, here are the best options we found. Please confirm each policy you'd like to bind."
          : "Thank you for submitting your information. We're generating your final quotes now."
      }
      loading={loading}
      progress={7 / 8}
      loader={onLoad}
      skeleton={
        <>
          <TransitionGroup>
            {showProgress && (
              <CSSTransition timeout={500} classNames="fade-500">
                <QuoteProgress />
              </CSSTransition>
            )}
          </TransitionGroup>
          <QuoteSkeleton />
        </>
      }
    >
      <BindQuote
        quote={bindQuote}
        loading={bindLoading}
        error={bindError}
        open={open}
        onClose={() => setOpen(false)}
        onBind={onBind}
      />

      {coverageTypes.some((coverage) => !!coverage.quote?.bound) && (
        <div className="rounded-xl bg-blue-50 dark:bg-primary-950 p-6 mt-8 border border-blue-100 dark:border-primary-900">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-blue-400" />
            </div>
            <div className="ml-3 flex flex-col gap-2">
              <p className="text-sm text-neutral-800 dark:text-neutral-200">
                Your bind has been recorded, but you still need to submit your application for your
                coverage to be confirmed by our team.
              </p>
              {coverageTypes.some((coverage) => !coverage.quote) && (
                <p className="text-sm text-neutral-800 dark:text-neutral-200">
                  Since not all coverage could be found online, we'll also match you with a
                  dedicated specialist to find the remaining coverage after you finish and submit
                  your application.
                </p>
              )}
            </div>
          </div>
        </div>
      )}

      {coverageTypes.some((coverage) => !coverage.quote) &&
        !coverageTypes.some((coverage) => !!coverage.quote?.bound) && (
          <div className="rounded-xl bg-blue-50 dark:bg-primary-950 p-6 mt-8 border border-blue-100 dark:border-primary-900">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-blue-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-neutral-800 dark:text-neutral-200">
                  Since not all coverage could be found online, we'll match you with a dedicated
                  specialist to find the remaining coverage after you finish and submit your
                  application.
                </p>
              </div>
            </div>
          </div>
        )}

      <div className="py-8">
        <div className="isolate mx-auto grid grid-cols-1 sm:grid-cols-2 gap-8">
          {coverageTypes.map((coverage) => (
            <div
              key={coverage.id}
              className={clsx(
                !coverage.quote && 'bg-neutral-100 dark:bg-neutral-700',
                coverage.quote && 'bg-white dark:bg-neutral-800',
                'ring-1 ring-neutral-200 dark:ring-neutral-700 rounded-3xl p-8 transition-all'
              )}
            >
              <h3 className="mt-2 mb-0 text-sm font-semibold text-primary-500">
                {coverage.quote?.carrier ? getCarrierName(coverage.quote.carrier) : 'Not available'}
              </h3>
              <h4 className="mt-0 text-xl font-medium transition-all dark:text-white">
                {coverage.name}
              </h4>
              {!!coverage.quote && (
                <>
                  <p className="mt-2 flex items-baseline gap-x-1">
                    <span className="text-4xl font-semibold font-heading tracking-tight text-neutral-900 dark:text-neutral-50">
                      {new Intl.NumberFormat(undefined, {
                        style: 'currency',
                        currency: 'usd',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      }).format(coverage.quote.premiumAmount)}
                    </span>
                    <span className="text-sm leading-6 text-neutral-500 dark:text-neutral-400">
                      /year
                    </span>
                  </p>
                  <p className="m-0 leading-none">
                    <span className="text-xs text-neutral-500 dark:text-neutral-400">
                      +
                      {new Intl.NumberFormat(undefined, {
                        style: 'currency',
                        currency: 'usd',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2
                      }).format(coverage.quote.totalAmount - coverage.quote.premiumAmount)}{' '}
                      in fees and taxes
                    </span>
                  </p>
                </>
              )}
              {!!coverage.quote && (
                <ul
                  role="list"
                  className="mt-6 space-y-1.5 text-sm leading-6 text-neutral-600 dark:text-neutral-300"
                >
                  {coverage.features.map((feature) => (
                    <li key={feature} className="flex gap-x-2">
                      <CheckIcon
                        aria-hidden="true"
                        className="h-6 w-5 flex-none text-primary-500"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              )}
              {!!coverage.quote?.bound && (
                <button
                  aria-describedby={coverage.name}
                  disabled
                  className={clsx(
                    'w-full bg-green-600/60 text-white shadow-sm cursor-not-allowed',
                    'font-heading transition-all',
                    'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6',
                    'flex items-center justify-center gap-1'
                  )}
                >
                  <span>Bind confirmed</span>
                  <span>
                    <CheckCircleIcon aria-hidden="true" className="h-4 w-4" />
                  </span>
                </button>
              )}
              {!coverage.quote?.bound && !!coverage.quote?.isOnline && (
                <HeadlessButton
                  aria-describedby={coverage.name}
                  onClick={() => {
                    setBindQuote(coverage.quote!);
                    setOpen(true);
                  }}
                  disabled={bindLoading || submitLoading}
                  className={clsx(
                    'w-full bg-primary-500 text-white shadow-sm hover:bg-primary-50 hover:text-primary-700 hover:outline hover:outline-primary-700 cursor-pointer',
                    'font-heading transition-all',
                    'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500',
                    'data-[disabled]:opacity-75 data-[disabled]:cursor-not-allowed data-[disabled]:hover:bg-primary-500 data-[disabled]:hover:text-white data-[disabled]:hover:outline-none data-[disabled]:focus-visible:outline-none'
                  )}
                >
                  Bind Now
                </HeadlessButton>
              )}
              {!!coverage.quote && !coverage.quote.bound && !coverage.quote.isOnline && (
                <HeadlessButton
                  aria-describedby={coverage.name}
                  onClick={() => {
                    setBindQuote(coverage.quote!);
                    setOpen(true);
                  }}
                  disabled={bindLoading || submitLoading}
                  className={clsx(
                    'w-full bg-primary-500 text-white shadow-sm hover:bg-primary-50 hover:text-primary-700 hover:outline hover:outline-primary-700 cursor-pointer',
                    'font-heading transition-all',
                    'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500',

                    'data-[disabled]:opacity-75 data-[disabled]:cursor-not-allowed data-[disabled]:hover:bg-primary-500 data-[disabled]:hover:text-white data-[disabled]:hover:outline-none data-[disabled]:focus-visible:outline-none'
                  )}
                >
                  Bind with Agent
                </HeadlessButton>
              )}
              {!coverage.quote && (
                <div className="flex flex-col justify-between">
                  <p className="mt-1 text-sm text-neutral-500 dark:text-neutral-400">
                    We can't quote your business online, but we'll pair you with a dedicated
                    insurance expert to find you coverage after you finish and submit your
                    application.
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <form onSubmit={(e) => e.preventDefault()}>
        <div className="flex justify-center gap-4">
          <Button
            color="light"
            disabled={bindLoading || submitLoading}
            onClick={() => history.push(`/commercial/app/${application?.id}/underwriting`)}
          >
            Back to Underwriting
          </Button>
          <Button
            color="sky"
            className="transition-all"
            type="submit"
            disabled={bindLoading || submitLoading}
            onClick={onSubmitApplication}
          >
            Finish and Submit
          </Button>
        </div>
      </form>
    </PageWrapper>
  );
};

const QuoteSkeleton = () => {
  return (
    <>
      <div className="py-8">
        <div className="isolate mx-auto grid grid-cols-1 gap-8 sm:grid-cols-2">
          <div className="ring-1 ring-neutral-200 dark:ring-neutral-700 rounded-3xl p-8 transition-all animate-pulse">
            <div
              className={clsx(
                'w-1/3',
                'h-3 py-1 mt-2 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />
            <div
              className={clsx(
                'w-full sm:w-full',
                'h-6 py-1 mt-2 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />
            <div
              className={clsx(
                'w-48',
                'mt-4 h-10 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />
            <div
              className={clsx(
                'w-36',
                'mt-1 h-3 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />

            <ul role="list" className="mt-6 space-y-3 text-sm leading-6 text-neutral-600">
              <div
                className={clsx(
                  'w-32',
                  'h-4 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
                )}
              />
              <div
                className={clsx(
                  'w-48',
                  'h-4 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
                )}
              />
              <div
                className={clsx(
                  'w-24',
                  'h-4 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
                )}
              />
            </ul>
            <div
              className={clsx(
                'w-full',
                'mt-7 h-10 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />
          </div>
          <div className="ring-1 ring-neutral-200 dark:ring-neutral-700 rounded-3xl p-8 transition-all animate-pulse">
            <div
              className={clsx(
                'w-1/3',
                'h-3 py-1 mt-2 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />
            <div
              className={clsx(
                'w-full sm:w-full',
                'h-6 py-1 mt-2 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />
            <div
              className={clsx(
                'w-48',
                'mt-4 h-10 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />
            <div
              className={clsx(
                'w-36',
                'mt-1 h-3 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />

            <ul role="list" className="mt-6 space-y-3 text-sm leading-6 text-neutral-600">
              <div
                className={clsx(
                  'w-32',
                  'h-4 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
                )}
              />
              <div
                className={clsx(
                  'w-48',
                  'h-4 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
                )}
              />
              <div
                className={clsx(
                  'w-24',
                  'h-4 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
                )}
              />
            </ul>
            <div
              className={clsx(
                'w-full',
                'mt-7 h-10 py-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const QuoteProgress = () => {
  // Build a two-minute animation to keep the user engaged while the quote loads
  const steps = [
    { title: 'Verifying application data...', maxDuration: 2500, maxWidth: 4 },
    { title: 'Submitting application...', maxDuration: 4000, maxWidth: 11 },
    { title: 'Waiting for carriers...', maxDuration: 6000, maxWidth: 22 },
    { title: 'Checking carrier status...', maxDuration: 10000, maxWidth: 34 },
    { title: 'Gathering quotes...', maxDuration: 6000, maxWidth: 48 },
    { title: 'Gathering quotes...', maxDuration: 4000, maxWidth: 62 },
    { title: 'Gathering quotes...', maxDuration: 8000, maxWidth: 72 },
    { title: 'Gathering quotes...', maxDuration: 6000, maxWidth: 81 },
    { title: 'Gathering quotes...', maxDuration: 4000, maxWidth: 88 },
    { title: 'Gathering quotes...', maxDuration: 16000, maxWidth: 93 },
    { title: 'Finalizing...', maxDuration: 30000, maxWidth: 100 }
  ];

  const [stepIndex, setStepIndex] = React.useState(0);
  React.useEffect(() => {
    if (stepIndex === steps.length) {
      return;
    }

    const timeout = setTimeout(() => {
      setStepIndex((prev) => (prev >= steps.length - 1 ? prev : prev + 1));
    }, steps[stepIndex].maxDuration); // add jitter

    return () => clearTimeout(timeout);
  }, [stepIndex]);

  return (
    <>
      <div className="mt-8 bg-white ring-1 ring-neutral-200 dark:bg-neutral-800 dark:ring-neutral-700 rounded-xl">
        <div className="px-4 py-5 sm:p-6">
          <span className="text-base font-heading font-semibold leading-6 text-neutral-900 dark:text-white">
            Getting your quotes
          </span>
          <p className="text-sm mt-2 text-neutral-500 dark:text-neutral-400">
            Hang tight and we'll have your quotes ready in just a moment. Please don't navigate away
            from the page.
          </p>
          <div className="mt-6 text-sm font-medium text-primary-600 dark:text-primary-400">
            {steps[stepIndex].title}
          </div>
          <div className="mt-2">
            <div className="h-2 w-full rounded-full bg-neutral-100 dark:bg-neutral-700">
              <div
                className={`h-2 rounded-full bg-gradient-to-r to-primary-300 from-primary-600 bg-[size:200%_100%] animate-progress-bar transition-all ease-in-out duration-1000`}
                style={{ width: `${steps[stepIndex].maxWidth}%` }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Complete = () => {
  const history = useHistory();
  const { application, loadApplication, setError } = React.useContext(ApplicationContext);
  const [loading, setLoading] = React.useState(false);

  const onLoad = async () => {
    const res = await loadApplication();
    if (!!res && res.state !== BusinessApplicationState.Submitted) {
      history.push(`/commercial/app/${res.id}`);
    }
    if (!res) {
      history.push('/commercial');
    }

    return res;
  };

  const onComplete = async () => {
    setLoading(true);
    try {
      await merchantUserSignInInit(application?.contact.email || '');
      history.push(`/signin?email=${encodeURIComponent(application?.contact.email || '')}`);
    } catch (e) {
      setError(new ApolloError({ graphQLErrors: [{ message: (e as Error).message }] }));
    } finally {
      setLoading(false);
    }
  };

  const bindingQuotes =
    application?.quotes.filter((q) => q.state === BusinessApplicationQuoteState.Binding) || [];
  const pendingCoverages =
    application?.insuranceTypes.filter(
      (i) => !application.quotes.some((q) => q.policyType === i)
    ) || [];

  return (
    <PageWrapper
      title="Application complete"
      description="Your application has been submitted and is now being reviewed by our team."
      progress={8 / 8}
      loader={onLoad}
      skeleton={<CompleteSkeleton />}
    >
      <div className="pt-4">
        {bindingQuotes.length > 0 && (
          <div className="mt-4 bg-white ring-1 ring-neutral-200 dark:bg-neutral-800 dark:ring-neutral-700 rounded-xl">
            <div className="px-4 py-5 sm:p-6">
              <span className="text-base font-heading font-semibold leading-6 text-neutral-900 dark:text-white">
                Your quotes
              </span>
              <p className="text-sm mt-2 text-neutral-500 dark:text-neutral-400">
                You've successfully submitted your application. Our team is now reviewing your
                quotes and will reach out to you shortly. Remember that you've agreed to pay for the
                below policies.
              </p>
              <ul className="mt-4 grid grid-cols-1 gap-4">
                {bindingQuotes.map((quote) => (
                  <li key={quote.id}>
                    <span className="flex flex-row items-center gap-2">
                      <span className="font-medium">
                        {getDisplayInsuranceType(quote.policyType)}
                      </span>
                      <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-2 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        Binding
                      </span>
                    </span>
                    <span className="block text-sm">
                      {currencyFormatter.format(quote.totalAmount)}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {pendingCoverages.length > 0 && (
          <div className="mt-4 bg-white ring-1 ring-neutral-200 dark:bg-neutral-800 dark:ring-neutral-700 rounded-xl">
            <div className="px-4 py-5 sm:p-6">
              <span className="text-base font-heading font-semibold leading-6 text-neutral-900 dark:text-white">
                Other coverages
              </span>
              <p className="text-sm mt-2 text-neutral-500 dark:text-neutral-400">
                Since we couldn't find all the coverage you need online, we'll pair you with a
                complementary dedicated insurance specialist to find the remaining coverages for
                you.
              </p>
              <ul className="mt-4 grid grid-cols-1 gap-4">
                {pendingCoverages.map((coverage) => (
                  <li key={coverage}>
                    <span className="flex flex-row items-center gap-2">
                      <span className="font-medium">{getDisplayInsuranceType(coverage)}</span>
                      <span className="inline-flex flex-shrink-0 items-center rounded-full bg-neutral-50 px-2 py-0.5 text-xs font-medium text-neutral-700 ring-1 ring-inset ring-neutral-600/20">
                        Not found online
                      </span>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <div className="mt-4 bg-white ring-1 ring-neutral-200 dark:bg-neutral-800 dark:ring-neutral-700 rounded-xl">
          <div className="px-4 py-5 sm:p-6">
            <span className="text-base font-heading font-semibold leading-6 text-neutral-900 dark:text-white">
              Next steps
            </span>
            <p className="text-sm mt-2 text-neutral-500 dark:text-neutral-400">
              An Oyster team member will reach out to your shortly. In the meantime, we've created a
              free Oyster account where you can manage your application, policies, and access our
              other risk management tools.
            </p>
            <div className="mt-4">
              <Button
                color="sky"
                className="transition-all"
                type="submit"
                disabled={loading}
                onClick={onComplete}
              >
                Continue to Dashboard
              </Button>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

const CompleteSkeleton = () => {
  return (
    <div className="mt-4">
      <div className="mt-4 ring-1 ring-neutral-200 dark:ring-neutral-700 rounded-xl px-4 py-5 sm:p-6 transition-all animate-pulse">
        <div
          className={clsx(
            'w-1/3',
            'h-4 py-1 mt-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
          )}
        />
        <div
          className={clsx(
            'w-full',
            'h-3 py-1 mt-4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
          )}
        />
        <div
          className={clsx(
            'w-1/2',
            'h-3 py-1 mt-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
          )}
        />
        <div
          className={clsx(
            'w-40',
            'h-5 py-1 mt-6 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
          )}
        />
        <div
          className={clsx(
            'w-16',
            'h-3 py-1 mt-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
          )}
        />
      </div>

      <div className="mt-4 ring-1 ring-neutral-200 dark:ring-neutral-700 rounded-xl px-4 py-5 sm:p-6 transition-all animate-pulse">
        <div
          className={clsx(
            'w-1/3',
            'h-4 py-1 mt-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
          )}
        />
        <div
          className={clsx(
            'w-full',
            'h-3 py-1 mt-4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
          )}
        />
        <div
          className={clsx(
            'w-1/2',
            'h-3 py-1 mt-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
          )}
        />
        <div
          className={clsx(
            'w-40',
            'h-5 py-1 mt-6 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
          )}
        />
      </div>

      <div className="mt-4 ring-1 ring-neutral-200 dark:ring-neutral-700 rounded-xl px-4 py-5 sm:p-6 transition-all animate-pulse">
        <div
          className={clsx(
            'w-1/3',
            'h-4 py-1 mt-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
          )}
        />
        <div
          className={clsx(
            'w-full',
            'h-3 py-1 mt-4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
          )}
        />
        <div
          className={clsx(
            'w-full',
            'h-3 py-1 mt-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
          )}
        />
        <div
          className={clsx(
            'w-1/2',
            'h-3 py-1 mt-1 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
          )}
        />

        <div
          className={clsx(
            'w-48',
            'h-10 py-1 mt-4 bg-neutral-300 rounded-lg dark:bg-neutral-700 animate-pulse'
          )}
        />
      </div>
    </div>
  );
};
