import * as React from 'react';
import styled from 'styled-components';
import {
  IoArrowForward,
  IoChevronForward,
  IoDocumentTextOutline,
  IoDownloadOutline,
  IoOpenOutline
} from 'react-icons/io5';
import { Badge } from '@oysterjs/ui/Badge';

import { PageTitle } from '@oysterjs/ui/Page/section';
import { Action, ActionsContainer, Table } from './table';
import config from '@oysterjs/core/config';
import {
  BusinessInsuranceType,
  BusinessOperationType,
  Merchant,
  MerchantUser,
  ProductType
} from '@oysterjs/types';
import {
  BusinessPolicy,
  Merchant as GraphQLMerchant,
  GetMerchantBusinessPolicyDocument,
  GetMerchantBusinessPolicyQuery,
  PolicyState,
  FileRole,
  GetCertificateQuery,
  GetCertificateDocument,
  File,
  GenerateCertificateInput,
  useGenerateCertificateMutation
} from '@oysterjs/types/merchant/graphql/types-and-hooks';
import { getDisplayInsuranceType } from '@oysterjs/types/merchant/graphql/map';
import { openPageInNewTab } from '@oysterjs/core/window';
import { PageNaviation } from '@oysterjs/ui/Page/navigation';
import { useHistory, useLocation } from 'react-router';
import { SlideOut } from '@oysterjs/ui/Modal/slideout';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { Loadable } from '@oysterjs/ui/Loadable';
import { getMerchantGraphQLClient } from '@oysterjs/core/api/merchant';
import { FormColumn, FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { ErrorDisplay, TextAreaInput, TextInput } from '@oysterjs/ui/Form/text';
import { AddressInputForm } from '@oysterjs/ui/Form/address';
import { Button, ButtonContainer } from '@oysterjs/ui/Button';
import { Checkbox } from '@oysterjs/ui/Form/checkbox';
import {
  RequirementCheckboxContainer,
  RequirementContentContainer,
  RequirementDescription,
  RequirementItemContainer,
  RequirementTitle
} from '@oysterjs/ui/common';
import { Banner } from '@oysterjs/ui/Banner';

const getFormId = (): string => {
  switch (config().environment) {
    case 'production':
      return 'vtaJwrn7SEus';
    default:
      return 'ohCv6cYeRaus';
  }
};

const toNumber = (s?: string) => s?.replace(/[^\d.]/g, '');

const getFilloutParameters = (merchant: Merchant, merchantUser: MerchantUser) => ({
  'data-first_name': merchantUser.FirstName,
  'data-last_name': merchantUser.LastName,
  'data-email': merchantUser.Email,
  'data-phone': merchant.BusinessProfile.Phone,
  'data-merchant_id': merchant.ID,
  'data-business_name': merchant.BusinessProfile.Name,
  'data-dba_name': merchant.BusinessProfile.DBA,
  'data-website': merchant.BusinessProfile.Domain,
  'data-address_line_1': merchant.BusinessProfile.Address.AddressLine1,
  'data-address_line_2': merchant.BusinessProfile.Address.AddressLine2,
  'data-address_city': merchant.BusinessProfile.Address.City,
  'data-address_state': merchant.BusinessProfile.Address.Zone,
  'data-address_zip_code': merchant.BusinessProfile.Address.PostalCode,
  'data-business_type':
    {
      [ProductType.bike]: 'BIKES',
      [ProductType.jewelry]: 'JEWELRY',
      [ProductType.electronics]: 'ELECTRONICS'
    }[merchant.BusinessProfile.ProductVerticals?.[0] || ''] ||
    merchant.BusinessProfile.ProductVerticals?.[0],
  'data-insurance_types': merchant.BusinessProfile.Personalization.BusinessInsuranceTypes?.map(
    (t) =>
      ({
        [BusinessInsuranceType.BusinessOwners]: 'BUSINESS_OWNERS',
        [BusinessInsuranceType.CommercialAuto]: 'AUTO',
        [BusinessInsuranceType.Cyber]: 'CYBER',
        [BusinessInsuranceType.DirectorsAndOfficers]: 'DIRECTORS_OFFICERS',
        [BusinessInsuranceType.GeneralLiability]: 'GENERAL_LIABILITY',
        [BusinessInsuranceType.JewelersBlock]: 'JEWELERS_BLOCK',
        [BusinessInsuranceType.Property]: 'PROPERTY',
        [BusinessInsuranceType.Rental]: 'RENTAL',
        [BusinessInsuranceType.ShippingAndTransportation]: 'SHIPPING',
        [BusinessInsuranceType.UmbrellaExcess]: 'UMBRELLA',
        [BusinessInsuranceType.WorkersCompensation]: 'WORKERS_COMP'
      })[t] ||
      merchant.BusinessProfile.Personalization.BusinessInsuranceTypesOtherDesc ||
      t
  ).join(','),
  'data-operation_types': merchant.BusinessProfile.Personalization.BusinessOperationTypes?.map(
    (t) =>
      ({
        [BusinessOperationType.RetailECommerce]: 'RETAIL',
        [BusinessOperationType.ServiceOrRepair]: 'SERVICE',
        [BusinessOperationType.Wholesale]: 'WHOLESALE',
        [BusinessOperationType.Rental]: 'RENTAL',
        [BusinessOperationType.Manufacturing]: 'MANUFACTORING_DESIGN'
      })[t] ||
      merchant.BusinessProfile.Personalization.BusinessOperationTypesOtherDesc ||
      t
  ).join(','),
  'data-own_brand': merchant.BusinessProfile.Personalization.BusinessManufactureOrWholesaleOwnBrand,
  'data-revenue_retail': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownRetail
  ),
  'data-revenue_service': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownServiceOrRepair
  ),
  'data-revenue_wholesale': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownWholesale
  ),
  'data-revenue_rental': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownRental
  ),
  'data-revenue_manufacturing': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownManufacturing
  ),
  'data-revenue_other': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownOther
  ),
  'data-total_employee_payroll': toNumber(
    merchant.BusinessProfile.Personalization.BusinessTotalPayroll
  ),
  'data-number_of_losses': toNumber(
    merchant.BusinessProfile.Personalization.BusinessNumberOfPriorLosses
  )
});

const GetInsurance = (props: { merchant: Merchant; merchantUser: MerchantUser }) => {
  React.useEffect(() => {
    // Create script component.
    const script = document.createElement('script');
    script.id = 'fillout-embed';
    script.src = `//server.fillout.com/embed/v1/`;
    script.defer = true;

    // Add the script to the DOM
    document.head.appendChild(script);

    // Remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const prefilledParameters = getFilloutParameters(props.merchant, props.merchantUser);

  return (
    <>
      <p style={{ marginTop: '0', maxWidth: '750px' }}>
        Submit the form to get a tailored quote for your business. Our commercial insurance team of
        experts will reach out soon upon submission.
      </p>
      <div
        style={{ maxWidth: '750px' }}
        data-fillout-id={getFormId()}
        data-fillout-embed-type="standard"
        data-fillout-inherit-parameters
        data-fillout-dynamic-resize
        {...prefilledParameters}
      />
      <p style={{ fontSize: '0.9em', color: '#333333' }}>
        <b style={{ fontWeight: 500 }}>Need help? </b>
        <a
          target="_blank"
          href="https://meetings.hubspot.com/gunnar-reinig/commercial-insurance-consultation"
        >
          Schedule a call
        </a>{' '}
        with us!
      </p>
    </>
  );
};

const InsuranceFormSubmitted = () => (
  <>
    <p style={{ marginTop: '0', maxWidth: '600px' }}>
      Thank you for submitting your information. Our commercial insurance team of experts will reach
      out soon.
    </p>
    <p style={{ fontSize: '0.9em', color: '#333333' }}>
      <b style={{ fontWeight: 500 }}>Need help? </b>
      <a
        target="_blank"
        href="https://meetings.hubspot.com/gunnar-reinig/commercial-insurance-consultation"
      >
        Schedule a call
      </a>{' '}
      with us!
    </p>
  </>
);

const ManageBusinessInsurance = (props: {
  merchant: Merchant;
  merchantUser: MerchantUser;
  graphMerchant: GraphQLMerchant;
  policies: BusinessPolicy[];
}) => (
  <PageNaviation
    redirect={{ '/': '/overview' }}
    routes={[
      {
        path: '/overview',
        title: 'Policies',
        render: () => <BusinessInsurancePoliciesList {...props} />
      },
      {
        path: '/certificates',
        title: 'Certificates',
        render: () => <BusinessInsuranceCertificatesList {...props} />
      }
    ]}
  />
);

const BusinessInsurancePoliciesList = (props: {
  merchant: Merchant;
  merchantUser: MerchantUser;
  policies: BusinessPolicy[];
}) => {
  const history = useHistory();
  const urlParams = new URLSearchParams(
    Object.fromEntries(
      Object.entries(getFilloutParameters(props.merchant, props.merchantUser)).map(
        ([key, value]) => [key.replace(/^data-/, ''), value]
      )
    )
  );

  const filloutLink =
    config().environment === 'production'
      ? 'https://www.withoyster.com/business-insurance-app'
      : `https://form.fillout.com/t/${getFormId()}?${urlParams.toString()}`;

  return (
    <>
      <ActionsContainer>
        <Action onClick={() => openPageInNewTab(filloutLink)}>
          Apply for new coverage <IoOpenOutline />
        </Action>
      </ActionsContainer>
      <Table style={{ width: ' 100%' }}>
        <thead>
          <tr>
            <td>Policy</td>
            <td>Status</td>
            <td>Inforce Date</td>
            <td>Expiration Date</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {props.policies
            .filter((p) =>
              [PolicyState.Binding, PolicyState.Inforce, PolicyState.Expired].includes(p.state)
            )
            .map((policy) => (
              <tr
                key={policy.id}
                onClick={() => history.push(`/insurance/overview/policy/${policy.id}`)}
              >
                <td>{getDisplayInsuranceType(policy.type)}</td>
                <td>{getPolicyBadge(policy.state)}</td>
                <td>
                  {!policy.inforceAt || isNaN(new Date(policy.inforceAt).getTime())
                    ? 'N/A'
                    : new Intl.DateTimeFormat('en-US').format(new Date(policy.inforceAt))}
                </td>
                <td>
                  {!policy.expiresAt || isNaN(new Date(policy.expiresAt).getTime())
                    ? 'N/A'
                    : new Intl.DateTimeFormat('en-US').format(new Date(policy.expiresAt))}
                </td>
                <td>
                  <IoChevronForward style={{ color: '#cccccc' }} />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

const BusinessInsuranceCertificatesList = (props: {
  merchant: Merchant;
  merchantUser: MerchantUser;
  graphMerchant: GraphQLMerchant;
}) => {
  const history = useHistory();
  const certificates = props.graphMerchant.files
    .filter((f) => f.role === FileRole.Certificate)
    .filter((f) => !!f.details?.certificateNumber);

  return (
    <>
      <ActionsContainer>
        <Action onClick={() => history.push('/insurance/certificates/create')}>
          Create Certificate <IoDocumentTextOutline />
        </Action>
      </ActionsContainer>
      {!!certificates.length && (
        <Table style={{ width: ' 100%' }}>
          <thead>
            <tr>
              <td>Certificate Number</td>
              <td>Certificate Holder</td>
              <td>Creation Date</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {certificates.map((file) => (
              <tr
                key={file.id}
                onClick={() =>
                  history.push(
                    `/insurance/certificates/certificate/${file.details?.certificateNumber}`
                  )
                }
              >
                <td>{file.details?.certificateNumber}</td>
                <td>{file.details?.certificateHolder.name}</td>
                <td>{new Intl.DateTimeFormat('en-US').format(new Date(file.createdAt))}</td>
                <td>
                  <IoChevronForward style={{ color: '#cccccc' }} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

const getPolicyBadge = (state: PolicyState) => {
  switch (state) {
    case PolicyState.Binding:
      return <Badge color="rgba(252, 227, 193)" textColor="rgba(214, 131, 0)" label="Pending" />;

    case PolicyState.Inforce:
      return <Badge color="rgba(189, 234, 205)" textColor="rgba(57, 145, 88)" label="Active" />;

    case PolicyState.Expired:
      return <Badge color="rgb(255, 193, 185)" textColor="rgb(201, 67, 48)" label="Expired" />;

    default:
      return <></>;
  }
};

const titleCase = (s: string) =>
  s
    .split(/[\s_]/g)
    .map((part) => part[0].toUpperCase() + part.substring(1).toLowerCase())
    .join(' ');

const unescapeSafe = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

const ViewBusinessPolicy = (props: { policy: BusinessPolicy }) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
    {[
      ['Carrier', props.policy.carrier.name],
      [
        'Premium',
        new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' }).format(
          props.policy.pricing.premium
        )
      ],
      [
        'Total Charge',
        new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' }).format(
          props.policy.pricing.total
        )
      ],
      ['Payment Mode', titleCase(props.policy.pricing.billingType)]
    ].map(([label, description]) => (
      <div key={label} style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <div style={{ fontWeight: 500 }}>{label}</div>
        <div style={{ color: '#666666', fontSize: '0.9em' }}>{description}</div>
      </div>
    ))}
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <div style={{ fontWeight: 500 }}>Documents</div>
      {!props.policy.files.length && (
        <div style={{ color: '#666666', fontSize: '0.9em' }}>No documents available yet</div>
      )}
      {!!props.policy.files.length && (
        <ul
          style={{
            color: '#666666',
            fontSize: '0.9em',
            listStyle: 'none',
            margin: '0',
            padding: '0'
          }}
        >
          {props.policy.files
            .filter((f) => !!f.url)
            .map((f) => (
              <li>
                <a href={f.url || ''} target="_BLANK">
                  {titleCase(f.role)} <IoOpenOutline />
                </a>
              </li>
            ))}
        </ul>
      )}
    </div>
  </div>
);

const ViewCertificate = (props: { certificate: File }) => (
  <>
    <ActionsContainer>
      <Action
        onClick={() =>
          openPageInNewTab(
            `${config().serviceBaseUrl.partners}/certificate/${
              props.certificate.details?.certificateNumber
            }`
          )
        }
      >
        Open Sharable Link <IoOpenOutline />
      </Action>
      <Action onClick={() => openPageInNewTab(props.certificate.url || '')}>
        Download Certificate <IoDownloadOutline />
      </Action>
    </ActionsContainer>
    <div
      style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '24px 0px 0px 0px' }}
    >
      {[
        ['Insured Name', unescapeSafe(props.certificate.details?.insured.name || '')],
        ['Insured Address', unescapeSafe(props.certificate.details?.insured.address || '')],
        [
          'Certificate Holder Name',
          unescapeSafe(props.certificate.details?.certificateHolder.name || '')
        ],
        [
          'Certificate Holder Address',
          unescapeSafe(props.certificate.details?.certificateHolder.address || '')
        ],
        [
          'Description of Operations',
          unescapeSafe(props.certificate.details?.descriptionOfOperations || '')
        ]
      ].map(
        ([label, description]) =>
          !!description && (
            <div key={label} style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <div style={{ fontWeight: 500 }}>{label}</div>
              <div style={{ color: '#666666', fontSize: '0.9em' }}>{description}</div>
            </div>
          )
      )}
    </div>
  </>
);

const CreateCertificate = () => {
  const history = useHistory();
  const [generateCertificate, { loading }] = useGenerateCertificateMutation();
  const [componentError, setComponentError] = React.useState<Error>();
  const [needsAgentAction, setNeedsAgentAction] = React.useState(false);
  const [form, setForm] = React.useState<GenerateCertificateInput>({
    name: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      zone: '',
      postalCode: ''
    },
    additionalInsured: false,
    additionalInsuredRelationship: '',
    notes: ''
  });

  const handleSubmit = async () => {
    try {
      setComponentError(undefined);
      const res = await generateCertificate({ variables: { req: form } });
      if (res.errors?.length) {
        throw new Error(res.errors[0].message);
      }
      if (res.data && !res.data.generateCertificate) {
        setNeedsAgentAction(true);
      } else if (res.data?.generateCertificate?.details?.certificateNumber) {
        history.push(
          `/insurance/certificates/certificate/${res.data?.generateCertificate?.details?.certificateNumber}`
        );
      }
    } catch (e) {
      setComponentError(e as Error);
    }
  };

  if (needsAgentAction) {
    return (
      <p>
        Your request has been received. An agent will follow up via email with your completed
        certificate.
      </p>
    );
  }

  return (
    <FormContainer>
      <FormRowHeader
        title="Certificate Holder"
        description="Enter the name of the party that will receive and hold the certificate."
      />
      <FormRow>
        <FormColumn>
          <TextInput
            style={{ maxWidth: '300px' }}
            value={form.name}
            onChange={(e) => {
              const val = e.currentTarget.value;
              setForm((prev) => ({ ...prev, name: val }));
            }}
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Certificate Holder Address"
        description="Enter the address of the party that will receive and hold the certificate."
      />
      <AddressInputForm
        onChange={(address) =>
          setForm((prev) => ({
            ...prev,
            address: {
              line1: address.streetAddress || '',
              city: address.city || '',
              zone: address.state || '',
              postalCode: address.zipCode || ''
            }
          }))
        }
      />
      <FormRow>
        <RequirementItemContainer
          style={{ padding: '0' }}
          onClick={(e) => {
            e.stopPropagation();
            setForm((prev) => ({ ...prev, additionalInsured: !prev.additionalInsured }));
          }}
        >
          <RequirementCheckboxContainer>
            <Checkbox
              label="Add the certificate holder as an additional insured"
              checked={form.additionalInsured}
              onChange={() => {
                setForm((prev) => ({ ...prev, additionalInsured: !prev.additionalInsured }));
              }}
            />
          </RequirementCheckboxContainer>
          <RequirementContentContainer>
            <RequirementTitle>Add the certificate holder as an additional insured</RequirementTitle>
            <RequirementDescription>
              Check this box if you want coverage to extend to this party.{' '}
              <b>
                Do not check this unless specifically requested by the certificate holder, as this
                may take several business days to process.
              </b>
            </RequirementDescription>
          </RequirementContentContainer>
        </RequirementItemContainer>
      </FormRow>
      {form.additionalInsured && (
        <>
          <FormRowHeader
            title="Relationship to Insured"
            description="Describe the relationship of the certificate holder to the primary insured on the policy."
          ></FormRowHeader>
          <FormRow>
            <FormColumn>
              <TextInput
                style={{ maxWidth: '300px' }}
                value={form.additionalInsuredRelationship || ''}
                onChange={(e) => {
                  const val = e.currentTarget.value;
                  setForm((prev) => ({ ...prev, additionalInsuredRelationship: val }));
                }}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <Banner
              title="Additional Review Required"
              description="In order to make sure your policy extends coverage to this party, Oyster will perform a manual review. In most cases, you'll receive your certificate within 24 hours, but the process may take up to several business days depending on complexity. Our agents will follow up via email."
            />
          </FormRow>
        </>
      )}
      <FormRowHeader
        title="Notes"
        description="Additional notes to include for the Oyster team to review."
      />
      <TextAreaInput
        rows={3}
        value={form.notes}
        onChange={(e) => {
          const val = e.currentTarget.value;
          setForm((prev) => ({ ...prev, notes: val }));
        }}
      />
      <FormRow>
        <ButtonContainer>
          <Button primary icon={<IoArrowForward />} loading={loading} onClick={handleSubmit}>
            Request Certificate
          </Button>
        </ButtonContainer>
      </FormRow>
      {componentError && <ErrorDisplay>{componentError.message}</ErrorDisplay>}
    </FormContainer>
  );
};

export const InsurancePage = (props: {
  merchant: Merchant;
  merchantUser: MerchantUser;
  graphMerchant: GraphQLMerchant;
  policies?: BusinessPolicy[];
}) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <PageTitle title="Business Insurance" description="Protect your business with Oyster." />
      {!props.merchant.BusinessProfile.Personalization.BusinessInsuranceFilloutFormSubmitted &&
        !props.policies?.length && (
          <GetInsurance merchant={props.merchant} merchantUser={props.merchantUser} />
        )}
      {props.merchant.BusinessProfile.Personalization.BusinessInsuranceFilloutFormSubmitted &&
        !props.policies?.length && <InsuranceFormSubmitted />}
      {!!props.policies?.length && (
        <ManageBusinessInsurance
          merchant={props.merchant}
          merchantUser={props.merchantUser}
          graphMerchant={props.graphMerchant}
          policies={props.policies}
        />
      )}

      <SlideOut
        showing={/^\/insurance\/overview\/policy\/.+$/.test(location.pathname)}
        onClose={() => history.replace('/insurance/overview')}
        onBack={() => history.replace('/insurance/overview')}
        title="View Policy"
      >
        <ErrorBoundary forceMobile>
          {!location.pathname.match(/\/policy\/(.+)$/)?.[1] ? null : (
            <Loadable
              request={getMerchantGraphQLClient()
                .query<GetMerchantBusinessPolicyQuery>({
                  query: GetMerchantBusinessPolicyDocument,
                  variables: {
                    id: location.pathname.match(/\/policy\/(.+)$/)?.[1]
                  }
                })
                .then((res) => {
                  if (res.data?.businessPolicy) {
                    return res.data.businessPolicy;
                  }

                  throw new Error('Policy not found');
                })}
            >
              {(data) => (
                <PolicySlideoutContainer
                  title={getDisplayInsuranceType(data.type)}
                  description={data.policyNumber}
                  badge={getPolicyBadge(data.state)}
                >
                  <ViewBusinessPolicy policy={data as BusinessPolicy} />
                </PolicySlideoutContainer>
              )}
            </Loadable>
          )}
        </ErrorBoundary>
      </SlideOut>

      <SlideOut
        showing={/^\/insurance\/certificates\/certificate\/.+$/.test(location.pathname)}
        onClose={() => history.replace('/insurance/certificates')}
        onBack={() => history.replace('/insurance/certificates')}
        title="View Certificate"
      >
        <ErrorBoundary forceMobile>
          {!location.pathname.match(/\/certificate\/(.+)$/)?.[1] ? null : (
            <Loadable
              request={getMerchantGraphQLClient()
                .query<GetCertificateQuery>({
                  query: GetCertificateDocument,
                  variables: {
                    certificateNumber: location.pathname.match(/\/certificate\/(.+)$/)?.[1]
                  }
                })
                .then((res) => {
                  if (res.data?.certificate?.details) {
                    return res.data.certificate;
                  }

                  throw new Error('Certificate not found');
                })}
            >
              {(data) => (
                <PolicySlideoutContainer
                  title="Certificate of Insurance"
                  description={data.details?.certificateNumber || ''}
                  badge={getPolicyBadge(PolicyState.Inforce)}
                >
                  <ViewCertificate certificate={data as File} />
                </PolicySlideoutContainer>
              )}
            </Loadable>
          )}
        </ErrorBoundary>
      </SlideOut>

      <SlideOut
        showing={/^\/insurance\/certificates\/create$/.test(location.pathname)}
        onClose={() => history.replace('/insurance/certificates')}
        onBack={() => history.replace('/insurance/certificates')}
      >
        <ErrorBoundary forceMobile>
          <PolicySlideoutContainer
            title="Create a Certificate"
            description="Automatically produce a document that certifies your insurance coverage for a third party"
          >
            <CreateCertificate />
          </PolicySlideoutContainer>
        </ErrorBoundary>
      </SlideOut>
    </>
  );
};

const SlideoutHeader = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 0px;
`;

interface PolicySlideoutContainerProps {
  title: string;
  description: string;
  badge?: JSX.Element;
}

export const PolicySlideoutContainer = (
  props: React.PropsWithChildren<PolicySlideoutContainerProps>
) => (
  <div style={{ padding: '0px 20px' }}>
    <SlideoutHeader>
      <h1>{props.title}</h1>
      {props.badge}
    </SlideoutHeader>
    <p style={{ margin: '0 0 1em 0', fontSize: '0.9em', color: '#666666' }}>{props.description}</p>
    {props.children}
    <div style={{ padding: '20px 0px' }}>
      <p style={{ fontSize: '0.9em', color: '#333333' }}>
        <b style={{ fontWeight: 500 }}>Need help?</b>{' '}
        <a
          target="_blank"
          href={`https://meetings.hubspot.com/gunnar-reinig/commercial-insurance-consultation`}
        >
          Schedule a call
        </a>{' '}
        with us!
      </p>
    </div>
  </div>
);
