import * as React from 'react';
import styled from 'styled-components';
import { useRouteMatch, NavLink } from 'react-router-dom';
import { useWindowDimensions } from '@oysterjs/core/window';

import { IoMenu } from 'react-icons/io5';
import { resetToken, useAuth } from '@oysterjs/core/auth';
import { merchantUserSignOut } from '@oysterjs/core/api/merchant';

const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 40px;
  height: 80px;
  box-sizing: border-box;
  background: white;
  border-bottom: 1px solid #eaeaea;
`;

const ProfileContainer = (props: { firstName: string; lastName: string; email: string }) => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      gap: '12px'
    }}
  >
    <div style={{ flex: '1 0 1' }}>
      <div style={{ fontWeight: 500, textAlign: 'right' }}>Hi, {props.firstName}</div>
      <div style={{ color: '#999999', fontSize: '0.8em', textAlign: 'right' }}>{props.email}</div>
    </div>
    <div
      style={{
        borderRadius: '50%',
        flex: '1 0 1',
        height: '40px',
        width: '40px',
        background: '#FFCEB6',
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {props.firstName[0]}
      {props.lastName[0]}
    </div>
  </div>
);

const HamburgerMenu = styled.div`
  position: absolute;
  width: 100%;
  top: 80px;
  right: 0px;
  background: white;
  border-top: 2px solid #fafafa;
  border-bottom: 2px solid #f2f2f2;
  z-index: 998;
`;

const HamburgerMenuItemContainer = styled.div`
  padding: 20px 40px;
  box-sizing: border-box;
  width: 100%;
  font-size: 1em;
  font-weight: 500;

  &:hover {
    background: #fafafa;
  }
`;

const HamburgerMenuItem: React.FunctionComponent<{
  label: string;
  link: string;
  onClick: () => void;
}> = (props) => (
  <li>
    <NavLink exact to={props.link} onClick={props.onClick}>
      <HamburgerMenuItemContainer>{props.label}</HamburgerMenuItemContainer>
    </NavLink>
  </li>
);

const NavBarContainer = styled.ol`
  margin: 0;
  padding: 0;
  list-style: none;

  a,
  a:visited {
    color: #333333;
    text-decoration: none;
  }

  a.active {
    color: #0ea5e9;
  }
`;

const NavItem = styled.li`
  padding: 18px 0px;
  display: flex;
  font-size: 1.5em;
  font-weight: 500;
  cursor: pointer;
`;

const NavBar = () => {
  const [expanded, setExpanded] = React.useState(false);
  const { url } = useRouteMatch();

  const signOut = () => {
    merchantUserSignOut().finally(() => {
      resetToken();
    });
  };

  return (
    <NavBarContainer>
      <NavItem
        aria-haspopup
        aria-expanded={expanded}
        tabIndex={0}
        role="button"
        aria-label="Expand Menu"
        style={{ padding: '0' }}
        onClick={() => setExpanded((prev) => !prev)}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            setExpanded((prev) => !prev);
            return false;
          }
          return true;
        }}
      >
        <IoMenu
          style={{
            cursor: 'pointer',
            color: expanded ? 'white' : undefined,
            background: expanded ? '#0EA5E9' : undefined,
            borderRadius: '50%',
            padding: '10px'
          }}
        />
      </NavItem>
      <HamburgerMenu style={{ display: expanded ? 'block' : 'none' }}>
        <HamburgerMenuItem
          label="Home"
          link={`${url.replace(/\/+$/, '')}/home`}
          onClick={() => setExpanded(false)}
        />
        <HamburgerMenuItem
          label="Insurance"
          link={`${url.replace(/\/+$/, '')}/insurance`}
          onClick={() => setExpanded(false)}
        />
        <HamburgerMenuItem
          label="Channels"
          link={`${url.replace(/\/+$/, '')}/channels`}
          onClick={() => setExpanded(false)}
        />
        <HamburgerMenuItem
          label="Payouts"
          link={`${url.replace(/\/+$/, '')}/payouts`}
          onClick={() => setExpanded(false)}
        />
        <HamburgerMenuItem
          label="Apps"
          link={`${url.replace(/\/+$/, '')}/apps`}
          onClick={() => setExpanded(false)}
        />
        <HamburgerMenuItem
          label="Reports"
          link={`${url.replace(/\/+$/, '')}/reports`}
          onClick={() => setExpanded(false)}
        />
        <HamburgerMenuItem
          label="Team"
          link={`${url.replace(/\/+$/, '')}/team`}
          onClick={() => setExpanded(false)}
        />
        <HamburgerMenuItem
          label="Invite"
          link={`${url.replace(/\/+$/, '')}/invite`}
          onClick={() => setExpanded(false)}
        />
        <HamburgerMenuItem
          label="Sign out"
          link={`${url.replace(/\/+$/, '')}/signin`}
          onClick={signOut}
        />
      </HamburgerMenu>
    </NavBarContainer>
  );
};

export const TopBar = () => {
  const [auth] = useAuth();
  const user = auth.MerchantUser;

  return (
    <TopBarContainer>
      {useWindowDimensions().innerWidth >= 900 ? <div /> : <NavBar />}
      {user ? (
        <ProfileContainer firstName={user.FirstName} lastName={user.LastName} email={user.Email} />
      ) : (
        <div />
      )}
    </TopBarContainer>
  );
};
