import React from 'react';
import { PageNaviation } from '@oysterjs/ui/Page/navigation';
import { PageTitle } from '@oysterjs/ui/Page/section';
import { SlideOut } from '@oysterjs/ui/Modal/slideout';
import { PolicySlideoutContainer } from '../insurance/business';
import { AttachmentUploader } from '@oysterjs/ui/Attachment';
import { Button } from '@oysterjs/ui/Button';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

const statuses = {
  Complete: 'text-green-700 bg-green-50 ring-green-600/20',
  'In progress': 'text-neutral-600 bg-neutral-50 ring-neutral-500/10',
  Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20'
};
const projects = [
  {
    id: 4,
    name: 'Register proof of insurance with the state',
    href: '#',
    status: 'Complete',
    createdBy: 'Jon Patel',
    dueDate: 'September 4, 2024',
    dueDateTime: '2025-06-07T00:00Z'
  },
  {
    id: 1,
    name: 'Make your website ADA-compliant',
    href: '#',
    status: 'Complete',
    createdBy: 'Nikhil Kansal',
    dueDate: 'March 17, 2025',
    dueDateTime: '2025-03-17T00:00Z'
  },
  {
    id: 2,
    name: 'Create a product warranty policy',
    href: '#',
    status: 'In progress',
    createdBy: 'Nikhil Kansal',
    dueDate: 'May 5, 2025',
    dueDateTime: '2025-05-05T00:00Z'
  },
  {
    id: 3,
    name: 'Install external security cameras',
    href: '#',
    status: 'In progress',
    createdBy: 'Vic Yeh',
    dueDate: 'May 25, 2025',
    dueDateTime: '2025-05-25T00:00Z'
  },
  {
    id: 5,
    name: 'Submit policy renewal documentation',
    href: '#',
    status: 'In progress',
    createdBy: 'Vic Yeh',
    dueDate: 'June 10, 2025',
    dueDateTime: '2025-06-10T00:00Z'
  }
];

const stats = [
  { name: 'Action Items', value: '3 open' },
  { name: 'Compliance', value: '28%' },
  { name: 'Potential Risk', value: '17 liabilities' }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Example() {
  return (
    <div>
      <dl className="mt-4 mb-6 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.name}
            className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
          >
            <dt className="truncate text-sm text-neutral-500">{item.name}</dt>
            <dd className="mt-1 text-3xl font-medium tracking-tight text-neutral-700">
              {item.value}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

const ActionItems = () => {
  const [showing, setShowing] = React.useState(false);

  return (
    <>
      <SlideOut
        showing={showing}
        onClose={() => setShowing(false)}
        onBack={() => setShowing(false)}
        title="View Action Item"
      >
        <PolicySlideoutContainer
          title="Create a product warranty policy"
          description="Ensure your business is protected from liability in case of product defects and malfunctions."
          badge={<> </>}
        >
          <p className="pb-2">A good product warranty policy usually consists of:</p>
          <ul className="list-disc ml-8 leading-relaxed pb-4">
            <li>Use clear, simple language avoiding legal jargon</li>
            <li>Clearly specify coverage period, what's included and excluded</li>
            <li>Detail the claim process and provide contact information</li>
            <li>State remedies (repair, replacement, refund) and company obligations</li>
            <li>Include maintenance requirements and transferability information</li>
            <li>Comply with local laws and align with marketing materials</li>
          </ul>
          {/* 
          title?: string;
  description?: string;
  multiple?: boolean;
  allowDeleteExisting?: boolean;
  attachments: AttachmentFile[];
  onAttachmentsAdded: (files: File[]) => Promise<void>;
  onAttachmentRemoved?: (index: number, attachment?: AttachmentFile, file?: File) => Promise<void>;
  validationError?: ValidationError; */}

          <AttachmentUploader
            title="Upload policy"
            description="Upload a copy of your product warranty policy. Supported file types: PDF, DOC, DOCX, TXT"
            attachments={[]}
            onAttachmentsAdded={async () => {}}
          />
          <div className="pt-4">
            <Button primary>Submit</Button>
          </div>
        </PolicySlideoutContainer>
      </SlideOut>

      <ul role="list" className="divide-y divide-neutral-100">
        {projects.map((project) => (
          <li key={project.id} className="flex items-center justify-between gap-x-6 py-5">
            <div className="min-w-0">
              <div className="flex items-start gap-x-3">
                <p className="text-sm font-semibold leading-6 text-neutral-900">{project.name}</p>
                <p
                  className={classNames(
                    statuses[project.status],
                    'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                  )}
                >
                  {project.status}
                </p>
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-neutral-500">
                <p className="whitespace-nowrap text-xs leading-5 ">
                  Due on <time dateTime={project.dueDateTime}>{project.dueDate}</time>
                </p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle r={1} cx={1} cy={1} />
                </svg>
                <p className="truncate text-xs leading-5 ">Assigned to {project.createdBy}</p>
              </div>
            </div>
            <div className="flex flex-none items-center gap-x-4">
              <span
                className="cursor-pointer hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 hover:bg-neutral-50 sm:block"
                onClick={() => setShowing(true)}
              >
                View item<span className="sr-only">, {project.name}</span>
              </span>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

const compliances = [
  {
    name: 'Operations',
    people: [
      {
        complete: true,
        label:
          'Establish a comprehensive data security and privacy policy to protect customer and employee information.'
      },
      {
        complete: false,
        label:
          'Implement robust cybersecurity measures, including firewalls, antivirus software, and regular software updates.'
      },
      {
        complete: false,
        label:
          'Develop and regularly test a business continuity plan to ensure seamless operations in the event of a disruption.'
      },
      {
        complete: false,
        label:
          'Maintain accurate and up-to-date inventory records, with regular audits to ensure accountability.'
      },
      {
        complete: true,
        label:
          'Comply with all applicable environmental regulations and implement sustainable practices in business operations.'
      }
    ]
  },
  {
    name: 'Processes',
    people: [
      {
        complete: false,
        label:
          'Streamline order processing and fulfillment procedures to ensure timely and accurate delivery of products.'
      },
      {
        complete: false,
        label:
          'Establish quality control measures to maintain consistent product standards and minimize defects.'
      },
      {
        complete: false,
        label:
          'Implement a transparent and efficient returns and refunds policy to address customer concerns.'
      },
      {
        complete: true,
        label:
          'Develop and regularly review standard operating procedures for all critical business processes.'
      },
      {
        complete: false,
        label:
          'Ensure compliance with labor laws and regulations, including fair wages, working hours, and employee safety.'
      }
    ]
  },
  {
    name: 'Sales and Marketing',
    people: [
      {
        complete: false,
        label:
          'Adhere to all applicable advertising and marketing regulations, including truthful and non-misleading claims.'
      },
      {
        complete: false,
        label: 'Implement a comprehensive customer data privacy and consent management system.'
      },
      {
        complete: true,
        label:
          'Provide clear and accurate product information, including descriptions, pricing, and shipping details.'
      },
      {
        complete: false,
        label:
          'Establish a responsive and accessible customer service system to address inquiries and complaints.'
      },
      {
        complete: false,
        label:
          'Comply with e-commerce regulations, such as online transaction security and consumer protection laws.'
      }
    ]
  },
  {
    name: 'Products',
    people: [
      {
        complete: true,
        label:
          'Ensure all products comply with relevant safety and quality standards, including product labeling and certifications.'
      },
      {
        complete: false,
        label:
          'Implement a robust product recall and crisis management plan to address any safety or quality issues.'
      },
      {
        complete: false,
        label:
          'Comply with intellectual property laws and obtain necessary licenses or permissions for any copyrighted or trademarked materials.'
      },
      {
        complete: true,
        label:
          'Develop a comprehensive product liability insurance policy to mitigate risks associated with product defects or failures.'
      },
      {
        complete: false,
        label:
          'Regularly review and update product offerings to meet evolving customer needs and market trends.'
      }
    ]
  },
  {
    name: 'Liability and Compliance',
    people: [
      {
        complete: true,
        label:
          'Maintain comprehensive general liability and professional liability insurance coverage.'
      },
      {
        complete: false,
        label:
          'Comply with all applicable tax laws and regulations, including sales tax, income tax, and employment taxes.'
      },
      {
        complete: false,
        label:
          'Adhere to all relevant industry-specific regulations and standards, such as those set by trade associations or regulatory bodies.'
      },
      {
        complete: true,
        label:
          'Establish a compliance management system to monitor, assess, and continuously improve adherence to all applicable laws and regulations.'
      },
      {
        complete: false,
        label:
          'Provide regular compliance training and updates to all employees to ensure awareness and understanding of relevant requirements.'
      }
    ]
  }
];

const ComplianceItems = () => {
  return (
    <div>
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block py-2 align-middle sm:px-6 lg:px-8">
          <table>
            <thead>
              <tr>
                <th scope="col">
                  <span className="sr-only">Complete</span>
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-neutral-900 sm:pl-3"
                >
                  Name
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {compliances.map((location, i) => (
                <React.Fragment key={location.name}>
                  <tr className="border-t border-neutral-200">
                    <th
                      scope="colgroup"
                      colSpan={5}
                      className="bg-white py-2 pl-4 pr-3 text-left text-sm font-semibold text-neutral-900 sm:pl-3"
                    >
                      {i + 1}. {location.name}
                    </th>
                  </tr>
                  {location.people.map((person, personIdx) => (
                    <tr
                      key={person.label}
                      className={classNames(
                        personIdx === 0 ? 'border-neutral-200' : 'border-neutral-200',
                        'border-t'
                      )}
                    >
                      <td className="w-6">
                        {person.complete && <CheckCircleIcon className="text-primary-500" />}
                      </td>
                      <td className="py-4 pl-4 pr-3 text-sm text-neutral-900 sm:pl-3">
                        {i + 1}.{personIdx + 1}. {person.label}
                      </td>
                      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                          {person.title}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                          {person.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                          {person.role}
                        </td> */}
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                        <a href="#" className="text-primary-600 hover:text-indigo-900 pr-4">
                          View
                        </a>
                        <a
                          href="#"
                          className={classNames([
                            person.complete
                              ? 'opacity-50'
                              : 'text-primary-600 hover:text-indigo-900'
                          ])}
                        >
                          Complete
                        </a>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export const RiskPage = () => {
  return (
    <>
      <PageTitle
        title="Risk Management"
        description="Manage and mitigate business risk with Oyster"
      />
      <Example />
      <PageNaviation
        redirect={{ '/': '/actions' }}
        routes={[
          {
            path: '/actions',
            title: 'Action Items',
            render: () => <ActionItems />
          },
          {
            path: '/compliance',
            title: 'Compliance',
            render: () => <ComplianceItems />
          }
        ]}
      />
    </>
  );
};
