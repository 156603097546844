import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { OysterWordMark } from '@oysterjs/ui/Logo';
import { Heading } from '../../components/heading';
import { useWindowScrollPosition } from '@oysterjs/core/window';
import { ApplicationContext, BusinessApplication, useBusinessApplicationProvider } from './context';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { ErrorComponentV2 } from '@oysterjs/ui/ErrorBoundary';
import { BusinessApplicationState } from '../../types/graphql';
import { useHistory } from 'react-router-dom';

const ProgressBar = (props: { progress: number }) => {
  const scrollPos = useWindowScrollPosition();

  return (
    <div className="fixed z-10 w-full h-1 bg-neutral-100 dark:bg-neutral-700">
      <div
        className="h-full bg-primary-500"
        style={{
          width: `${props.progress * 100 + (scrollPos.scrollPosY / document.body.scrollHeight) * 10}%`
        }}
      />
    </div>
  );
};

export const ApplicationWrapper = (
  props: React.PropsWithChildren<{
    id: string;
    includeQuotes?: boolean;
    includeQuotableCarriers?: boolean;
  }>
) => {
  const value = useBusinessApplicationProvider(
    props.id,
    !!props.includeQuotes,
    !!props.includeQuotableCarriers
  );
  return !props.id ? null : (
    <ApplicationContext.Provider value={value}>{props.children}</ApplicationContext.Provider>
  );
};

const TransitionContainer = styled.div`
  .fade-enter,
  &.fade-enter {
    opacity: 0;
  }
  .fade-enter-active,
  &.fade-enter-active {
    opacity: 1;
    transition: 0.15s opacity ease;
  }
  .fade-exit,
  &.fade-exit {
    opacity: 1;
  }
  .fade-exit-active,
  &.fade-exit-active {
    opacity: 0;
    transition: 0.15s opacity ease;
  }

  .fade-500-enter,
  &.fade-500-enter {
    opacity: 0;
  }
  .fade-500-enter-active,
  &.fade-500-enter-active {
    opacity: 1;
    transition: 0.5s opacity ease;
  }
  .fade-500-exit,
  &.fade-500-exit {
    opacity: 1;
  }
  .fade-500-exit-active,
  &.fade-500-exit-active {
    opacity: 0;
    transition: 0.5s opacity ease;
  }
`;

export const PageWrapper = (
  props: React.PropsWithChildren<{
    title: string;
    description: string;
    progress: number;
    loading?: boolean;
    loader?: () => Promise<BusinessApplication | null>;
    skeleton?: JSX.Element;
  }>
) => {
  const { application, loading, error, loadApplication } = React.useContext(ApplicationContext);
  const location = useLocation();
  const history = useHistory();
  const loader =
    props.loader ||
    (() =>
      loadApplication().then((res) => {
        if (res?.state == BusinessApplicationState.Submitted) {
          history.push(`/commercial/app/${res.id}/complete`);
        }
      }));

  React.useEffect(() => {
    window.scroll({ top: 0 });
    loader();
  }, [location.pathname]);

  return (
    <div className="w-full min-h-screen bg-neutral-50 dark:bg-neutral-900">
      <ProgressBar progress={props.progress} />
      <div className="mx-auto max-w-7xl px-8 pb-24 sm:px-12">
        <div className="mx-auto max-w-2xl">
          <div className="pt-24 pb-16 flex flex-col gap-8">
            <OysterWordMark />
          </div>

          <SwitchTransition mode="out-in">
            <CSSTransition key={(!!error).toString()} timeout={100} classNames="fade">
              <TransitionContainer>
                {!error && (
                  <>
                    {application?.businessName && (
                      <Heading level={1} className="text-base/4 font-semibold text-primary-500">
                        {application.businessName}
                      </Heading>
                    )}
                    {!application?.businessName && (
                      <div className="h-4 py-1.5 mt-4 mb-2 w-12 sm:w-48 bg-primary-200 rounded-lg dark:bg-primary-800 animate-pulse" />
                    )}
                    <Heading level={2} className="text-3xl/8 dark:text-white">
                      {props.title}
                    </Heading>
                    <p className="text-lg/6 text-neutral-500 dark:text-neutral-400">
                      {props.description}
                    </p>
                  </>
                )}
                {error && <ErrorComponentV2 error={error} />}
              </TransitionContainer>
            </CSSTransition>
          </SwitchTransition>

          <SwitchTransition mode="out-in">
            <CSSTransition
              key={loading.toString() + props.loading?.toString() + (!!error).toString()}
              timeout={100}
              classNames="fade"
            >
              <TransitionContainer>
                {(!application || loading || props.loading) && !error && props.skeleton}
                {!!application && !loading && !props.loading && !error && props.children}
              </TransitionContainer>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </div>
  );
};
